import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import apiCall from "../../../components/src/ApiCall.web";
import { Color } from "@material-ui/lab/Alert";
import StorageProvider from "../../../framework/src/StorageProvider";

interface ForgotPasswordSuccess {
    data: {
        attributes: {
            activated: boolean;
            address: string;
            district: string;
            email: string;
            full_phone_number: number
            gender: string;
            mobile_number: number;
            phone_number: number;
            pincode: number;
            state: string;
            user_id: string;
            user_name: string;
        }
    }
}

interface ForgotPassowrdFailure {
    errors: Array<string>;
}

interface NavItem {
    link: string;
    path: string;
}

interface FooterNavItem {
    name: string;
    link: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string;
    history: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeBtn: boolean;
    showSuccessMesage: boolean;
    email: string;
    userId: string;
    password: string;
    retypePassword: string;
    validate: boolean;
    successModalVisible: boolean,
    isLoading: boolean;
    forgotPasswordSuccess: ForgotPasswordSuccess;
    navData: NavItem[];
    currentFontSize: string;
    footerNavData: FooterNavItem[];
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class ForgotPassword extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    forgotPasswordApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            activeBtn: true,
            showSuccessMesage: false,
            email: "",
            userId: "",
            password: "",
            retypePassword: "",
            validate: false,
            successModalVisible: false,
            isLoading: false,
            forgotPasswordSuccess: {} as ForgotPasswordSuccess,
            navData: [{ link: "Home", path: "/" },
            { link: "FAQs", path: "/faqs" },
            { link: "Feedback", path: "/feedback" },
            { link: "Sign Up", path: "/signUp" }],
            footerNavData: [
                { name: "Terms and Conditions", link: "/terms-conditions" },
                { name: "Privacy Policy", link: "/privacy-policy" },
                {
                    name: "Copyright", link: ""
                },
                {
                    name: "Hyperlinking Policy", link: ""
                },
                {
                    name: "Accessibility", link: ""
                },
                {
                    name: "Archive", link: ""
                },
                {
                    name: "Contact Us", link: "/contact-us"
                },
                {
                    name: "Sitemap", link: ""
                }
            ],
            currentFontSize: "16px",
            alertMsg: "",
            isAlert: false,
            alertType: "success"
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
         
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)

            )

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            if (responseJson && !responseJson.errors) {
                await this.passwordForgotSucessCall(apiRequestCallId, responseJson);
            }
            if (responseJson && responseJson.errors) {
                await this.passwordForgotErrorsCell(apiRequestCallId, responseJson)
            } else if (errorReponse) { this.setState({ isLoading: false }) }
        }
    }

    passwordForgotSucessCall = async (apiRequestCallId: string, responseJson: any) => {
        if (apiRequestCallId === this.forgotPasswordApiCallId) {
            await this.forgotPassowrdSuccessCallBack(responseJson);
        }
    }

    passwordForgotErrorsCell = async (apiRequestCallId: string, responseJson: ForgotPassowrdFailure) => {
        if (apiRequestCallId === this.forgotPasswordApiCallId) {
            await this.forgotPassowrdFailureCallBack(responseJson);
        }
    }

    handleForgotPassword = async () => {
        let forgotBody = {
            data: {
                attributes: {
                    email: this.state.email,
                    user_id: this.state.userId
                }
            }
        }
        this.forgotPasswordApiCallId = apiCall({
            contentType: configJSON.forgotPasswordAPiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.forgotPasswordEndPoint,
            body: forgotBody
        });
    }

    forgotPassowrdSuccessCallBack = async (responseJson: ForgotPasswordSuccess) => {
        this.setState({
            isLoading: false,
            showSuccessMesage: true,
            forgotPasswordSuccess: responseJson
        })
    }

    forgotPassowrdFailureCallBack = async (responseJson: ForgotPassowrdFailure) => {
        this.setState({ isLoading: false, isAlert: true, alertMsg: responseJson.errors[0], alertType: "error" });
    }

    handleMenuTabUserId = () => {
        this.setState({ activeBtn: true });
    };

    handleMenuTabDigitalSignature = () => {
        this.setState({ activeBtn: false }, () => {
            { !this.state.activeBtn && this.props.history.push("/digitalsignature") }
        });
    };

    onFinish = (values: { email_id: string, user_id: string }) => {
        this.setState({
            email: values?.email_id,
            userId: values?.user_id
        }, () => {
            this.handleForgotPassword()
        });
    };

    handleLoginRedirect = () => {
        this.setState({ showSuccessMesage: false })
        const SigninMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
        SigninMessage.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        SigninMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        runEngine.sendMessage(SigninMessage.id, SigninMessage)
    }

    fontSizeHandler = (size: string): void => {
        if (typeof document !== 'undefined') {
            const root: HTMLElement | null = document.documentElement;
            if (root) {
                root.style.setProperty("--base-font-size", size);
            }
            this.setState({
                currentFontSize: size
            });
        }
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
      };
    // Customizable Area End
}
