
// Customizable Area Start
import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpCentreController, { configJSON } from './HelpCentreController'
import Header from "../../../components/src/Header.web";
import Footer from '../../../components/src/Footer.web';
import Banner from '../../../components/src/Banner.web';
import { viewVector } from './assets';


export const useStyles: {} = (theme: {
  breakpoints: { down: (arg0: string) => string; }; spacing: (arg0: number) => string;
}) => ({
  root: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  mainDiv: {
    backgroundImage: "linear-gradient(to bottom, #F5F7F9, #275080)",
    minHeight: '100vh',
    height: 'auto !important',
  },
  faqWrapper: {
    padding: '30px',
    minHeight: '100vh',
    height: 'auto !important',
    textAlign: 'center',
    '& h1': {
      color: '#012C5E',
      borderBottom: '3px solid #012C5E',
      display: 'inline-block',
      fontSize: "40px",
      fontWeight: "700",
      lineHeight: "47px",
      letterSpacing: "0px",
      textAlign: "center",
      '@media(max-width: 700px)': {
        fontSize: "26px",
      },
    }
  },
  errorResponsestyle: {
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "28px",
    letterSpacing: "0px",
    padding: "3%",
    color: "#FC0019"
  },
  accordionSummary: {
    '&.MuiAccordionSummary-root': {
      '& .MuiIconButton-root': {
        color: "#012C5E"
      },
    },
  },
  searchBox: {
    borderRadius: '4px',
    border: '1px solid #0B336A',
    backgroundColor: '#FFFFFF',
    fontSize: '19px',
    fontWeight: '400',
    lineHeight: '28px',
    color: '#073366',
    padding: '12px 55px',
    marginTop:"4px",
    width: '50%',
    margin: 'auto',
    '& input::placeholder': {
      color: '#073366',
      fontWeight: '400',
      lineHeight: '28px',
      fontSize: '18px',
    },
    '&:hover, &:focus': {
      border: '1px solid #012C5E',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
   
  },
  bannerDiv: {
    padding: "2%"
  },

  overrides: {
    MuiOutlinedInput: {
        notchedOutline: {
            border: 'none',
        },
    },
},
  searchBlock: {
    position: "relative",
    marginTop: "1%"
  },
  searchImg: {
    position: "absolute",
    bottom: "24%",
    left: "26%",
    height: "26px",
    '@media(max-width: 700px)': {
      display: "none"
    },
  },
  footer: {
    textAlign: "start"
  },
  questionDiv: {
    display: "flex !important"
  },
  accordianWrapper: {
    paddingTop: '50px',
    '& .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded': {
      marginBottom: '15px',
      borderRadius: '4px',
      border: "1px solid #0B336A"
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      paddingBottom: '8px',
    },
  },
  questionText: {
    color: '#012C5E',
    fontWeight: "600",
    fontSize: "1.18rem",
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  questionindex: {
    color: '#012C5E',
    fontWeight: "600",
    fontSize: "1.18rem",
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "left",
    paddingRight: "10px"

  },
  expandIcon: {
    fontSize: "30px"
  },
  horizontalLine: {
    width: "97%",
    margin: "0px",
    marginLeft: "1.5%"
  },
  answerText: {
    color: '#2C2C2E',
    textAlign: 'start',
    padding: "1.5% 1.3%",
    fontWeight: "500",
    fontSize: "17px",
    lineHeight: "28px",
    letterSpacing: "0px",
  }
})


export class HelpCenterFAQ extends HelpCentreController {


  render() {
    const { classes }: any = this.props;

    return (
      <>
        <div className={classes.mainDiv}>
          <Header
            navigation={undefined}
            id={""}
            navData={this.state.faqnavData}
            fontSizeHandler={this.fontSizehandler}
            currentFontSize={this.state.currentFontSize}
            isLogin={false}
            onLogoutClick={undefined}
          />
          <div className={classes.bannerDiv}>
            <Banner
              title="welcome"
              activeLink="home"
              activePage="FAQs"
              navigation={undefined}
              id={""}
            />
          </div>
          <div className={classes.faqWrapper}>
            <h1>{configJSON.faqTitle}</h1>
            <div className={classes.searchBlock}>
              <TextField className={classes.searchBox}      InputProps={{ disableUnderline: true }}

                data-testid='searchBox' onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.getHelpCentreSearchQA(e) }} placeholder="Search for your question here" margin="normal" variant='standard' />
              <img className={classes.searchImg} src={viewVector} alt="" />
            </div>
            {this.state.searchState ?
              <div>
                <Typography className={classes.errorResponsestyle} >{this.state.faqErrorresponse}</Typography>
              </div> :
              <div className={classes.accordianWrapper}>
                {
                  this.state.faqResponse?.map((item: { id: string; attributes: { question: string; answer: string; } }, index: number) => {
                    return (
                      <>
                        <Accordion key={item.id}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                            data-test-id='accordion-box'
                            className={classes.accordionSummary}
                          >
                            <div className={classes.questionDiv}>
                              <Typography className={classes.questionindex} >
                                {index + 1}.
                              </Typography>
                              <Typography className={classes.questionText} >
                                {item.attributes.question}
                              </Typography>

                            </div>
                          </AccordionSummary>
                          <hr className={classes.horizontalLine} />
                          <AccordionDetails>
                            <Typography className={classes.answerText} color="textSecondary">
                              {item.attributes.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )
                  })
                }
              </div>}
            <div className={classes.footer}>
              <Footer
                navigation={undefined}
                id={""}
                footerNavData={this.state.footerfaqnavData}
              />
            </div>

          </div>
        </div>
      </>
    )
  }
}

export default withStyles(useStyles)(HelpCenterFAQ)

// Customizable Area End