import React from "react";

import {
  Box,
  Button,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  Grid,
  TextField,
  MenuItem
  // Customizable Area Start
  ,
  Typography,
  styled,
  Checkbox,
  FormControlLabel,
  Modal,
  Fade,
  Radio,
  RadioGroup,
  withStyles,
  Tooltip,
  Select,
  // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import * as Yup from 'yup';
export const configJSON = require("./config");
import { ModalDataObject, FormFieldDataObject, FieldTypes } from "./MultipageFormsController";
import { DeleteData, FailureIcon, SuccessIcon, UpdateData, BitmapCopy } from "./assets";
import { NumberField } from "../../../components/src/NumberField";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./index.css"
// Customizable Area End

import MultipageFormsController, {
  Props,
  Gender,
  Country,
  Industry,
} from "./MultipageFormsController";
import { Formik } from "formik";

interface CountryOptionType {
  name?: string,
  dial_code?: string,
  code?: string
  }


const phoneRegExp = /^\+?\d{7,14}$/;
const FormSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Field is Too Short')
    .max(50, 'Field is Too Long!')
    .required('First Name is required'),
    last_name: Yup.string()
    .min(2, 'Field is Too Short')
    .max(50, 'Field is Too Long!')
    .required('Last Name is required'),
    email: Yup.string()
    .email('Enter a valid email')
    .required('Email is required'),
    phone_number: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone Number is required')
    .min(10, 'Phone Number mush to 10 digit')
    .max(12, 'Phone Number mush be less than 12 digit')
});

const FormSchemaStep2 = Yup.object().shape({
    message: Yup.string()
    .required('Message is required'),
    selectedCountry: Yup.string()
    .required('Country is required'),
    selectedIndustry: Yup.string()
    .required('Industry is required'),
    selectedGender: Yup.string()
    .required('Gender is required'),
});


export default class MultipageForms extends MultipageFormsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start  
  steps = this.getSteps();

  renderApplicantFormModal = () => {
    return (
      <Modal
        open={this.state.applicanformModalVisible}
        onClose={this.handleModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}
        style={webStyle.modalContainer}
      >
        <Fade in={this.state.applicanformModalVisible}>
          <Box sx={webStyle.paper}>
            <form>
              <Box>
                {this.state.formFilteredData.map((modalFieldItem: ModalDataObject, mainIndex: number) => {
                     return (
                    <Box key={mainIndex}>
                      <Typography style={webStyle.modalHeading} data-testid="formSectionModalTextId">{modalFieldItem.attributes.name}</Typography>
                      <NoteMessageText>{modalFieldItem.attributes.note}</NoteMessageText>
                      {modalFieldItem.attributes.form_fields.data.map((fieldValueItem: FormFieldDataObject, index: number) => {
                        return (
                          <Box key={index} sx={webStyle.pageFirstInput}>
                            <Box sx={webStyle.FormInputContainer}>
                              <FormFieldName>
                                {fieldValueItem.attributes.field_name}
                              </FormFieldName>
                              {fieldValueItem.attributes.required && this.renderAshtricShow()}
                            </Box>
                            {this.renderFormField(fieldValueItem, configJSON, mainIndex, index,fieldValueItem.attributes.field_name)}
                          </Box>
                        )
                      })}
                    </Box>
                  )
                })}
                <Box sx={webStyle.modalInputButton}>
                  <PrioriteAddCancelButton variant="outlined"
                    data-testid="cancelButtonTestId"
                    onClick={this.handleModalClose}
                  >
                    {configJSON.cancelButtonText}
                  </PrioriteAddCancelButton>
                  <PrioriteAddCancelButton
                    variant="outlined"
                    data-testid={`modalSubmitHandleTestId-${this.state.editIndex}`}
                    onClick={this.state.editIndex ? this.handleSaveEditModalData : this.handleModalSubmit}
                  >
                    {configJSON.addButtonText}
                  </PrioriteAddCancelButton>
                </Box>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    );
  }

  renderFormField = (fieldValueItem: FormFieldDataObject, configJson: { requiredFeildError: string }, mainIndex: number, index: number,fieldName:string) => {
    let isValid = fieldValueItem.attributes.isValid;
    let helperText = isValid && `${configJson.requiredFeildError}`;
    const fieldTypes: FieldTypes = {
      text: (
        <TextAreaFields
          InputProps={{
            inputProps: {
              style:{
                borderRadius:"4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperText),
                height:"5px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
            }
          }}
          fullWidth
          type="text"
          data-testid="applicantModalTextSelectTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem?.attributes?.field_value}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      string: (
        <TextField
          onKeyPress={this.handleKeyPress}
          InputProps={{
            inputProps: {
              style: {
                borderRadius:"4px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: helperText ? "#FF0000" : "#979797",
                height: "15px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
            }
          }}
          fullWidth
          type="text"
          data-testid="applicantStringTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={helperText}
          variant="outlined"
          value={fieldValueItem?.attributes?.field_value}
          name={fieldValueItem.attributes.field_name}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      dropdown: (
        <>
        <Select
          style={{...webStyle.firstSectionSelect,borderColor: this.handleConditionalColors(helperText)}}
          data-testid="applicantDropDownTestId"
          disableUnderline
          error={isValid}
          name={fieldName}
          placeholder={fieldName}
          value={fieldValueItem?.attributes?.field_value}
          onChange={event => this.handleModalChange(event as React.ChangeEvent<{
            name?: string;
            value: string;
          }>, mainIndex, index)}
          IconComponent={() => <ExpandMoreIcon />}
          required={fieldValueItem.attributes.required}
        >
          {fieldValueItem.attributes.form_values.map((option: { value: string }, index: number) => (
            <MenuItem key={index} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <Typography style={webStyle.errorMsg}>{helperText}</Typography>
        </>
      ),
      time: (
        <TextField
          style={{borderColor: this.handleConditionalColors(helperText),}}
          id="time"
          type="time"
          defaultValue="07:30"
          InputLabelProps={{
            shrink: true
          }}
          data-testid="applicantTimeSelectTestId"
          inputProps={{
            step: 300
          }}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          value={fieldValueItem?.attributes?.field_value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      number: (
        <NumberField
        className={this.handleClassnames(helperText)}
        inputProps={{onInput:(event)=>{
          let regex = new RegExp(`^(?:${fieldValueItem.attributes.maximum_number}|[1-9]?` + `\\d)$`);
          this.handleInput(event,regex)
        }
        }}
          id="number"
          type="text"
          data-testid="applicantNumberTestId"
          InputProps={{
            inputProps: {
              maxLength:fieldValueItem.attributes.maximum_number,
              inputMode:"numeric",
              minLength:fieldValueItem.attributes.minimum_number,
              min: 0,
              onInput: (event) => {
                let regex = new RegExp(`^(?:${fieldValueItem.attributes.maximum_number}|[1-9]?` + `\\d)$`);
                this.handleInput(event, regex)
              },
              sx: { borderRadius: "4px" }
            }
          }}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem.attributes.field_value ?? ""}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      float: (
        <NumberField
          className={this.handleClassnames(helperText)}
          id="number"
          type="text"
          InputProps={{
            inputProps: {
              maxLength:fieldValueItem.attributes.maximum_number,
              min: 0,
              appearance: 'none',
              inputMode: "numeric",
              sx: { borderRadius: "4px" }
            }
          }}
          data-testid="applicantFloatTestId"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem.attributes.field_value}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      telephone_number: (
        <TextAreaFields
          style={{borderColor: this.handleConditionalColors(helperText)}}
          fullWidth
          type="text"
          data-testid="mobileTelephoneTypeSelectTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem.attributes.field_value}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          variant="outlined"
          InputProps={{
            inputProps: {
              style:{
                borderRadius: "4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperText),
                height:"5px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
                  appearance: 'none',
                  inputMode: "numeric"
            }
          }}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(inputField, mainIndex, index)}
        />
      ),
      pincode: (
        <TextAreaFields
          style={{borderColor: this.handleConditionalColors(helperText)}}
          data-testid="noOfPagesTextIdOne"
          id="number"
          type="text"
          variant="outlined"
          InputProps={{
            inputProps: {
              style:{
                borderRadius: "4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperText),
                height:"5px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
                  appearance: 'none',
                  inputMode: "numeric",
                  maxLength:6
            }
          }}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem.attributes.field_value}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(inputField, mainIndex, index)}
        />
      ),
      email: (
        <TextAreaFields
          type="text"
          InputProps={{
            inputProps: {
              style: {
                borderRadius:"4px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: helperText ? "#FF0000" : "#979797",
                height: "5px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
              appearance: 'none',
              inputMode:"email"
            }
          }}
          fullWidth
          data-testid="applicantEmailTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{configJSON.emailIdValidationMsg}</Typography>}
          variant="outlined"
          name={fieldValueItem.attributes.field_name}
          value={fieldValueItem?.attributes?.field_value}
          placeholder={`Enter ${fieldValueItem.attributes.field_name}`}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      mobile_number: (
        <TextAreaFields
          style={{borderColor: this.handleConditionalColors(helperText)}}
          fullWidth
          type="text"
          data-testid="mobileNumberSelectTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          InputProps={{
            inputProps: {
              style:{
                borderRadius: "4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperText),
                height:"5px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
              appearance: 'none',
            }
          }}
          value={fieldValueItem?.attributes?.field_value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      checkbox: (
        <FormControlInput
          key={index}
          data-testid="applicantFormControlTestId"
          control={
            <Checkbox
              data-testid="applicantsInfoDeclareCheckBoxTestId"
              disableRipple
              color="default"
              checkedIcon={
                <OuterIcon sx={webStyle.checkIconDefault}>
                  <CheckedIcon></CheckedIcon>
                </OuterIcon>
              }
              icon={<OuterIcon sx={helperText ? webStyle.checkIconErr: webStyle.checkIconDefault}></OuterIcon>}
              inputProps={{ "aria-label": "decorative checkbox" }}
              value={fieldValueItem.attributes.field_value}
              checked={fieldValueItem.attributes.field_value as boolean}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
            />
          }
          label={fieldValueItem.attributes.field_name}
        />
      ),
      boolean: (
        <FormControlInput
          key={index}
          data-testid="applicantsBooleanCheckBoxTestId"
          control={
            <Checkbox
              data-testid="applicantsInfoDeclareCheckBoxTestId"
              disableRipple
              color="default"
              checkedIcon={
                <OuterIcon sx={ webStyle.checkIconDefault}>
                  <CheckedIcon></CheckedIcon>
                </OuterIcon>
              }
              icon={<OuterIcon sx={helperText ? webStyle.checkIconErr: webStyle.checkIconDefault}></OuterIcon>}
              inputProps={{ "aria-label": "decorative checkbox" }}
              value={fieldValueItem?.attributes?.field_value}
              checked={fieldValueItem.attributes.field_value as boolean}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
            />
          }
          label={undefined}
        />
      ),
      radio: (
        <RadioGroup
          row aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group">
          {fieldValueItem.attributes.form_values.map((radioItem: { value: string }, index: number) => (
            <FormControlLabel
              key={index}
              data-testid="applicantsRadioLabelCheckTestId"
              value={radioItem.value}
              control={
                <Radio
                  value={fieldValueItem?.attributes?.field_value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
                />
              }
              label={radioItem.value}
            />
          ))}
        </RadioGroup>
      ),
      date: (
        <TextField
          style={{borderColor: this.handleConditionalColors(helperText)}}
          fullWidth
          id="date"
          type="date"
          data-testid="applicantDateSelectTestId"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          value={fieldValueItem?.attributes?.field_value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
      file: (
        <TextField
          style={{borderColor: this.handleConditionalColors(helperText)}}
          fullWidth
          id="file"
          type="file"
          data-testid="applicantFileTestId"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperText}</Typography>}
          variant="outlined"
          inputProps={{ multiple: true, accept: ".pdf, .doc, .docx, .jpg, .jpeg, .png, .mp4" }}
          value={fieldValueItem?.attributes?.field_value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleModalChange(event, mainIndex, index)}
        />
      ),
    };

    return fieldTypes[fieldValueItem.attributes.field_type] || null;
  };

  renderAddPrioritiesButton = (formSectionName: string) => {
    return (
      <Box sx={webStyle.prioritiesAddButtonApplicant}>
        <ApplicantPrioritiesText
          data-testid="formSectionNameTextId">
          {formSectionName}
        </ApplicantPrioritiesText>
        <Box  sx={webStyle.priotiesButtonContainer}>
        <PriotiesButton>{configJSON.addPriorityButtonText}</PriotiesButton>
        </Box>
      </Box>
    );
  }

  renderFormSectionName = (formSectionName: string) => {
    return (
      <Box sx={formSectionName ? webStyle.noOfDraw : webStyle.withoutSection}>
        <FormSectionName data-testid="formSectionNameTextId">{formSectionName}</FormSectionName>
        {
          this.state.formHoverDescription[0] &&
          <CustomTooltip
          placement="bottom-start"
            arrow
            title={
              <>
                <Typography color="inherit" style={webStyle.tooltipHeading}>{this.state.formHoverDescription[0]} wqwqwqwqwqw</Typography>
                <Typography style={webStyle.tooltipHeading} >{configJSON.gotItButtonText}</Typography>
              </>
            }
          >
            <img style={webStyle.hoverIcon} src={BitmapCopy} />
          </CustomTooltip>
        }
      </Box>
    );
  }

  renderAshtricShow = () => {
    return (
      <Typography style={webStyle.astrix}>{configJSON.astrick}</Typography>
    );
  }

  editDelete = (updateItem: ModalDataObject, index: number) => {
    return (
      <Box sx={webStyle.editDeleteMain}>
        <Box sx={webStyle.editDeleteButton}>
          <img
            data-testid="editDataTestId"
            onClick={() => this.handleEdit(updateItem, index)}
            src={UpdateData} />
          <img
            data-testid="DeleteDataTestId"
            onClick={() => this.handleDelete(index)}
            src={DeleteData} />
        </Box>
      </Box>
    )
  }

  editSectionShowChunk = (item: ModalDataObject, mainIndex: number,) => {
    return (
      item && item.attributes.multiple ?
        this.editDelete(item, mainIndex) :
        this.renderFormSectionName(item?.attributes.name)
    )
  }

  renderToolTipIcon=(information_icon:boolean,note:string | undefined)=>{
    return(
      information_icon &&
      <CustomTooltip
            placement="bottom-start"
            arrow
            title={
              <>
                <Typography color="inherit" style={webStyle.tooltipHeading}>{note}</Typography>
                <Typography style={webStyle.tooltipHeading} >{configJSON.gotItButtonText}</Typography>
              </>
            }
          >
            <img style={webStyle.hoverIcon} src={BitmapCopy} />
      </CustomTooltip>
    ) 
  }

  renderApplicantButton = (index: number) => {
    return (
      <Box sx={webStyle.prioritiesAddButtonApplicant}>
        <ApplicantPrioritiesText>{configJSON.applicantsHeading}</ApplicantPrioritiesText>
        <Box sx={webStyle.priotiesButtonContainer}>
          <PriotiesButton
            className="addApplicantBtn"
            data-testid="applicantModalButtonTestId"
            onClick={() => this.applicantModalShow(index)}
            disabled={this.state.isDisabled}
          >{configJSON.addApplicantsButtonText}</PriotiesButton>
        </Box>
      </Box>
    );
  }

  renderSectionNameButtonNoteShow = (item: ModalDataObject, mainIndex: number) => {
    return (
      <>
        {
          !item?.attributes.multiple &&
            item?.attributes.name == "Priorities" ?
            this.renderAddPrioritiesButton(item.attributes.name) :
            this.editSectionShowChunk(item, mainIndex)
        }
        {!item?.attributes.multiple &&
          <NoteMessageText>{item?.attributes.note}</NoteMessageText>
        }
      </>
    )
  }

  renderFieldValueShow = (fielItemValue: FormFieldDataObject, item: ModalDataObject) => {
    return (
      <>
        {fielItemValue.attributes.field_type !== "checkbox" && (
          <Box sx={webStyle.fieldNameValue}>
            <Box sx={webStyle.addNameValue}>
              <FormFieldName>
                {fielItemValue.attributes.field_name}
              </FormFieldName>
              &nbsp;{fielItemValue.attributes.required && this.renderAshtricShow()}
              {fielItemValue.attributes.information_icon && this.renderToolTipIcon(fielItemValue.attributes.information_icon,fielItemValue.attributes.note )}
            </Box>
            <Box sx={webStyle.addNameValue}>
              <FormFieldName>
                {item.attributes.multiple ? ` - ${fielItemValue.attributes.field_value}` : ""}
              </FormFieldName>
            </Box>
          </Box>
        )}
      </>
    )
  }

  renderAllFunction = (fielItemValue: FormFieldDataObject, configJson: { requiredFeildError: string }, mainIndex: number, index: number,formID:string,mainID:string,item:ModalDataObject) => {
    let isValid = fielItemValue.attributes.isValid;
    let helperTextForm = isValid && configJson.requiredFeildError;
    let fieldTypeFactories: FieldTypes = {
      text: (
        <TextAreaFields
          fullWidth
          inputProps={{
            maxLength :fielItemValue.attributes.maximum_number,
            minLength:fielItemValue.attributes.minimum_number
          }}
          type="text"
          data-testid="applicationTypeSelectTestId"
          id="outlined-select-currency-native"
          variant="outlined"
          disabled={fielItemValue.attributes.information_icon && fielItemValue.attributes.field_name==="Type of Application"}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.information_icon && fielItemValue.attributes.field_name==="Type of Application" ? this.state.sortedFormSection[0].attributes.form_fields.data[1].attributes.field_value : fielItemValue.attributes.field_value}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          InputProps={{
            inputProps: {
              style:{
                borderRadius: "4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperTextForm),
                height:"5px",
                WebkitAppearance: 'textfield',
                margin: "0px",
              },
              appearance: 'none',
              maxLength:fielItemValue.attributes.maximum_number,
              minLength:fielItemValue.attributes.minimum_number
            }
          }}
        />
      ),
      string: (
        <TextField
          InputProps={{
            inputProps: {
              style: {
                borderRadius:"4px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: helperTextForm ? "#FF0000" : "#979797",
                height: "15px",
                WebkitAppearance: 'textfield', margin: "0px",
              },
              appearance: 'none',
              maxLength:fielItemValue.attributes.maximum_number,
              minLength:fielItemValue.attributes.minimum_number
            }
          }}
          fullWidth
          type="text"
          data-testid="stringTypeSelectTestId"
          id="outlined-select-currency-native"
          variant="outlined"
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
        />
      ),
      boolean: (
        <FormControlInput
          data-testid="booleanFormControlTestId"
          control={
            <Checkbox
              data-testid="applicantsInfoDeclareCheckBoxTestId"
              disableRipple
              color="default"
              name={fielItemValue.attributes.field_name}
              value={fielItemValue.attributes.field_value}
              checked={fielItemValue.attributes.field_value as boolean}
              checkedIcon={
                <OuterIcon sx={ webStyle.checkIconDefault}>
                  <CheckedIcon></CheckedIcon>
                </OuterIcon>
              }
              icon={<OuterIcon sx={helperTextForm ? webStyle.checkIconErr: webStyle.checkIconDefault}></OuterIcon>}
              inputProps={{ "aria-label": "decorative checkbox" }}
              onChange={(event) => this.handleCheckBoxChange(event, mainID, index,formID)}

            />
          }
          label={undefined}
        />
      ),
      dropdown: (
        <>
        <Select
          style={{...webStyle.firstSectionSelect,borderColor: this.handleConditionalColors(helperTextForm)}}
          data-testid="dropDownTypeSelectTestId"
          disableUnderline
          error={isValid}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          onChange={(inputField) => this.handleChange(inputField as React.ChangeEvent<{
            name?: string;
            value: string;
          }>, mainIndex, index)}
          IconComponent={() => <ExpandMoreIcon />}
          required={fielItemValue.attributes.required}
          >
          {fielItemValue.attributes.form_values.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
          <Typography style={webStyle.errorMsg}>
            {helperTextForm}
          </Typography>
        </>
      ),
      time: (
        <TextField
          id="time"
          type="time"
          data-testid="timeSelectTestId"
          defaultValue="07:30"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 300
          }}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      number: (
        <NumberField
          data-testid="noOfPagesTextId"
          inputProps={{onInput:(event)=>{
            let regex = new RegExp(`^(?:${fielItemValue.attributes.maximum_number}|[1-9]?\d)$`);
          this.handleInput(event,regex)
          }}}
          className={this.handleClassnames(helperTextForm)}
          id="number"
          type="text"
          variant="outlined"
          InputProps={{
            inputProps: {
              style: { WebkitAppearance: 'none', margin: "0px" ,},
              maxLength:fielItemValue.attributes.maximum_number,
              minLength:fielItemValue.attributes.minimum_number,
              inputMode:"numeric",
              sx: { borderRadius: "4px" },
              onInput: (event) => {
                let regex = new RegExp(`^(?:${fielItemValue.attributes.maximum_number}|[1-9]?` + `\\d)$`);
                this.handleInput(event, regex)
              }
            },
          }}
          error={false}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          onChange={(inputField) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      float: (
        <NumberField
        className={this.handleClassnames(helperTextForm)}
          id="number"
          type="text"
          data-testid="minMaxNumberSelectTestId"
          InputProps={{
            inputProps: {
              maxLength:fielItemValue.attributes.maximum_number,
              minLength:fielItemValue.attributes.minimum_number,
              appearance: 'none',
              style:{
                borderRadius:"4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperTextForm)
              },
            }
          }}
          variant="outlined"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value ?? ""}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      checkbox: (
          <FormControlInput
            key={index}
            data-testid="applicantCheckBocControlTestId"
            control={
              <Checkbox
                data-testid="applicantsInfoDeclareCheckBoxTestId"
                disableRipple
                color="default"
                name={fielItemValue.attributes.field_name}
                value={fielItemValue?.attributes?.field_value}
                checked={fielItemValue.attributes.field_value as boolean}
                onChange={(event) => this.handleCheckBoxChange(event, mainID, index, formID)}
                checkedIcon={
                  <OuterIcon sx={webStyle.checkIconDefault}>
                    <CheckedIcon></CheckedIcon>
                  </OuterIcon>
                }
                icon={<OuterIcon sx={helperTextForm ? webStyle.checkIconErr : webStyle.checkIconDefault}></OuterIcon>}
                inputProps={{ "aria-label": "decorative checkbox" }}
              />
            }
            label={fielItemValue.attributes.field_name}
          />
      ),
      radio: (
        <RadioGroup
          data-testid="formControlRadioButtonTestId"
          row
          key={index}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        >
          {fielItemValue.attributes.form_values.map((radioItem, idx) => (
            <FormControlLabel
              key={idx}
              data-testid="formControlRadioButtonTestId"
              value={radioItem.value}
              name={fielItemValue.attributes.field_name}
              control={
                <Radio />
              }
              label={radioItem.value}
            />
          ))}
        </RadioGroup>
      ),
      date: (
        <TextField
          style={{...webStyle.dateStyle,borderColor: this.handleConditionalColors(helperTextForm)}}
          key={index}
          id="date"
          type="date"
          variant="outlined"
          data-testid="dateSelectTestId"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      file: (
        <TextField
          key={index}
          id="file"
          type="file"
          data-testid="fileShareSelectTestId"
          variant="outlined"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={this.state.resertAllForm ? "" : fielItemValue.attributes.field_value}
          inputProps={{ multiple: true, accept: ".pdf, .doc, .docx, .jpg, .jpeg, .png, .mp4",style:{...webStyle.errorfield,borderColor: this.handleConditionalColors(helperTextForm)} }}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      email: (
        <TextAreaFields
        inputProps={{
          style:{...webStyle.errorfield,borderColor: this.handleConditionalColors(helperTextForm)}
        }}
          fullWidth
          type="text"
          data-testid="applicationEmailTestId"
          id="outlined-select-currency-native"
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{isValid && configJSON.emailIdValidationMsg}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          variant="outlined"
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      ),
      mobile_number: (
        <TextAreaFields
          fullWidth
          InputProps={{
            inputProps: {
              style:{
                borderRadius:"4px",
                borderWidth:"1px",
                borderStyle:"solid",
                borderColor: this.handleConditionalColors(helperTextForm),
                height:"5px",
                margin: "0px",
              },
              appearance: 'none',
              inputMode: "numeric",
            }
          }}
          type="text"
          data-testid="mobileNumberTypeSelectTestId"
          id="outlined-select-currency-native"
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
          error={isValid}
          name={fielItemValue.attributes.field_name}
          helperText={<Typography style={webStyle.errorMsg}>{isValid && configJSON.mobileNumberValidationMsg}</Typography>}
          value={fielItemValue.attributes.field_value}
          variant="outlined"

        />
      ),
      telephone_number: (
        <TextAreaFields
        InputProps={{
          inputProps: {
            style:{
              borderRadius:"4px",
              borderWidth:"1px",
              borderStyle:"solid",
              borderColor: this.handleConditionalColors(helperTextForm),
              height:"5px",
              margin: "0px",
            },
            appearance: 'none',
            inputMode: "numeric"
          }
        }}
          type="text"
          fullWidth
          variant="outlined"
          data-testid="mobileNumberTelePhoneTypeSelectTestId"
          id="outlined-select-currency-native"
          value={fielItemValue.attributes.field_value}
          error={isValid}
          name={fielItemValue.attributes.field_name}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
          helperText={<Typography style={webStyle.errorMsg}>{isValid && configJSON.telephoneNumberValidationMsg}</Typography>}
        />
      ),
      pincode: (
        <TextAreaFields
          data-testid="pincodeTypeSelectTestId"
          style={{ width: "30inch" }}
          id="number"
          type="text"
          variant="outlined"
          InputProps={{
            inputProps: {
              style: { WebkitAppearance: 'textfield', margin: "0px",borderColor:"red" },
              appearance: 'none',
              inputMode: "numeric",
              maxLength:fielItemValue.attributes.maximum_number
            }
          }}
          error={isValid}
          helperText={<Typography style={webStyle.errorMsg}>{helperTextForm}</Typography>}
          name={fielItemValue.attributes.field_name}
          value={fielItemValue.attributes.field_value}
          onChange={(inputField: React.ChangeEvent<HTMLInputElement>) => this.handleChange(inputField, mainIndex, index)}
        />
      )
    };
    return fieldTypeFactories[fielItemValue.attributes.field_type] || null;
  };

  addInventorButtonShow = (fielItemValue: FormFieldDataObject, configJSON: { inventorCopyDataText: string, inventorHeadingText: string, addInventorButtonText: string }, mainIndex: number) => {
    return fielItemValue.attributes.field_name == configJSON.inventorCopyDataText && (
      <Box sx={webStyle.prioritiesAddButtonApplicant}>
        <ApplicantPrioritiesText>{configJSON.inventorHeadingText}</ApplicantPrioritiesText>
        <Box sx={webStyle.priotiesButtonContainer}>
        <PriotiesButton
        className="addApplicantBtn"
        disabled={this.state.isDisabledInventor}
          onClick={() => this.applicantModalShow(mainIndex)}
        >{configJSON.addInventorButtonText}</PriotiesButton>
      </Box>
      </Box>
    )
  }

  renderSaveSubmitFormModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={webStyle.modal}
        open={this.state.openSavedModal}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={this.state.openSavedModal}>
          <Box sx={webStyle.savedModalContainer}>
            <img src={SuccessIcon} />
            <Typography>{this.state.formSubmitSuccssMessage}</Typography>
            <Typography style={webStyle.savedModalHeading}>
              {this.state.successDataMsg}
            </Typography>
            <Typography style={webStyle.savedModalPara}>
              {this.state.formReferenceId}
            </Typography>
            <Button
              data-testid="doneButtonModalTestId"
              onClick={this.successFormModalClose}
              style={webStyle.savedModalButton}>{configJSON.doneButtonText}</Button>
          </Box>
        </Fade>
      </Modal>
    )
  }

  renderFormSubmitFailModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={webStyle.modal}
        open={this.state.openFailedModal}
        onClose={this.handleFailedModalClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.state.openFailedModal}>
          <Box sx={webStyle.failedModalContainer}>
            <img src={FailureIcon} />
            <Typography>{configJSON.failedFormText}</Typography>
            <Typography style={webStyle.savedModalHeading}>
              {configJSON.failedFormText}
            </Typography>
            <Typography style={webStyle.savedModalPara}>
              {configJSON.tryAgainText}
            </Typography>
            <Button
              data-testid="retryButtonTestId"
              onClick={this.handleFailedModalClose}
              style={webStyle.savedModalButton}>{configJSON.retryButtonText}</Button>
          </Box>
        </Fade>
      </Modal>
    );
  }
  // Customizable Area End

  render() {
    if (!this.state?.token_local) {
      return (
        <>
          <h3 style={{ color: "red" }}>Token expired</h3>
          <Button
            data-test-id="btnLogin"
            style={{ backgroundColor: "blue", color: "white" }}
            onClick={this.LoginPage}
          >
            Sign In
          </Button>
        </>
      );
    }
    return (
      // Customizable Area Start
      <Box sx={webStyle.mainContainer}>
      <Box sx={webStyle.formContainerDashbaord}>
        <div
          data-testid="formSubmitTestId"
        >
          {this.state.sortedFormSection.filter(item=>item).map((item: ModalDataObject, mainIndex: number) => {
            return (
                <Box key={mainIndex}>
                  <Box key={mainIndex} sx={webStyle.priotiMain}>
                    {this.renderSectionNameButtonNoteShow(item, mainIndex)}
                    <Box>
                      {item?.attributes.form_fields.data.map((fielItemValue: FormFieldDataObject, index: number) => {
                        return (
                          <Box key={index} sx={webStyle.pageFirstInput}
                            data-testid="checkBoxTestId"
                          >
                            {this.renderFieldValueShow(fielItemValue, item)}
                            {!item?.attributes.multiple && this.renderAllFunction(fielItemValue, configJSON, mainIndex, index,item.id,fielItemValue.id,item)
                            }
                            {fielItemValue.attributes.note && this.state.sortedFormSection[0]?.attributes.form_fields.data.length === fielItemValue.attributes.position && <NoteMessageText> {fielItemValue.attributes.note}</NoteMessageText>}
                            {this.addInventorButtonShow(fielItemValue, configJSON, mainIndex)}
                          </Box>
                        )
                      })}
                    </Box>
                    {item && item.attributes.name.toLowerCase() === "priorities" && this.renderApplicantButton(mainIndex)}
                    {this.state.uploadAllForm &&  this.state.sortedFormSection.filter(item=>item).length-2 === mainIndex && this.handleValidationOf(mainIndex) ? <NoteMessageText>{configJSON.checkBoxError}</NoteMessageText> : null}
                    { this.state.uploadAllForm && this.state.sortedFormSection.filter(item=>item).length-1 === mainIndex && this.handleValidationOf(mainIndex) ? <NoteMessageText>{configJSON.agreeTermCondtionText}</NoteMessageText> : null}
                </Box>
                </Box>
            );
          })}
            <NoteMessageText>
              {this.state.formSubmitError.errors && this.state.formSubmitError.errors[0]}
            </NoteMessageText>
          <Box sx={webStyle.resetSaveUploadButton}>
            {
              this.state.resertAllForm
                ?
                <ResepeActiveBtn data-testid="resetAllFeildButtonTestId" onClick={this.handleResetForm}>{configJSON.resetButtonText}</ResepeActiveBtn>
                :
                <ResepeUnActiveBtn data-testid="resetAllFeildButtonTestId" onClick={this.handleResetForm}>{configJSON.resetButtonText}</ResepeUnActiveBtn>
            }
            {this.state.saveAllForm
              ?
              <ResepeActiveBtn data-testid="saveFormButtonTestId" onClick={()=>this.handleSubmit('save_form')} >{configJSON.saveButtonText}</ResepeActiveBtn>
              :
              <ResepeUnActiveBtn data-testid="saveFormButtonTestId" onClick={()=>this.handleSubmit('save_form')} >{configJSON.saveButtonText}</ResepeUnActiveBtn>
            }
            {this.state.uploadAllForm ?
              <ResepeActiveBtn data-testid="uploadFormButtonTestId" onClick={()=>this.handleSubmit('submit')} >{configJSON.uploadButtonText}</ResepeActiveBtn>
              :
              <ResepeUnActiveBtn data-testid="uploadFormButtonTestId" onClick={()=>this.handleSubmit('submit')} >{configJSON.uploadButtonText}</ResepeUnActiveBtn>
            }
          </Box>
        </div>
        {this.renderApplicantFormModal()}
        {this.renderSaveSubmitFormModal()}
        {this.renderFormSubmitFailModal()}
      </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  boxStyle: {
    maxWidth: "620px",
    margin: "0 auto"
  },
  successMsg: {
    color: 'red',
    display: 'flex',
    justifyContent: 'center',
  },
  errorMsg: {
    color: '#FF0000',
    fontSize: '14px',
    paddingTop: '2px',
    marginBottom:"2px"
  },
  mainContainer:{
    flex: 1,
    padding: "0.6rem",
    background: `linear-gradient(360deg, #275080 1.98%, rgba(245, 247, 249, 0.712986) 100%)`,
    borderRadius:"8px",
    marginTop:"8px"
  },
  formContainerDashbaord: {
    flex: 1,
    padding: "0.6rem",
    backgroundColor: "#FFF",
    borderRadius:"8px"
  },
  priotiMain: {
    borderRadius: "10px",
    border: "1px solid #D7D7D7",
    backgroundColor: "#FFF",
    marginBottom: "20px",
    padding: "20px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginLeft: "0px"
    }
  },
  noOfDraw: {
    width: "100%",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "5px",
    display: "flex",
    alignItems: "center"
  },
  tooltipHeading: {
    fontSize: "16px",
    fontWeight: 500,
    marginTop: "8px",
    cursor: "pointer",
  },
  withoutSection: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  astrix: {
    color: "red"
  },
  hoverIcon: {
    width: "17px",
    height: "17px",
    marginLeft: "2px"
  },
  pageFirstInput: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginLeft: "0px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "18.5px 14px"
    }
  },
  FormInputContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "3px 0px 6px 0px",
    alignItems: "center"
  },
  modalContainer: {
    overflow: "scroll",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline"
  },
  paper: {
    padding: "10px 20px 10px 20px",
    backgroundColor: "white",
    color: "#000",
    width: "600px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    borderRadius: "8px",
    "@media(max-width:767px)": {
      width: "400px",
    },
    "@media(max-width:575px)": {
      width: "280px"
    }
  },
  modalHeading: {
    fontSize: "24px",
    fontWeight: 700
  },
  ModalInputPara: {
    color: "red",
    fontSize: "16px",
    "@media (max-width: 600px)": {
      fontSize: "14px !important",
      fontWeight: 400
    },
    "@media (max-width: 490px)": {
      fontSize: "12px !important",
      fontWeight: 300
    }
  },
  prioritiesAddButton: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    alignItems: "center",
    gap: "4rem",
    borderBottom: "1px solid #D7D7D7",
    paddingBottom: "10px",
    "@media (max-width: 600px)": {
      gap: "2rem"
    },
    "@media (max-width: 490px)": {
      gap: "0.5rem"
    },
    "@media (max-width: 390px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  priotiesButtonContainer : {
    width:"70%",
    "@media (max-width: 490px)": {
      width:"100%",
    },
  },
  prioritiesAddButtonApplicant: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "15px",
    alignItems: "center",
    gap: "4rem",
    "@media (max-width: 600px)": {
      gap: "2rem"
    },
    "@media (max-width: 490px)": {
      gap: "0.5rem"
    },
    "@media (max-width: 390px)": {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  editDeleteMain: {
    display: "flex",
    justifyContent: "flex-end"
  },
  editDeleteButton: {
    display: "flex",
    gap: "20px"
  },

  modalInputButton: {
    marginTop: "20px",
    display: "flex",
    gap: "20px"
  },
  resetSaveUploadButton: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "1.5rem",
    margin: "20px 0px",
    "@media(max-width: 600px)": {
      gap: "1rem"
    },
    "@media(max-width: 450px)": {
      gap: "0.5rem"
    }
  },
  formHeaderContainer: {
    marginBottom: "15px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginLeft: "0px"
    }
  },
  profileHeading: {
    fontSize: "18px",
    fontWeight: 500
  },
  ModalInputButton: {
    marginTop: "20px",
    display: "flex",
    gap: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  savedModalContainer: {
    height: "400px",
    width: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    color: "#000",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "20px",
    gap: "10px",
    "@media(max-width:475px)": {
      width: "280px",
      height: "280px",
    },
  },
  savedModalHeading: {
    fontSize: "20px",
    fontWeight: 500,
    "@media(max-width:475px)": {
      fontSize: "16px",
    },
  },
  savedModalPara: {
    fontSize: "14px",
  },
  savedModalButton: {
    backgroundColor: "rgb(22,67,111)",
    backgroundImage:
      "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
    color: "#fff",
    width: "100px",
    height: "35px",
    "&:hover": {
      backgroundColor: "rgb(22,67,111)",
      backgroundImage:
        "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
      color: "#fff",
    },
  },
  failedModalContainer: {
    color: "red",
    height: "380px",
    width: "380px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "20px",
    gap: "10px",
    "@media(max-width:475px)": {
      width: "270px",
      height: "270px",
    }
  },
  fieldNameValue: {
    display: "flex",
    alignItems: "center"
  },
  addNameValue: {
    width: "50%",
    display: "flex",
    alignItems: "center"
  },
  dateStyle:{
    width:'25%'
  },
  firstSectionSelect: {
    border: "1px solid #979797",
    height: "44px !important",
    borderRadius: "4px",
    padding: "5px 12px 3px 11px",
  },
  errorfield :{
    borderWidth:"1px",
    borderStyle:"solid",
    height:"5px",
    borderRadius: "4px",
  },
  checkIconErr:{
   border: ".5px solid red"
  },
  checkIconDefault:{
   border: "0.5px solid #979797",
   backgroundColor:"#979797"
  }
};

const CheckedIcon = styled(Box)({
  borderRadius: "4px",
  backgroundColor: "#0B336A",
  border : "1px solid #d5cece",
  "&:before": {
    width: "16px",
    height: "16px",
    display: "block",
    backgroundImage: "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    content: "''"
  },
  "input:hover ~ &": {
    backgroundColor: "rgba(0,0,0,0)"
  },
  "& .MuiIconButton-label": {
    border: "1px solid #d5cece",
    width: "100%",
    display: "flex",
    alignItems: "inherit",
    justifyContent: "inherit",
    padding: "4px",
    borderRadius: "4px"
  }
});

const OuterIcon = styled(Box)({
  background: `linear-gradient(0deg, #F2F2F2, #F2F2F2),
  linear-gradient(0deg, #979797, #979797)`,
  width: "26px",
  height: "26px",
  display: "flex",
  alignItems: "inherit",
  justifyContent: "inherit",
  borderRadius: "4px",
  "&:before": {
    backgroundColor: "#dbdbdbd"
  }
});

const FormControlInput = styled(FormControlLabel)({
  color: "#000",
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#0B336A"
  }
});


const TextAreaFields = styled(TextField)({
  "& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button": {
    webkitAppearance: "none",
    mozAppearance: "none",
    appearance: "none",
    margin: "0",
    color:'green'
  },
  "& .MuiOutlinedInput-multiline": {
    padding: "0px",
    width: "100%"
  },
  "& .MuiBox-root-95 .MuiOutlinedInput-input": {
    padding: "0px",
    width: "100%",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#f44336",
    marginLeft: "0px"
  },
  "& .MuiOutlinedInput-root":{
    height:"44px !important",
  },
});

const PriotiesButton = styled(Button)({
  color: "#FFF",
  width: "100%",
  maxWidth: "310px",
  fontSize: "16px",
  height: "48px",
  flexShrink: 0,
  borderRadius: "4px",
  backgroundColor: "rgb(22,67,111)",
  backgroundImage: "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
  alignItems: "center",
  justifyContent: "center",
  textTransform: "capitalize",
  "@media (max-width: 600px)": {
    height: "45px",
    fontSize: "14px",
    maxWidth: "240px"
  },
  "@media (max-width: 490px)": {
    height: "35px",
    fontSize: "12px",
    maxWidth: "160px"
  },"&.MuiButton-root.Mui-disabled": {
    color:"#FFF !important"
}
});

const PrioriteAddCancelButton = styled(Button)({
  display: "flex",
  width: "160px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #0B336A",
  backgroundImage: "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
  color: "#fff",
  textAlign: "center",
  fontSize: "16px",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "rgb(22,67,111)",
    backgroundImage: "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
    color: "#fff"
  }
});

const ResepeActiveBtn = styled(Button)({
  display: "flex",
  width: "160px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #0B336A",
  backgroundColor: "#09356D",
  color: "#fff",
  textAlign: "center",
  fontSize: "16px",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#09356D",
    color: "#fff"
  },
  "@media (max-width: 600px)": {
    width: "145px",
    height: "35px",
    fontSize: "14px",
    fontWeight: 600
  },
  "@media (max-width: 450px)": {
    width: "130px",
    height: "30px",
    fontSize: "12px",
    fontWeight: 600
  }
});

const ResepeUnActiveBtn = styled(Button)({
  display: "flex",
  width: "160px",
  height: "40px",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  border: "1px solid #0B336A",
  backgroundColor: "#F5F7FA",
  color: "#09356D",
  textAlign: "center",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  "@media (max-width: 600px)": {
    width: "145px",
    height: "35px",
    fontSize: "14px",
    fontWeight: 600
  },
  "@media (max-width: 450px)": {
    width: "130px",
    height: "30px",
    fontSize: "12px",
    fontWeight: 600
  }
});

const FormSectionName = styled(Typography)({
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: "24px",
  fontWeight: 700,
  "@media (max-width: 720px)": {
    fontSize: "18px !important",
    fontWeight: 600
  },
  "@media (max-width: 490px)": {
    fontSize: "14px !important",
    fontWeight: 600
  }
});

const FormFieldName = styled(Typography)({
  color: "var(--Black, #000)",
  fontSize: "18px",
  fontWeight: 500,
  padding: "10px 0px",
  "@media (max-width: 600px)": {
    fontSize: "14px !important",
    fontWeight: 400
  },
  "@media (max-width: 490px)": {
    fontSize: "12px !important",
    fontWeight: 300
  }
});

const ApplicantPrioritiesText = styled(Typography)({
  color: "#000",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  width:"150px",
  "@media (max-width: 600px)": {
    fontSize: "14px",
    fontWeight: 400
  },
  "@media (max-width: 490px)": {
    fontSize: "12px",
    fontWeight: 300,
    textAlign: "start"
  }
});

const NoteMessageText = styled(Typography)({
  color: "#FF0000",
  fontSize: "16px",
  marginTop: "10px",
  "@media (max-width: 600px)": {
    fontSize: "14px !important",
    fontWeight: 400
  },
  "@media (max-width: 490px)": {
    fontSize: "12px !important",
    fontWeight: 300
  }
});

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#07336C",
    color: "#fff",
    maxWidth: "500px",
    minWidth: "150px",
    fontSize: "16px",
    fontWeight: 600,
    padding: "10px 20px 15px 20px",
    border: "1px solid rgb(7,51,108)",
    fontFamily:"Roboto !important",
    borderRadius: "8px",
    marginTop:"20px",
    marginLeft:"-8.5px",
    "& .MuiTooltip-arrow": {
      color: "rgb(7,51,108)",
      fontSize: "40px",
      width: "42px",
      height: "35px",
      marginLeft:"-2px",
      marginTop:"5px"
    }
  }
}))(Tooltip);
// Customizable Area End
