import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config.js");
import { Message } from "framework/src/Message";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface NavItem {
  link: string;
  path: string;
}

interface FooterNavItem {
  name: string;
  link: string;
}

interface ValidResponseType {
  error_message: string;
  message: ValidResponseType;
  data: object;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  showCurrentPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  showCaptcha: boolean;
  currentPasswordValue: string;
  newPasswordValue: string;
  confirmPasswordValue: string;
  captchaValue: string;
  currentPasswordErrorTxt: string;
  newPasswordErrorTxt: string;
  confirmPasswordErrorTxt: string;
  captchaErrorTxt: string;
  showCurrentPasswordValidation: boolean;
  showNewPasswordValidation: boolean;
  showNewConfirmPasswordValidation: boolean;
  showCaptchaValidation: boolean;
  generatedPasswordCaptcha: string;
  isDashModalOpen: boolean;
  apiResponseValue: string;
  errorAlertChangePswrd: boolean;
  errorAlertMsgChangePswrd: string
  navData: NavItem[];
  footerNavData: FooterNavItem[];
  currentFontSize: string;
  // Customizable Area End
}
interface SS {
  id: string;
}

export default class UTSLoginScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loginApiCallId: string = "";
  acceptTermsAndConditionAPICallId: string = "";
  getTermsAndConditionAPICallId: string = "";
  changePswrdAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];
    // Customizable Area End
    this.state = {
      // Customizable Area Start
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showCaptcha: false,
      currentPasswordValue: "",
      newPasswordValue: "",
      confirmPasswordValue: "",
      captchaValue: "",
      currentPasswordErrorTxt: "",
      newPasswordErrorTxt: "",
      confirmPasswordErrorTxt: "",
      captchaErrorTxt: "",
      showCurrentPasswordValidation: false,
      showNewPasswordValidation: false,
      showNewConfirmPasswordValidation: false,
      showCaptchaValidation: false,
      generatedPasswordCaptcha: '',
      isDashModalOpen: false,
      apiResponseValue: "",
      errorAlertChangePswrd: false,
      errorAlertMsgChangePswrd: "",
      navData:
        [
          {
            link: "Home",
            path: "/"
          },
          {
            link: "FAQs",
            path: "/faqs"
          },
          {
            link: "Feedback",
            path: "/feedback"
          },
          {
            link: "Sign Up",
            path: "/signUp"
          }
        ],
      footerNavData: [
        {
          name: "Terms and Conditions",
          link: "/terms-conditions"
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy"
        },
        {
          name: "Copyright",
          link: ""
        },
        {
          name: "Hyperlinking Policy",
          link: ""
        },
        {
          name: "Accessibility",
          link: ""
        },
        {
          name: "Archive",
          link: ""
        },
        {
          name: "Contact Us",
          link: ""
        },
        {
          name: "Sitemap",
          link: ""
        }],
      currentFontSize: "16px"
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showCurrentPasswordClick = () => {
    this.setState({
      showCurrentPassword: !this.state.showCurrentPassword
    });
  };

  async componentDidMount() {
    super.componentDidMount();
    this.setState({ generatedPasswordCaptcha: this.generateCaptcha(5) })
  }

  showNewPassword = () => {
    this.setState({
      showNewPassword: !this.state.showNewPassword
    });
  };

  showConfirmPassword = () => {
    this.setState({
      showConfirmPassword: !this.state.showConfirmPassword
    });
  };

  showCaptcha = () => {
    this.setState({
      showCaptcha: !this.state.showCaptcha
    });
  };

  onSubmitChangePwd = () => {
    let confirmPasswordError = "";
    if (this.state.currentPasswordValue === "") {
      this.setState({ showCurrentPasswordValidation: true, currentPasswordErrorTxt: "Current Password is Required." });
    }
    if (this.state.newPasswordValue === "") {
      this.setState({ showNewPasswordValidation: true, newPasswordErrorTxt: "New Password is Required." });
    }
    if (this.state.confirmPasswordValue === "") {
      this.setState({ showNewConfirmPasswordValidation: true, confirmPasswordErrorTxt: "Confirm Password is Required." });
    }
    if (this.state.newPasswordValue !== this.state.confirmPasswordValue) {
      confirmPasswordError = "Confirm Password doesn't match.";
      this.setState({ showNewConfirmPasswordValidation: true, confirmPasswordErrorTxt: confirmPasswordError });
    }
    if (this.state.captchaValue === "") {
      this.setState({ showCaptchaValidation: true, captchaErrorTxt: "Captcha is Required." });
    }
    if (this.state.captchaValue !== this.state.generatedPasswordCaptcha) {
      this.setState({ showCaptchaValidation: true, captchaErrorTxt: "Incorrect Captcha" });
    }
  
    if (
      this.state.currentPasswordValue !== "" &&
      this.state.newPasswordValue !== "" &&
      this.state.confirmPasswordValue !== "" &&
      this.state.newPasswordValue === this.state.confirmPasswordValue &&
      this.state.captchaValue === this.state.generatedPasswordCaptcha
    ) {
      this.setState(
        {
          showCurrentPasswordValidation: false,
          showNewPasswordValidation: false,
          showNewConfirmPasswordValidation: false,
          showCaptchaValidation: false,
          currentPasswordErrorTxt: "",
          newPasswordErrorTxt: "",
          confirmPasswordErrorTxt: "",
          captchaErrorTxt: "",
          showCurrentPassword: false,
          showNewPassword: false,
          showConfirmPassword: false,
          showCaptcha: false,
        },
        () => this.changePswrd()
      );
    }
  };
  

  handleConfirmPasswordValidation = () => {
    if (this.state.confirmPasswordValue !== this.state.newPasswordValue) {
      this.setState({ showNewConfirmPasswordValidation: true, confirmPasswordErrorTxt: "Confirm Password doesn't match." });
    } else {
      this.setState({ showNewConfirmPasswordValidation: false, confirmPasswordErrorTxt: "" });
    }
  }

  modalCloseHandler = () => {
    this.setState({
      isDashModalOpen: false
    });
  };

  onChangeCurrentPassword = (event: { target: { value: string } }) => {
    this.setState({ currentPasswordValue: event.target.value }, () => {
      if (this.state.currentPasswordValue === "") {
        this.setState({ showCurrentPasswordValidation: true, currentPasswordErrorTxt: "Current Password is Required." });
      } else {
        this.setState({ showCurrentPasswordValidation: false, currentPasswordErrorTxt: "" });
      }
    });
  };

  onChangeNewPassword = (event: { target: { value: string } }) => {
    const newPassword = event.target.value;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    this.setState({ newPasswordValue: newPassword }, () => {
      if (newPassword === "") {
        this.setState({ showNewPasswordValidation: true, newPasswordErrorTxt: "New Password is Required." });
      } else if (this.state.currentPasswordValue === newPassword) {
        this.setState({
          showNewPasswordValidation: true,
          newPasswordErrorTxt: "New password cannot be the same as the current password."
        });
      } else if (!passwordRegex.test(newPassword)) {
        this.setState({
          showNewPasswordValidation: true,
          newPasswordErrorTxt: "Password must be at least 8-15 characters long and include at least one uppercase letter, one lowercase letter, and one digit."
        });
      } else {
        this.setState({ showNewPasswordValidation: false, newPasswordErrorTxt: "" });
      }
    });
  };

  onChangeConfirmPassword = (event: { target: { value: string } }) => {
    const confirmPasswordValue = event.target.value;
    this.setState({ confirmPasswordValue }, this.handleConfirmPasswordValidation);
  };

  // onChangeConfirmPassword = (event: { target: { value: string } }) => {
  //   this.setState({ confirmPasswordValue: event.target.value }, () => {

  //     if (this.state.confirmPasswordValue === "") {
  //       this.setState({ showNewConfirmPasswordValidation: true, confirmPasswordErrorTxt: "Confirm Password is Required." });
  //     }
  //     else if (this.state.newPasswordValue !== this.state.confirmPasswordValue) {
  //       this.setState({ showNewConfirmPasswordValidation: true, confirmPasswordErrorTxt: "Confirm Password doesn't match." });
  //     }
  //     else if(this.state.captchaValue !== this.state.confirmPasswordValue){
  //       this.setState({ showNewConfirmPasswordValidation: true, captchaErrorTxt: "Confirm Password doesn't match" });
  //     } 
  //     else {
  //       this.setState({ showNewConfirmPasswordValidation: false, confirmPasswordErrorTxt: "" });
  //     }
  //   });
  // };

  onChangeCaptcha = (event: { target: { value: string } }) => {
    const enteredCaptcha = event.target.value;
    if (enteredCaptcha === "") {
      this.setState({ showCaptchaValidation: true, captchaErrorTxt: "Captcha is Required" });
    } else if (enteredCaptcha !== this.state.generatedPasswordCaptcha) {
      this.setState({ showCaptchaValidation: true, captchaErrorTxt: "Incorrect Captcha", captchaValue: enteredCaptcha });
    } else {
      this.setState({ showCaptchaValidation: false, captchaErrorTxt: "", captchaValue: enteredCaptcha });
    }
  };
  

  generateCaptcha(length: number) {
    let results = '';
    const character = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characterLength = character.length;
    let counter = 0;
    while (counter < length) {
      results += character.charAt(Math.floor(Math.random() * characterLength));
      counter += 1;
    }
    return results;
  }

  refreshedCaptcha = () => {
    this.setState({ generatedPasswordCaptcha: this.generateCaptcha(5) })
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if ((this.isValidResponse(responseJson))) {
        this.responseSucessCall(apiRequestCallId, responseJson);
      }
      else if (responseJson && responseJson.error_message) {
        this.responseFailureCall(apiRequestCallId, responseJson);
      }
    }
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.message;
  };

  responseSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.changePswrdAPICallId) {
      this.changePswrdSucessCallBack(responseJson);
    }
  };

  responseFailureCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.changePswrdAPICallId) {
      this.changePswrdFailureCallBack(responseJson);
    }
  };

  changePswrdSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      apiResponseValue: 'pass',
      isDashModalOpen: true,
      generatedPasswordCaptcha: this.generateCaptcha(5)
    }, () => this.onResetClick())
  };

  changePswrdFailureCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      errorAlertMsgChangePswrd: responseJson.error_message,
      errorAlertChangePswrd: true,
      generatedPasswordCaptcha: this.generateCaptcha(5)
    });
  };

  onCloseAlertChangePswrd = () => {
    this.setState({ errorAlertChangePswrd: false });
  };

  changePswrd = async () => {
    const body = {
      password: this.state.currentPasswordValue,
      new_password: this.state.newPasswordValue,
      password_confirmation: this.state.confirmPasswordValue
    }
    let Token = await StorageProvider.get("TOKEN");
    const header = {
      "Content-Type": configJSON.changePswrdContentType,
      token: Token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePswrdAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePswrdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.changePswrdMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fontSizeHandler = (size: string): void => {
    const root: HTMLElement | null = document.documentElement;
    if (root) {
      root.style.setProperty("--base-font-size", size);
    }
    this.setState({
      currentFontSize: size
    });
  };

  onResetClick = () => {
    this.setState({
      currentPasswordValue: "",
      newPasswordValue: "",
      confirmPasswordValue: "",
      captchaValue: "",
      newPasswordErrorTxt: "",
      currentPasswordErrorTxt: "",
      confirmPasswordErrorTxt: "",
      captchaErrorTxt: "",
      showCaptchaValidation: false,
      showCurrentPasswordValidation: false,
      showNewPasswordValidation: false,
      showNewConfirmPasswordValidation: false
    })
  }
  // Customizable Area End
}
