import React from "react";
// Customizable Area Start
import { Box, Button, Grid, styled, } from "@material-ui/core";
import PrivacyPolicyController, { Props, configJSON } from "./PrivacyPolicyController";
import Header from "../../../components/src/Header.web";
import Banner from "../../../components/src/Banner.web";
import * as IMG_CONST from "./assets";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                <Header
                    data-test-id="headerData"
                    navigation={undefined}
                    id={""}
                    navData={this.state.navData}
                    fontSizeHandler={this.fontSizeHandler}
                    currentFontSize={this.state.currentFontSize}
                    onLogoutClick={undefined}
                    isLogin={false}
                    />
                <Box className="container">
                    <MainBox>
                        <Banner
                            title="welcome"
                            activeLink="home"
                            navigation={undefined}
                            id={""}
                            activePage="Privacy Policy"
                        />
                        <Box
                            style={styles.innerBoxPrivacyPolicy}>
                            <Box component={"div"} style={styles.boxStyleTwoPrivacyPolicy}>
                                <Box
                                    dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyData?.data?.attributes?.description }}
                                    data-test-id="descriptionTestId"
                                />
                                <ButtonGrid container spacing={2}>
                                    <Grid item >
                                        <ButtonStyle
                                            variant="outlined"
                                            color="primary"
                                            startIcon=
                                            {
                                                <img src={IMG_CONST.mobileImg} />
                                            }
                                        >
                                            {configJSON.mobileTxt}
                                        </ButtonStyle>
                                    </Grid>
                                    <Grid item >
                                        <ButtonStyle
                                            variant="outlined"
                                            color="primary"
                                            startIcon=
                                            {
                                                <img src={IMG_CONST.employeesImg} />
                                            }
                                        >
                                            {configJSON.employeeTxt}
                                        </ButtonStyle>
                                    </Grid>
                                    <Grid item >
                                        <ButtonStyle
                                            variant="outlined"
                                            color="primary"
                                            startIcon=
                                            {
                                                <img src={IMG_CONST.publicImg} />
                                            }
                                        >
                                            {configJSON.publicTxt}
                                        </ButtonStyle>
                                    </Grid>
                                    <Grid item >
                                        <ButtonStyle
                                            variant="outlined"
                                            color="primary"
                                            startIcon=
                                            {
                                                <img src={IMG_CONST.galleryImg} />
                                            }
                                        >
                                            {configJSON.galleryTxt}
                                        </ButtonStyle>
                                    </Grid>
                                    <Grid item >
                                        <ButtonStyle
                                            variant="outlined"
                                            color="primary"
                                            startIcon=
                                            {
                                                <img src={IMG_CONST.fileImg} />
                                            }
                                        >
                                            {configJSON.rtiTxt}
                                        </ButtonStyle>
                                    </Grid>
                                </ButtonGrid>
                                <ButtonGrid container spacing={5}>
                                    <Grid item >
                                        <img
                                            src={IMG_CONST.indiaPortalImg}
                                            alt="indiaPortal"
                                        />
                                    </Grid>
                                    <Grid item >
                                        <img
                                            src={IMG_CONST.nvspImg}
                                            alt="nvspImg"
                                        />
                                    </Grid>
                                    <Grid item >
                                        <img
                                            src={IMG_CONST.dippImg}
                                            alt="dippImg"
                                        />
                                    </Grid>
                                    <Grid item >
                                        <img
                                            src={IMG_CONST.myGovImg}
                                            alt="indiaPortal"
                                        />
                                    </Grid>
                                </ButtonGrid>
                            </Box>
                        </Box>
                        <Footer
                            navigation={undefined}
                            id={""}
                            footerNavData={this.state.footerNavData}
                        />
                    </MainBox>
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    innerBoxPrivacyPolicy: {
        padding: "10px",
        borderRadius: "10px",
        background: "linear-gradient(-136deg, #002E6A -7.18%, #708990 104.44%)",
        marginTop: "20px"
    },
    boxStyleTwoPrivacyPolicy: {
        padding: "20px",
        background: "white"
    }
}

const ButtonGrid = styled(Grid)
    ({
        marginTop: "30px",
        display: "flex",
        justifyContent: "center"
    });

const ButtonStyle = styled(Button)
    ({
        display: "flex",
        width: "220px",
        padding: "16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "16px",
        color: "#275080",
        textTransform: "capitalize" as "capitalize",
        borderRadius: 8
    });

const MainBox = styled(Box)
    ({
        paddingTop: "2rem"
    });
// Customizable Area End
