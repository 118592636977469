import React from "react";
// Customizable Area Start
import EmailLoginController, { configJSON } from "./EmailLoginController.web";
import { Form, Input } from "antd";
import * as IMG_CONST from "./assets";
import { Link } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import { TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";
// Customizable Area End

class EmailLogin extends EmailLoginController {

    renderForm = () => {
        // Customizable Area Start
        return (
            <div>
                <div className="login-form">
                    <h2 className="login-form-title">{configJSON.eFilling}</h2>
                    <h3 className="login-form-name">{configJSON.login}</h3>
                    <Form data-testid="loginApi" layout="vertical" onFinish={this.loginApi}
                        data-test-id="loginSubmitBtn">
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: "Username is required." }]}
                        >
                            <Input
                                onChange={this.onUsernameChange}
                                data-test-id="usernameChangeTestId"
                                value={this.state.username} />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: "Password is required." }]}
                        >
                            <Input
                                onChange={this.onPasswordChange}
                                data-test-id="passwordChangeTestId"
                                value={this.state.password}
                                type="password"
                            />
                        </Form.Item>
                        <div className="forgot-container">
                            <a href="/forgot-password" className="forgot-password-text">
                                {configJSON.forgotPswrd}
                            </a>
                        </div>

                        <div className="captcha-container">
                            <div className="captcha-top-email">
                                <span className="captcha-title">{configJSON.captcha}</span>
                                <img className="speaker-img" src={IMG_CONST.speakerImg} alt="speaker" />
                            </div>
                            <div className="captcha-bottom">
                                <TextField
                                    name="captcha"
                                    data-testid="captchaChange"
                                    variant="outlined"
                                    className="captcha-input"
                                    autoComplete="off"
                                    // helperText={this.state.isCaptchaValidMessage}
                                    helperText={
                                        <span style={{ marginLeft:"-10px" }}>
                                            {this.state.isCaptchaValidMessage}
                                        </span>
                                    }
                                    error={this.state.isCaptchaValidCheck}
                                    onChange={this.handleCaptchaChange} value={this.state.captchaValue} />
                                <div className="captcha-img-container">
                                    <span className="captcha-img">{this.state.generatedCaptcha}</span>
                                    <div className="captcha-refresh-img-container" onClick={this.refreshCaptcha} >
                                        <img className="captcha-refresh-img" src={IMG_CONST.captchaRefreshImg} alt="captcha refresh" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="login-form-btn" type="submit">{configJSON.login}</button>
                    </Form>
                    <Link className="sign-up-btn" to="/signUp">{configJSON.newUser}</Link>
                </div>
            </div>
        )

        // Customizable Area End

    }
    render() {
        // Customizable Area Start
        return (
            <div>
                <Snackbar open={this.state.errorAlert}
                    autoHideDuration={3000}
                    data-testid="snackBar"
                    anchorOrigin={{ horizontal: 'right', vertical: "top" }}
                    onClose={this.oncloseAlert}
                >
                    <Alert severity="error">{this.state.errorAlertMsg}</Alert>
                </Snackbar>
                {this.renderForm()}
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default EmailLogin;
// Customizable Area End
