import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { debounce } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider";
import moment from "moment";


export interface NewApplicationsData {
  value: string;
}

export interface ObjectData {
  [x: string]: any;
  value: string;
}

export interface SubFormsList {
  id: number;
  formSelect: string;
}
export interface DrafetedButtonObject {
  label: string;
  onClick: () => void;
  testId: string;
  preview?:boolean;
  disabled?:boolean;
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  classes:{ [key: string]: string },
  selectedForm?:string,
  data?:any,
  handleCheckboxChange?:any,
  previewData?:any,
  searchForm?:any,
  onPagination:any,
  handleDiscard:any,
  handleEdit:any,
  handleCheck:boolean;
  handleCheckboxAll:any;
  checkAll:boolean;
  totalPages:number;
  activePage:number;
  showModel:boolean;
  closeModel:any;
  successMsg:string;
  discardCheck: boolean;
  selectedButton: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  selectedOptionValue:string;
  totalCandidates: string;
  type: string;
  token: string;
  selectValue:string
  errorMsg: string;
  loading: boolean;
  discardedData:{  
    checked: boolean | undefined;
    id:string,
    type:string,
    attributes: {
      id:number
        status: string,
        created_at: string,
        reference_number:string
        form_detail: {
            name: string,
            price: number
        }, } }[],
  loginToken:string,
  checkBox:boolean,
  previewData:{ [key: string]: string | boolean | undefined | number | {} }[]
  currentFontSize: string,
  dashboardnavData:{ [key: string]: string } [],
  footerdashboardnavData:{ [key: string]: string } [],
  modalOpen:boolean,
  urlParam:string,
  newApplicationSelect: string;
  newApplications:{ [key: string]: string | {}} [],
  expanded: string | boolean;
  newApplication:boolean
  discardState:boolean;
  patentState:boolean;
  userState:boolean;
  searchFormRadio: string;
  queryParam:string | null;
  searchState: boolean;
  searchResultTable: boolean;
  searchPatentModel: boolean;
  applicantName:string;
  applicantNameSet:boolean;
  applicantNumber: string;
  applicantCity: string;
  searchCreatedDate: any;
  searchResultsArr: any[];
  searchValue: string | null;
  currentPage:number
  changePassword:boolean;
  searchResultPerPage: any;
  totaltPageSearchPatent: number;
  currentPageSearchPatent: number;
  pageLimit:number,
  selectedButton:string,
  previewCheck:boolean,
  checkAll:boolean,
  totalPages:number,
  formId:string,
  openSavedModal:boolean;
  successDataMsg:string;
  isPreviewDisabled: boolean;
  isDiscardDisabled: boolean;
  isEditDisabled: boolean;
  discardCheck: boolean;
  upDownArrow: boolean;
  checkedCheckboxes: { [key: number]: boolean };
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apigetDiscardformlistCallId:string="";
  getdiscardformApiCallId:string=""
  apiSearchByPatentCall:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      isPreviewDisabled: true,
      isDiscardDisabled: true,
      isEditDisabled: true,
      type: "",
      selectedOptionValue:"Form-1",
      urlParam:'',
      dashboardData: [],
      discardState:false,
      newApplication:false,
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      loginToken:"",
      checkBox:false,
      previewData:[],
      discardedData:[],
      currentFontSize: "16px",
      selectValue:"",
      searchFormRadio: 'Patent Name',
      searchState: false,
      searchValue: '',
      searchResultPerPage: 5,
      currentPageSearchPatent: 1,
      totaltPageSearchPatent: 0,
      searchResultTable: false,
      searchPatentModel: false,
      applicantName: '',
      applicantNameSet:false,
      applicantNumber: '',
      applicantCity: '',
      searchCreatedDate: '',
      searchResultsArr: [],
      modalOpen:false,
      patentState:true,
      currentPage:1,
      pageLimit:5,
      selectedButton:'',
      previewCheck:true,
      checkAll:false,
      userState:false,
      discardCheck: true,
      upDownArrow: false,
      checkedCheckboxes: {},
      dashboardnavData: [
  {
    link: "Home",
    path: "/"
  },
  {
    link: "FAQs",
    path: "/faqs"
  },
  {
    link: "Feedback",
    path: "/feedback"
  },
  {
    link: "Sign Up",
    path: "/signUp"
  }
],
footerdashboardnavData: [
  {
    name: "Terms and Conditions",
    link: "/terms-conditions"
  },
  {
    name: "Privacy Policy",
    link: "/privacy-policy"
  },
  {
    name: "Copyright",
    link: ""
  },
  {
    name: "Hyperlinking Policy",
    link: ""
  },
  {
    name: "Accessibility",
    link: ""
  },
  {
    name: "Archive",
    link: ""
  },
  {
    name: "Contact Us",
    link: "/contact-us"
  },
  {
    name: "Sitemap",
    link: ""
  }
],

      newApplications: [
        {
          value: "My Applications",
          deferentForms: [
            {
              id: 1,
              formSelect: "My Applications",
            },
           
          ],

        },
        {
          value: "Quick form filling",
          deferentForms: [
            {
              id: 1,
              formSelect: "Reply for Patent Prosecution Highway (PPH)  ",
            },
            {
              id: 2,
              formSelect: "Search For Existing Patents",
            },
            {
              id: 3,
              formSelect: "PCT National Phase Application",
            },
            {
              id: 4,
              formSelect: "New Application",
            },
            {
              id: 5,
              formSelect: "File Form",
            },
            {
              id: 6,
              formSelect: "File Form",
            },
            {
              id: 7,
              formSelect: "Form 30 ( New )",
            },
            {
              id: 8,
              formSelect: "Renewal of Patent",
            },
            {
              id: 9,
              formSelect: "Reply to Examination Report",
            },
            {
              id: 10,
              formSelect: "Petition under rule 6(6)",
            },
          ],
        },
        {
          value: "Fifth Schedule",
          deferentForms: [
            {
              id: 1,
              formSelect: "Fifth Schedule",
            },
           
          ],
        },
        {
          value: "Form History",
          deferentForms: [
            {
              id: 1,
              formSelect: "Drafted Forms",
            },
            {
              id: 2,
              formSelect: "Discarded Forms",
            },
            {
              id: 3,
              formSelect: "Submitted Forms",
            },
            {
              id: 4,
              formSelect: "Completed Forms",
            },
          ],
        },
        {
          value: "Payments/Submission",
          deferentForms: [
            {
              id: 1,
              formSelect: "Payments/Submission",
            },
            {
              id: 2,
              formSelect: "Control Panel",
            },

            
          ],
        },
        {
          value: "Pending CBR",
          deferentForms: [
            {
              id: 1,
              formSelect: "Pending CBR",
            },
           
          ],
        },
        {
          value: "Control Panel",
          deferentForms: [
            {
              id: 1,
              formSelect: "Change Password",
            },
            {
              id: 2,
              formSelect: "Payments/Submission",
            },
          ],
        },
        {
          value: "User Panel",
          deferentForms: [
            {
              id: 1,
              formSelect: "User Profile",
            },
          
          ],
        },
        {
          value: "Downloads",
          deferentForms: [
            {
              id: 1,
              formSelect: "Downloads",
            },
            {
              id: 2,
              formSelect: "Fifth Schedule",
            },
            {
              id: 3,
              formSelect: "Pending CBR",
            },
           
            
          ],
        },
      ],
      newApplicationSelect: "",
      expanded: false,
      queryParam:null,
      changePassword:false,
      totalPages:0,
      formId:'',
      openSavedModal:false,
      successDataMsg:''
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
     // Customizable Area Start
    const getToken = await StorageProvider.get("TOKEN")
    if (!getToken) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEmailRegistrationFormMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
    this.setState({
      token: getToken
  },()=>  this.getDiscardformlistApi())
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
     
      if (webResponseJson && !webResponseJson.errors) {
        this.setState({
          dashboardData:webResponseJson
        })
      } else if (webResponseJson && webResponseJson.errors) {
        this.setState({
          dashboardData:webErrorReponse
        })
      }
      if(webApiRequestCallId === this.getdiscardformApiCallId){
        this.handleDiscardform(webResponseJson,webErrorReponse)
      }
     
      if(webApiRequestCallId === this.apigetDiscardformlistCallId){
      this.handleResponse(webResponseJson)

      }
      this.getSearchDataResponseApiCall(webApiRequestCallId, webResponseJson)
    }
    // Customizable Area End
  }
  // Customizable Area Start
  urlManagement=()=>{
    if(this.state.queryParam) {
      return webConfigJSON.discardformGetUrl(this.state.urlParam,this.state.currentPage,this.state.pageLimit)+`&query=${this.state.queryParam}`
    }else{
      return webConfigJSON.discardformGetUrl(this.state.urlParam,this.state.currentPage,this.state.pageLimit)
    } 
  }
handleResponse=(webResponseJson: {total_count:number, results: { data: { map: (arg0: (item: {}) => { checked: boolean; }) => { checked: boolean | undefined; id: string; type: string; attributes: { id: number; status: string; created_at: string; reference_number: string; form_detail: { name: string; price: number; }; }; }[]; }; }; })=>{
  if (webResponseJson) {
    this.setState({
      discardedData:webResponseJson?.results?.data?.map((item:{}) => ({ ...item, checked: false })) as S['discardedData'],
      totalPages:webResponseJson.total_count
    })
  }
}
  getSearchDataResponseApiCall = (webApiRequestCallId:any, webResponseJson:any)=>{
    if(webApiRequestCallId === this.apiSearchByPatentCall){
      if (webResponseJson?.error || webResponseJson?.errors) {
        this.setState({searchResultTable: true, searchState: false, searchResultsArr: [], searchResultPerPage: 0})
      }else if(webResponseJson?.message  === "No records found"){
        this.setState({searchResultsArr: [], searchResultTable: true, searchState: false, searchResultPerPage: 0})
      }else{
        this.setState({searchResultsArr: webResponseJson?.results?.data, searchResultTable: true, searchState: false, searchResultPerPage: 10})
      }
      this.searchPatentTableHandle();
    }
  }

  getDiscardformlistApi = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.token
    };
    this.apigetDiscardformlistCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.urlManagement()
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchPatentTableHandle = ()=>{
    if(this.state.searchResultsArr?.length>0 && this.state.searchResultsArr?.length%this.state.searchResultPerPage==0){
      this.setState({
        totaltPageSearchPatent: this.state.searchResultsArr?.length/this.state.searchResultPerPage
      })
    }
    else{
      this.setState({
        totaltPageSearchPatent: Math.floor(this.state.searchResultsArr?.length/this.state.searchResultPerPage)+1
      })
    }
  }
  getSearchByPatentNameApi = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.token
    };
    this.apiSearchByPatentCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.searchExistingPatentUrl}/search_by_name?patent_name=${this.state.searchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.searchPatentMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSearchByPatentNumberApi = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token": this.state.token
    };
    this.apiSearchByPatentCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${webConfigJSON.searchExistingPatentUrl}/search_by_number?reference_number=${this.state.searchValue}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.searchPatentMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleKeyDownSearchPatent = (event:any)=>{
    if (["Enter"].includes(event.key)) {
      event.preventDefault();
      if(this.state.searchFormRadio === 'Patent Name'){
        this.getSearchByPatentNameApi()
      }else{
        this.getSearchByPatentNumberApi()
      }
      this.searchPatentTableHandle()
    }
  }

  handleAllSearchByPatent = (event?:any)=>{
    this.setState({searchValue: ''})
    if(this.state.searchFormRadio === 'Patent Name'){
      this.getSearchByPatentNameApi()
    }else{
      this.getSearchByPatentNumberApi()
    }
    this.searchPatentTableHandle()
  }
  handleBackToSearchPatent=()=>{
    this.setState({
      searchState: true,
      searchResultTable: false,
      searchResultsArr: [],
      searchValue: '',
    })
  }


  getInventerName=(formSectionData:any)=>{
    let applicantNameVal = ''
    formSectionData?.data?.map((section:any)=>{
      if (section?.attributes?.name === 'Applicant-1' && section?.type === 'form_section') {
         section?.attributes?.form_fields?.data?.find((val: any) => {
          if (val?.attributes?.field_name === 'Applicant Name') {
            applicantNameVal = val?.attributes?.field_value          
          }
        });
      }
    })
    return applicantNameVal
  }

  renderPatentName=(formSectionData:any)=>{
    let patentName='';
    formSectionData?.data?.map((section:any)=>{
      if(section?.type === 'form_section'){
        section?.attributes?.form_fields?.data?.find((val: any) => {
          if (val?.attributes?.field_name === 'Abstract') {
            patentName = val?.attributes?.field_value          
          }
        });
      }
    })
    return patentName;
  } 
  
  handleOpenSearchViewModel= (data:any, event:any)=>{
    let applicantNameVal = ''
    let applicantNoVal = ''
    let applicantAddressVal = ''
    data?.attributes?.form_sections.data?.map((section:any)=>{
      if (section?.attributes?.name === 'Applicant-1' && section?.type === 'form_section') {
        section?.attributes?.form_fields?.data?.find((val: any) => {
         if (val?.attributes?.field_name === 'Applicant Name') {
           applicantNameVal = val?.attributes?.field_value          
         }else if (val?.attributes?.field_name === 'Application No.') {
          applicantNoVal = val?.attributes?.field_value          
        }else if (val?.attributes?.field_name === 'Address') {
          applicantAddressVal = val?.attributes?.field_value          
        }
       });      
     }
   })
    
    this.setState({
      searchCreatedDate:moment(data?.attributes?.created_at).format('MMM DD, YYYY'),
      applicantNumber:applicantNoVal,
      applicantCity:applicantAddressVal,
      applicantName:applicantNameVal,
      searchResultTable: true,
      searchState: false,
      searchPatentModel: true,     
    })
  }
  handleCloseModalPatent = ()=>{
    this.setState({searchPatentModel: false})
  }

  sizehandler = (size: string) => {
    this.setState({
      currentFontSize: size
    });
  };
 
    handlePreview=()=>{
      this.setState({
        modalOpen:true,
        selectedButton:'previewButtonTestId'
      })
  }

  handleOptionValue=(event:any)=>{

    this.setState({ selectedOptionValue: event.target.value });

  }
  handleClose=()=>{
    this.setState({
      modalOpen:false,
      selectedButton: ''
    })
  }
  handleDiscardform=(webResponseJson: { data:{message: string; }},webErrorReponse: { errors: string; })=>{
    if(webResponseJson.data.message){
      this.setState({successDataMsg:webResponseJson.data.message, openSavedModal:true, selectedButton: ''})
      this.formTypeSelect("Discarded Forms")
    }
  }
  handleEdit=(checkedCheckboxes: { checked: boolean | undefined; id: string; type: string; attributes: { id: number; status: string; created_at: string; reference_number: string; form_detail: { name: string; price: number; }; }; }[])=>{
    this.setState({selectedButton:'editButtonTestId',newApplicationSelect:'New Application'})
    this.handleForms('New Application')
  }

  toggleDropDown = () => {
    this.setState(prevState => ({
      upDownArrow: !prevState.upDownArrow 
    }));
  };

  handleCheckboxChange = async(checkboxId: number, data: { checked: boolean | undefined; id: string; type: string; attributes: { id: number; status: string; created_at: string; reference_number: string; form_detail: { name: string; price: number; }; }; }) => {
    const updatedCheckboxes = this.state.discardedData?.map(checkbox =>
      checkbox.attributes.id === checkboxId ? { ...checkbox, checked: !checkbox.checked } : checkbox
    );
  
    const isAnyCheckboxChecked = updatedCheckboxes?.filter(checkbox => checkbox.checked); 
    this.setState({
      discardedData: updatedCheckboxes,
      checkAll: isAnyCheckboxChecked.length === this.state.discardedData?.length, 
      previewCheck: isAnyCheckboxChecked.length === 1, 
      discardCheck: isAnyCheckboxChecked.length === 0 
    });

    if (isAnyCheckboxChecked.length >1 || isAnyCheckboxChecked.length <1) {
      this.setState ({
        previewData: [data],
        discardedData: updatedCheckboxes,
        previewCheck:true,
        checkAll:false,
      });
      await StorageProvider.remove('formid');
    } else {
      this.setState({
        discardedData: updatedCheckboxes,
        previewCheck:false,
        checkAll:false,
      });
      await StorageProvider.set('formid', data.id);
    }
  } 

  handleCheckboxAll=(event:React.ChangeEvent<HTMLInputElement>)=>{
    const {checked} = event.target 
    const updatedCheckboxes = this.state.discardedData?.map(checkbox =>
      checked ? { ...checkbox, checked: true } : { ...checkbox, checked: false }
    );

    this.setState({
      discardedData: updatedCheckboxes,
      checkAll: checked,
      previewCheck: false, 
      discardCheck: !checked 
    });
    
    const isAnyCheckboxChecked = updatedCheckboxes?.filter(checkbox => checkbox.checked); 

    this.setState({
      discardCheck: (isAnyCheckboxChecked.length < 1),
      previewCheck: isAnyCheckboxChecked.length > 1 || isAnyCheckboxChecked.length == 0
     })

    if (isAnyCheckboxChecked.length >0 || isAnyCheckboxChecked.length <1) {
      this.setState ({
        discardedData: updatedCheckboxes,
        // previewCheck:false,
        checkAll: checked,
      });
    } else {
      this.setState({
        discardedData: updatedCheckboxes,
        // previewCheck:true,
        checkAll: checked
      });
    }
  }

  handleDiscardbuttonClick = () => {
    const { discardedData } = this.state;
  
    const checkedCheckboxes = discardedData?.filter(checkbox => checkbox.checked);
  
    if (checkedCheckboxes && checkedCheckboxes.length > 0) {
      this.handleDiscard(checkedCheckboxes);
    } 
  }

  handleEditbuttonClick = () => {
    const { discardedData } = this.state;
  
    const checkedCheckboxes = discardedData?.filter(checkbox => checkbox.checked);
  
    if (checkedCheckboxes && checkedCheckboxes.length > 0) {
      this.handleEdit(checkedCheckboxes);
    } 
  }
  
  handleSearchFormRadioChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchFormRadio: e.target.value})
  }

  handleSearchValueChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchValue: e.target.value})
  }

  handleChangePageSearchPatent = (event: React.ChangeEvent<unknown>, value: number) =>{
    this.setState({
      currentPageSearchPatent: value
    })
  }

  handleSearchResultPerPage = (e:any)=>{
    this.setState({searchResultPerPage: e.target.value})
    if(this.state.searchResultsArr?.length>0 && this.state.searchResultsArr?.length%Number(e.target.value)==0){
      this.setState({
        totaltPageSearchPatent: this.state.searchResultsArr?.length/Number(e.target.value),
        currentPageSearchPatent: 1,
      })
    }
    else{
      this.setState({
        totaltPageSearchPatent: Math.floor(this.state.searchResultsArr?.length/Number(e.target.value))+1,
        currentPageSearchPatent: 1
      })
    }
  }

  handleChange = (panel: string) => {
    this.setState({ expanded: panel });
  }

  searchForm=(event:any)=>{
    this.setState({queryParam:event.target.value})
    this.debounceFxn()
  }

  debounceFxn=debounce(async ()=>{
    this.getDiscardformlistApi()
  },500)

  formTypeSelect = async( selectForm: string ) => {
    await StorageProvider.remove('formid')
    this.setState({ newApplicationSelect: selectForm, previewCheck: true,
      discardCheck: true, selectedButton: '' });
    this.handleForms(selectForm)
  }

  onSubmit = async()=>{
    await StorageProvider.remove('formid')
    this.setState({patentState:false})
    this.setState({newApplication:true})
  }
  
  handleForms=(selectForm: string)=>{
    switch (selectForm) {
      case "Discarded Forms":
        this.setState({
          discardState:true,
          newApplication:false,
          urlParam:'discarded',
          patentState:false,
          searchState: false,
          searchResultTable:false,
          userState:false,
          changePassword: false,
          previewCheck: true
        })
        break;
        case "Submitted Forms":
          this.setState({
            discardState:true,
            newApplication:false,
            searchState: false,
            searchResultTable:false,
            patentState:false,
            urlParam:'submitted',
            userState:false,
            changePassword: false,
          })
          break; 
          case "Drafted Forms":
            this.setState({
              discardState:true,
              newApplication:false,
              searchState: false,
              patentState:false,
              searchResultTable:false,
              urlParam:'drafted',
              userState:false,
              changePassword: false,
            })
            break; 
            case "New Application":
           this.setState({
          discardState: false,
          patentState:false,
          searchState: false,
          newApplication:true,
          userState:false,
          changePassword: false,
          searchResultTable:false,
           })
          break;
          case "Completed Forms":
          this.setState({
            discardState:true,
            newApplication:false,
            patentState:false,
            searchState: false,
            urlParam:'completed',
            searchResultTable:false,
            userState:false,
            changePassword: false,
          })
          break;
      case "Search For Existing Patents":
        this.setState({
          searchState: true,
          discardState: false,
          newApplication: false,
          patentState: false,
          searchValue: '',
          userState:false,
          searchResultTable:true,
          changePassword: false,

        })
        break;
      case "Change Password":
        this.setState({
          changePassword: true,
          discardState: false,
          newApplication: false,
          searchState: false,
          patentState: false,
          searchResultTable:false,
          userState:false
        })
        break;

      case "User Profile":
        this.setState({
          changePassword: false,
          discardState: false,
          newApplication: false,
          patentState: false,
          userState:true,
          searchResultTable:false,
          searchState: false,
        })
        break;

      default:
        this.setState({
          searchState: false,
          discardState: false,
          newApplication: false,
          patentState: false,
          userState: false,
          changePassword: false,
          searchResultTable:false
        })
        break;
    }
    
    
  }
 
  handleCloseModel=()=>{
    this.setState({openSavedModal:false, selectedButton: ''})
  }

  onLogoutClick = async () => {
   await StorageProvider.remove("TOKEN");
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailRegistrationFormMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.urlParam !== this.state.urlParam || prevState.currentPage !== this.state.currentPage) {
      this.setState({ queryParam: null }, () => this.getDiscardformlistApi());

        const updatedCheckboxes = this.state.discardedData?.map((item) => {
          return { ...item, checked: false };
        });
      
        this.setState({
          discardedData: updatedCheckboxes,
          checkAll: false
        });

    }
  }
  handlePagination=(event: React.ChangeEvent<HTMLInputElement>,value: number)=>{
    this.setState({
      currentPage: value
    }, async () => {
      await this.getDiscardformlistApi();
    });
    }
    
  handleDiscard=(discardedData:any)=>{
    const objectIds = discardedData.map((discardedObject: { id: string; }) => parseInt(discardedObject.id));
    const form_creation_id=objectIds
    
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      "token": this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getdiscardformApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.discardFormupdateUrl + `${form_creation_id[0]}/${"form_submissions"}`+webConfigJSON.discardEndFormupdateUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarPutApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({"ids":form_creation_id})
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePages=()=>{
   let totalPages =  this.state.totalPages/this.state.pageLimit
   return Math.ceil(totalPages)
  }
  // Customizable Area End
}
