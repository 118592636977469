import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, Select, TextField, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, MenuItem, Button, Dialog,Modal,Fade } from "@material-ui/core";
import { closeimg, downarrow, headerLeft, headerRight, search, rupeeIcon, success, } from "./assets";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffcc00",
      contrastText: "#fff",
    },
  },
});
import { DrafetedButtonObject } from "./DashboardController.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class DiscardedForm extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  downarrowfn = (classes: any) => {
    return <img
      src={downarrow} className={classes.select} />
  }

  renderSavedSubmitFormModal = () => {
    const { classes } = this.props;
    return (
      <Modal
      aria-describedby="transition-modal-description"
      className={classes.modal}
      aria-labelledby="transition-modal-title"
      closeAfterTransition
      open={this.props.showModel}
      data-testid="openModalTestId"
        BackdropProps={{
          timeout: 500
        }}>
        <Fade in={this.props.showModel}>
          <Box className={classes.savedModalContainer}>
            <img src={success} />
            <Typography className={classes.savedModalHeading}>
              {this.props.successMsg}
            {/* <Typography>{webConfigJSON.successFormText}</Typography> */}
            </Typography>
            <Button
              data-testid="closeModalTestId"
              onClick={()=>this.props.closeModel()}
              className={classes.savedModalButton}>{webConfigJSON.doneButtonText}</Button>
          </Box>
        </Fade>
      </Modal>
    )
  }

  checkBoxClasses(check: boolean) {
    const { classes } = this.props;
    return check ? classes.checkBoxActive : classes.checkBox;
  }

  renderStatusTableCell() {
    const { selectedForm, classes } = this.props;
    return selectedForm === "Discarded Forms" ? 
      <TableCell className={classes.tableHeadingText}>{webConfigJSON.statusHeading}</TableCell> : null;
  }

  getCheckBoxRows(isChecked: boolean){
    const { classes } = this.props;
    return isChecked ? classes.checkBoxActive : classes.checkBox;
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const DraftedButton:DrafetedButtonObject[] = [
      {
        onClick: this.handlePreview,
        label: webConfigJSON.Preview,
        testId: 'previewButtonTestId',
        preview:this.props.handleCheck,
        disabled:this.state.isPreviewDisabled
       
      },
      {
        onClick: this.props.handleDiscard,
        label: webConfigJSON.Discard,
        testId: 'discardButtonTestId',
        preview:this.props.discardCheck,
        disabled:this.state.isDiscardDisabled
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.Edit,
        testId: 'editButtonTestId',
        preview:this.props.handleCheck,
        disabled:this.state.isEditDisabled
      },
    ];

    const submitButtonObj: DrafetedButtonObject[] = [
      {
        onClick: this.handlePreview,
        label: webConfigJSON.Preview,
        testId:'SubmitPreviewBtnTestID',
        preview:this.props.handleCheck,
     
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.signDocuments,
        testId:'signDocumentsBtnTestId',
        preview:this.props.handleCheck,
     
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.makePayment,
        testId:'makePaymentBtnTestID',
        preview:this.props.handleCheck,
     
      },
    ];

    const completedButtonObj: DrafetedButtonObject[] = [
      {
        onClick: this.handlePreview,
        label: webConfigJSON.Preview,
        testId:'SubmitPreviewBtnTestID',
        preview:this.props.handleCheck,
     
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.renewal,
        testId:'renewalBtnTestId',
        preview:this.props.handleCheck,
     
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.orderCertifiedCopy,
        testId:'orderCertifiedCopyBtnTestID',
        preview:this.props.handleCheck,
     
      },
      {
        onClick: this.props.handleEdit,
        label: webConfigJSON.transferPatent,
        testId:'transferPatentBtnTestID',
        preview:this.props.handleCheck,
     
      },
    ]
    
    
    return (
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Grid container className={classes.mainContainer}>
          <Dialog className={classes.modalOpen} onClose={this.handleClose} open={this.state.modalOpen}>
            <Box >
              <Box className={classes.modalGridstyle} >
                <Typography className={classes.modalTextstyle} >
                  Status :
                  {this.props.selectedForm}
                </Typography>
                <img onClick={this.handleClose} data-testid="perviewModalclose" className={classes.closeImg} src={closeimg} alt="close" />
              </Box>
              <Box className={classes.tableBoxstyle}>
                <Grid container className={classes.tableGridstyle} >
                  <Grid item lg={3} md={3} >
                    <img className={classes.headerLeft} src={headerLeft} alt="" />
                  </Grid>
                  <Grid item lg={6} md={6} className={classes.formHeadingstyle} >
                    <Typography className={classes.formTitlestyle}>{webConfigJSON.titleForm}</Typography>
                  </Grid>
                  <Grid item lg={3} md={3} className={classes.headerRight}>
                    <img src={headerRight} style={{width: "5rem"}} alt="" />
                  </Grid>
                </Grid>
                <Grid container className={classes.tableGridstyle} >
                  <Grid item lg={12} md={12} className={classes.formHeadingstyle} >
                    <Typography className={classes.formsubTitlestyle1}>{webConfigJSON.subTitle}</Typography>
                    <Typography className={classes.formsubTitlestyle}>{webConfigJSON.subText}</Typography>
                  </Grid>
                </Grid>
                <Box className={classes.tableContainstyle} >
                {this.props.previewData?.map((data: any) => {
                  return (
                    <>
                    <table className={classes.tablestyle}>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={10} className={classes.tableColumnstyle}> {webConfigJSON.designInformation}</th>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.designNumber}</th>
                        <td className={classes.tableColumnText}>{data.id}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.designClass}</th>
                        <td className={classes.tableColumnText}>{data.attributes.name}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.proprietorFullname}</th>
                        <td className={classes.tableColumnText}>{data.attributes.form_detail.name}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={10} className={classes.tableColumnstyle}> {webConfigJSON.paymentFaileddetails}</th>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.extensionFee}</th>
                        <td className={classes.tableColumnText}>{data.attributes.form_detail.price}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.date}</th>
                        <td className={classes.tableColumnText}>{moment(data.attributes.created_at).format('DD/MM/YYYY')}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={2} className={classes.tableRowstyle}> {webConfigJSON.reasonforPaymentfail}</th>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th rowSpan={8} className={classes.applicantDetailsstyle}>{webConfigJSON.applicantDetails}</th>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle} style={{width:"16%"}}></td>
                        <td className={classes.tableRowstyle} style={{width:"25%", textAlign:"center"}}>{webConfigJSON.Applicant1}</td>
                        <td className={classes.tableRowstyle} style={{width:"25%", textAlign:"center"}}>{webConfigJSON.Applicant2}</td>
                        <td className={classes.tableColumnstyle} style={{textAlign:"center"}}>{webConfigJSON.Applicant3}</td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}>{webConfigJSON.applicationName}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}> {webConfigJSON.Address}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}>{webConfigJSON.Pincode}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}> {webConfigJSON.townDistrict}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}> {webConfigJSON.stateTerritory}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td className={classes.tableRowstyle}>{webConfigJSON.Country}</td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableRowText}></td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <th colSpan={10} className={classes.tableColumnstyle} style={{borderRight:"none"}}>{webConfigJSON.addressService}</th>
                      </tr>
                      <tr className={classes.tableRowstyle}>
                        <td colSpan={2} className={classes.tableRowstyle}>{webConfigJSON.Address}</td>
                        <td className={classes.tableColumnText}></td>
                      </tr>
                    </table>
                    <table style={{width:"100%"}}>
                    <tr style={{border:"none"}} className={classes.tableRowstyle}>
                          <td style={{width:"10%", borderLeft:"none", borderTop:"none"}} className={classes.tableRowstyle}>{webConfigJSON.Pincode}</td>
                          <td style={{width:"10%", borderTop:"none"}} className={classes.tableRowText}></td>
                          <td style={{width:"14%", borderTop:"none"}} className={classes.tableRowstyle}>{webConfigJSON.townDistrict}</td>
                          <td style={{width:"15%", borderTop:"none"}} className={classes.tableRowText}></td>
                          <td style={{width:"17%", borderTop:"none"}} className={classes.tableRowstyle}>{webConfigJSON.stateTerritory}</td>
                          <td style={{width:"15%", borderTop:"none"}} className={classes.tableRowText}></td>
                          <td style={{width:"10%", borderTop:"none"}} className={classes.tableRowstyle}>{webConfigJSON.Country}</td>
                          <td style={{width:"15%", borderRight:"none", borderTop:"none"}} className={classes.tableRowText}></td>
                      </tr>
                    </table>
                    </>
                  )
                })}
              </Box>
                <Grid container className={classes.tableGridstyle}>
                  <Grid item lg={9} md={9}>
                    <Typography className={classes.conditionText}><Checkbox className={classes.conditionCheckbox} defaultChecked />{webConfigJSON.condition}</Typography>
                    <Typography className={classes.dateText} style={{display:"flex",alignItems:"baseline"}}>{webConfigJSON.date} <span className={classes.dateValue}><Box style={{borderBottom:"2px dashed black", width:"100px"}}></Box></span></Typography>
                    <Box>
                      <Typography className={classes.applicantValue}><Box style={{borderBottom:"2px dashed black", width:"22%"}}></Box></Typography>
                      <Typography className={classes.applicantText}>{webConfigJSON.applicantSignature}</Typography>
                    </Box>

                  </Grid>
                  <Grid item lg={3} className={classes.controler} md={3}>
                    <Typography className={classes.controllerValue}><Box style={{borderBottom:"2px dashed black", width:"97%"}}></Box></Typography>
                    <Typography className={classes.controllerText}>{webConfigJSON.controller}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Dialog>
          <>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.discardedGrid}>
                <Box>
                  <Typography className={classes.title}>{this.props.selectedForm}</Typography>
                </Box>
                <Grid container className={classes.filterGrid}>
                  <Grid item lg={4} xs={12} className={classes.filterGridstyle} sm={12} md={6}>
                    <Box className={classes.filterBox}>
                      <Typography className={classes.filterText}>{webConfigJSON.filterText}</Typography>
                    </Box>
                    <FormControl variant="filled" fullWidth className={classes.filterSelect}>
                      <Select
                        value={this.state.selectValue}
                        data-test-id="showAllDropdown"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        className={classes.filterSelect}
                        onClick={this.toggleDropDown} 
                        IconComponent={ExpandMoreIcon}
                      >
                        <MenuItem value="">{webConfigJSON.showAll}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} sm={12} xs={12} md={2}>
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12} className={classes.searchTextfieldgrid}>
                    <TextField data-testid="searchBtnId" onChange={this.props.searchForm} className={classes.searchField} size="small" id="outlined-basic" placeholder="search" variant="outlined" />
                    <img className={classes.searchTextfieldimg} src={search} alt="search" />
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} sm={12} className={classes.tableGridcontainer}>
                  <TableContainer component={Paper} className={classes.tableGrid}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className={classes.tableRowheadingGrid}>
                          <TableCell className={classes.tableHeadingText}>
                            <Checkbox 
                              className={this.checkBoxClasses(this.props.checkAll)}
                              data-testid="checkbox1"
                              checked={this.props.checkAll}
                              onChange={(event)=>this.props.handleCheckboxAll(event)} 
                            />
                          </TableCell>
                          <TableCell className={classes.tableHeadingText}>{webConfigJSON.application}</TableCell>
                          <TableCell className={classes.tableHeadingText}>{webConfigJSON.patentName}</TableCell>
                          <TableCell className={classes.tableHeadingText}>{webConfigJSON.entrytype}</TableCell>
                          <TableCell className={classes.tableHeadingText}>{webConfigJSON.filingdate}</TableCell>
                          <TableCell className={classes.tableHeadingText}>{webConfigJSON.formAmount}</TableCell>
                          {this.renderStatusTableCell()}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.data?.map((row: any) => (
                          <TableRow key={row.id}>
                            <TableCell className={classes.tableText} >
                              <Checkbox
                                checked={row.checked}
                                data-testid="checkbox"
                                onChange={() => {this.props.handleCheckboxChange(row.attributes.id, row)}}
                                className={this.getCheckBoxRows(row.checked)}
                              />
                            </TableCell>
                            <TableCell className={classes.tableText} >
                              {row.attributes.reference_number === null ? <span>-</span> : row.attributes.reference_number}
                            </TableCell>
                            <TableCell className={classes.tableText} >{row?.attributes?.form_sections?.data?.filter((item : {attributes:{name:string}})=>item.attributes.name === "Priorities")[0]?.attributes.form_fields.data.filter((item:{attributes:{field_name:string}}) => item.attributes.field_name === "Abstract")[0]?.attributes.field_value}</TableCell>
                            <TableCell className={classes.tableText} >{row.type}</TableCell>
                            <TableCell className={classes.tableText}>{moment(row.attributes.created_at).format('DD-MM-YYYY')}</TableCell>
                            <TableCell className={classes.tableText} ><span><img src={rupeeIcon} alt="rupee" /></span>{row.attributes.form_detail.price}</TableCell>
                            {this.props.selectedForm == "Discarded Forms" && <TableCell className={classes.tableTextDiscarded}>{row.attributes.status}</TableCell>}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item lg={12} md={12} sm={12} className={classes.buttongrid}>
                  {this.props.selectedForm === "Discarded Forms" &&
                    <span>
                      <Button disabled={this.props.handleCheck} 
                      className={this.props.selectedButton === 'DiscardBtnTestId' ? classes.previewButton :classes.outlineButton } 
                      data-testid="DiscardBtnTestId" 
                      onClick={()=>{this.handlePreview();this.setState({selectedButton:'DiscardBtnTestId'})}} >{webConfigJSON.Preview}</Button>
                    </span>}
                  {this.props.selectedForm === "Submitted Forms" &&
                       submitButtonObj.map((_item:DrafetedButtonObject)=>
                       <Button
                          className={this.props.selectedButton === _item.testId ? classes.previewButton :classes.outlineButton }
                          data-test-id="prevButton"
                          disabled={_item.preview}
                          onClick={()=>{_item.onClick();this.setState({selectedButton:_item.testId})}}
                          data-testid={_item.testId} 
                         >
                             {_item.label}
                       </Button>
                     )
                    }
                    {this.props.selectedForm === "Completed Forms" &&
                       completedButtonObj.map((_item:DrafetedButtonObject)=>
                       <Button
                          className={this.props.selectedButton === _item.testId ? classes.previewButton :classes.outlineButton }
                          data-test-id="prevButton"
                          disabled={_item.preview}
                          onClick={()=>{_item.onClick();this.setState({selectedButton:_item.testId})}}
                          data-testid={_item.testId} 
                         >
                             {_item.label}
                       </Button>
                     )
                    }
                  {this.props.selectedForm === "Drafted Forms" &&
                    DraftedButton.map((_item:DrafetedButtonObject)=>
                      <Button 
                        data-testid={_item.testId} 
                        onClick={()=>{_item.onClick();this.setState({selectedButton:_item.testId})}}
                        disabled={_item.preview}
                        className={this.props.selectedButton === _item.testId ? classes.previewButton :classes.outlineButton }
                        >
                          {_item.label}
                      </Button>
                    )}
                </Grid>
                <Grid item lg={12} sm={12} className={classes.buttongrid} md={12}>
                  <Pagination variant="outlined" count={this.props.totalPages} page={this.props.activePage} data-testid="pagination" shape="rounded" onChange={this.props.onPagination} />
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid>
        {this.renderSavedSubmitFormModal()}
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const webStyle: {} = (theme: { breakpoints: { down: (arg0: string) => string; }; }) => ({
  mainContainer: {
    backgroundImage: "linear-gradient(to bottom, #F5F7F9, #275080)",
    minHeight: '100vh',
    height: 'auto !important',
    overFlow: "auto",
    margin: "15px",
    borderRadius: "10px"
  },
  banner: {
    padding: "2%"
  },
  discardedGrid: {
    margin: "2%",
    padding: "2% !important",
    backgroundColor: "white",
    borderRadius: "10px",
    [theme.breakpoints.down('sm')]: {
      margin: "1px",
      padding: "1%"
    },
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "28px",
    letterSpacing: "0px",
    color: "#000000",
  },
  filterGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "3%",
    marginBottom: "2%"
  },
  tableGrid: {
  },
  tableRowheadingGrid: {
    backgroundColor: "#EAEAEA"
  },
  tableHeadingText: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "28px",
    letterSpacing: "0px",
    color: "#000000",
    border: '1px solid #dddddd',
    textAlign: "center",
    textTransform: "capitalize"
  },
  tableText: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0px",
    color: "#000000",
    border: '1px solid #dddddd',
    textAlign: "center",
    textTransform: "capitalize"
  },
  tableTextDiscarded: {
    fontWeight: 400,
    lineHeight: "28px",
    fontSize: "16px",
    textAlign: "center",
    letterSpacing: "0px",
    textTransform: "capitalize",
    color: "#808080",
    border: '1px solid #dddddd',
  },
  outlineButton: {
    fontSize: "20px",
    minWidth:'124px',
    fontWeight: 400,
    padding:"7px 16px 7px 16px",
    borderRadius: "4px",
    color: "rgba(11, 51, 106, 1)",
    textTransform: "capitalize",
    border: "1px solid rgba(11, 51, 106, 1)",
  },
  previewButton: {
    backgroundImage: "linear-gradient(to bottom, #002E6A, #708990)",
    fontWeight: 400,
    minWidth:'124px',
    fontSize: "20px",
    textTransform: "capitalize",
    color: "#FFFFFF",
    padding:"7px 16px 7px 16px",
    borderRadius: "4px",
  },
  
  regularBtn: {
    margin: 10,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
    backgroundColor: "#fff",
    textTransform: "capitalize",
    borderRadius: "4px",
    border: "1px solid #0b336A"
  },
  buttongrid: {
    display: "flex",
    gap: "20px",
    justifyContent: "flex-end",
    marginTop:'2%'
  },
  tableGridcontainer: {
    overflowX: 'auto',
  },
  searchField: {
    fontSize: "16px",
    fontWeight: "400",
    color: " #000",
    fontFamily: "Inter",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    width: "100%",
    '& .MuiOutlinedInput-root': {
      borderColor: '#012C5E',
      '&:hover fieldset': {
        borderColor: '#012C5E',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#012C5E',
      },
    },
  },
  searchTextfieldgrid: {
    width: "100%",
    position: "relative"
  },
  searchTextfieldimg: {
    position: "absolute",
    top: "22%",
    right: "2%"
  },
  select: {
    zIndex: 1,
    height: "20px",
    margin: "5%",
    cursor: "pointer",
  },
  filterBox: {
    [theme.breakpoints.down('1633')]: {
      width: "50%",
    },
    width: "23%",
  },
  filterSelect: {
    height: "40px",
    color: "#000000",
    width: "70%",
    fontSize: "18px",
    fontWeight: "400",
    "& .MuiFilledInput-input": {
      background: "none",
      padding: "20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      backgroundColor: "none"
    },
    '&.Mui-focused': {
      '& fieldset': {
        borderColor: 'transparent !important',
      },
    },
    '&:hover': {
      '& fieldset': {
        borderColor: 'transparent !important',
      },
    },

    "& .MuiFilledInput-root": {
      borderRadius: "5px"
    },
    "& .MuiFilledInput-underline:before": {
      borderBottom: "none"
    },
    "& .MuiFilledInput-underline:after ": {
      borderBottom: "none"
    },
    [theme.breakpoints.down('1633')]: {
      width: "80%",
    },
  },
  filterText: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0px",
    paddingTop: "4px",
    color: "#000000",
    display:"inline"
  },
  filterGridstyle: {
    display: "flex",
    flexDirection: "row",
  },
  modalGridstyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "3%",
    margin: "2%",
  },
  tableGridstyle: {
    margin: "1%",
    width: "auto",
  },
  tableContainstyle: {
    width: "auto",
    overflowX: 'auto',
  },
  tableBoxstyle: {
    border: "3px solid black",
    margin: "2% 5% 2% 5%",
    width: "auto",
    paddingTop: "1%",
    paddingBottom: "1%",
  },
  tablestyle: {
    textAlign: "left",
    width: "100%",
    '& tr': {
      border: "1px solid black",
      fontSize: "16px",
      fontWeight: 700,
      borderCollapse: "collapse",
      lineHeight: "21px",
      color: "#000000",
      padding: "10px",
      borderRight: 'none !important',
      borderLeft: 'none !important',
      '& th': {
        padding: '10px',
        borderRight: '1px solid #000',
      },
      '& th:nth-child(2)': {
        padding: '10px',
        borderRight: '1px solid #000 !important',
      },
    }
  },
  tableRowstyle: {
    border: "1px solid black",
    borderCollapse: "collapse",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21px",
    padding: "10px",
    color: "#000000",
  },

  tableRowApplicationstyle: {
    borderCollapse: "collapse",
    fontSize: "16px",
    border: "1px solid black",
    fontWeight: 700,
    color: "#000000",
    lineHeight: "21px",
    padding: "10px",
    borderLeft: 'none !important',
    borderRight: 'none !important',
    '& td': {
      borderRight: '1px solid #000 !important'
    },
    '& th': {
      borderRight: '1px solid #000 !important'
    },
    '& td:nth-child(4)': {
      borderRight: 'none !important',
    },
  },

  tableRowstylePincode: {
    borderCollapse: "collapse",
    border: "1px solid black",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "21px",
    padding: "10px",
    color: "#000000",
    borderLeft: 'none !important',
    borderRight: 'none !important',
  },

  tableRowstyleData: {
    borderCollapse: "collapse",
    border: "1px solid black",
    fontSize: "16px",
    lineHeight: "21px",
    fontWeight: 700,
    padding: "10px",
    color: "#000000",
  },
  applicantDetailsstyle: {
    border: "1px solid black",
    borderCollapse: "collapse",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
    padding: "10px",
    transform: "rotate(-90deg)",
    width: "10px",
    textAlign: "center",
  },
  tableColumnstyle: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21px",
    padding: "10px",
    color: "#000000",
    borderRight:"none !important"
  },
  tableColumnText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#808080",
    padding: "10px"
  },
  tableRowText: {
    border: "1px solid black",
    borderCollapse: "collapse",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#808080",
    padding: "10px"
  },
  checkBox: {
    color: "#F1F1F1 !important",

    '& .MuiSvgIcon-root': {
      fontSize: "30px",
      border: "0.5px solid #979797",
      backgroundColor: "#F1F1F1",
      borderRadius: "5px"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#000000 !important",
    }
  },
  checkBoxActive: {
    color: "#000000 !important",

    '& .MuiSvgIcon-root': {
      fontSize: "30px",
      border: "0.5px solid #979797",
      backgroundColor: "#F1F1F1",
      borderRadius: "5px"
    },
  },
  conditionCheckbox: {
    color: "#0B336A !important",

    '& .MuiSvgIcon-root': {
      fontSize: "26px",
      border: "0.5px solid #979797",
      backgroundColor: "#F1F1F1",
      borderRadius: "5px"
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#0B336A !important",
    }
  },
  modalOpen: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100% !important",
      width: "100% !important",
    }
  },
  closeImg: {
    height: "30px",
    width: "30px",
    border: "3px solid #808080",
    padding: "7px",
    cursor: "pointer",
  },
  formHeadingstyle: {
    textAlign: "center"
  },
  formTitlestyle: {
    fontSize: "32px",
    lineHeight: "21px",
    color: "#000000",
    fontWeight: 700,
    paddingTop: "1%",
  },
  formsubTitlestyle: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
    paddingTop: "1.5%",
    paddingBottom: "2%"
  },
  formsubTitlestyle1: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
  },
  conditionText: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#000000",
    padding: "1%",
    paddingLeft: '1%'
  },
  dateText: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
    padding: "3%"
  },
  applicantText: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
    padding: "3%",
    paddingTop: "5px"
  },
  applicantValue: {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#808080",
    padding: "3%",
    paddingBottom: "5px",
    paddingTop: "2%",
    textDecorationLine: "underline",
    textDecorationThickness: "2px",
    textDecorationStyle: "dashed",
  },
  controllerText: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "21px",
    color: "#000000",
    paddingTop: "3%"
  },
  controllerValue: {
    fontSize: "15px",
    fontWeight: 500,
    color: "#808080",
    textDecorationThickness: "2px",
    textDecorationLine: "underline",
    paddingTop: "66%",
    textDecorationStyle: "dashed",
  },
  dateValue: {
    fontSize: "15px",
    fontWeight: 500,
    color: "#808080",
    paddingLeft: "1%",
    paddingTop: "10% !important",
  },
  headerRight: {
    textAlign: "right",
    width: "5rem"
  },
  headerLeft: {
    width: "18rem"
  },
  modalTextstyle: {
    fontSize: "22px",
    fontWeight: "500",
    lineHeight: "21px",
    color: "#808080",
  },
  savedModalContainer: {
    width: "400px",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    flexDirection: "column",
    color: "#000",
    borderRadius: "8px",
    gap: "10px",
    padding: "20px",
    "@media(max-width:475px)": {
      height: "280px",
      width: "280px",
    },
  },
  modal: {
    alignItems: "center",
    display: "flex",
    padding: "20px",
    justifyContent: "center",
  },
  savedModalHeading: {
    "@media(max-width:475px)": {
      fontSize: "16px",
    },
    fontSize: "20px",
    fontWeight: 500,
  },
  savedModalPara: {
    fontSize: "14px",
  },
  savedModalButton: {
    backgroundImage:
    "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
    color: "#fff",
    backgroundColor: "rgb(22,67,111)",
    height: "35px",
    width: "100px",
    "&:hover": {
      backgroundImage:
      "linear-gradient(to bottom right, rgb(22,67,111), rgb(78,109,132))",
      backgroundColor: "rgb(22,67,111)",
      color: "#fff",
    },
  },
});
export const DiscardedFormComponent = withStyles(webStyle)(DiscardedForm)
// Customizable Area End
