Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg  = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.getFormFillingApiEndPoint = "bx_block_automaticformcreation/form_creations/?id=";
exports.juricdictionFeildHeading = "Jurisdiction";
exports.requiredFeildError = "Selection Required";
exports.typeApplicationHeading = "Type of Application";
exports.typeSpecificationHeading = "Type of Specification";
exports.noteMessage = "Note:Pages of claims, abstract & drawings should be given against respective field";
exports.completeProvisionHeading = "Complete/Provisional Specification(Description Part)";
exports.noOfPagesLabel = "No. of pages";
exports.claimsLabel = "Claim";
exports.additionalChargeMessage = "Additional charges over 9 claims";
exports.numberOfclaimsLabel = "No. of Claims";
exports.abstractHeading = "Abstract";
exports.numberOfDrawingHeading = "No. of Drawing(s)";
exports.sequenceListingLabel = "Sequence listing pages";
exports.numberOfPriorityLabel = "No. of Priorities";
exports.priorityHeading = "Priorities";
exports.addPriorityButtonText = "Add Priorities";
exports.totalCountOfPageMessage = "Total No of Pages (Including Drawings & Abstract ), Claims and Priorty are going to be used in Fee Calculation.";
exports.addressServiceHeading = "Address for Service (India)";
exports.addressServiceErrorMessage = "The provided information should not exceed 500 characters";
exports.stateHeading = "State";
exports.mobileNumberHeading = "Mobile Number";
exports.mobileNumberErrorMessage = "The mobile number should be 10 digits";
exports.mobileValidateError = "Enter valid mobile number";
exports.telephoneNumberHeading = "Telephone No.";
exports.telephoneNumberErrorMessage = "Please enter a valid telephone number";
exports.emailHeading = "Email ID";
exports.emailRegex = /^\s+@\s+\.\s+$/;
exports.emailErrorMessage = "Enter valid Email Address";
exports.aleternativeEmailHeading = "Alternate Email ID(Optional)";
exports.aleternativeEmailErrorMessage = "The email address provided is not in a valid format";
exports.abstractTextChangeError = "The provided information should not exceed 100 characters";
exports.descriptionHeading = "Description";
exports.descriptionTextChangeError = "The provided information should not exceed 1000 characters";
exports.claimsHeading = "Claims";
exports.applicantsHeading = "Applicants";
exports.addApplicantsButtonText = "Add Applicants";
exports.applicantsDeclareInfoText = "I/We, the applicants hereby declare(s) that:-";
exports.checkBoxError = "You must select at least one checkbox before proceeding";
exports.inventorCopyDataText = "Inventors same as Applicant (copy data)";
exports.inventorHeadingText = "Inventors";
exports.addInventorButtonText = "Add Inventors";
exports.proofDocumentSubmitText = "(Proof of Rights, PA, Priority Documents etc are required to be submitted original)";
exports.resetButtonText = "Reset";
exports.saveButtonText = "Save";
exports.uploadButtonText = "Upload";
exports.astrick = "*";
exports.emailValidationRegex = /^[\w\.-]+@[\w\.-]+\.\w+$/;
exports.mobileNumberRegex = /^[6-9]\d{9}$/;
exports.telephoneNumberRegex = /^\d{9,14}$/;
exports.emptySpace= /^\s*$/;
exports.maxLengthRegx = /^(100|\d{1,2})$/;
exports.checkKeys = /^[a-zA-Z ]$/;
exports.require = "Fields marked with an asterisk * are Required.";
exports.applicant = "Applicant Name";
exports.applicantNumber = "Application No.";
exports.applicantError = "The provided information should not exceed 100 characters";
exports.addressError = "The provided information should not exceed 500 characters";
exports.pincodeError = "Enter Valid pincode";
exports.townDiscrict = "Town/City/District";
exports.applicantCityErrorMessage = "something went wrong...";
exports.stateUnionHeading = "State/Union/Territory";
exports.stateUnionError = "Select State/Union/Territory";
exports.countryHeading = "Country";
exports.countryError = "Select Country";
exports.cancelButtonText = "Cancel";
exports.addButtonText = "Add";
exports.applicantFormSuccess = "Application";
exports.successFormText = "success";
exports.doneButtonText = "Done";
exports.referenceId = "with Reference Number : DP-F23/65/2023";
exports.failedFormText = "Failed";
exports.tryAgainText = "Please try again or contact support.";
exports.retryButtonText = "Retry";
exports.hoverFieldDescriptionText = "Hover over the information icon for additional details or context about this field.";
exports.gotItButtonText = "Got it";
exports.addressText = "Address";
exports.pincodeFieldText = "Pincode";
exports.addApplicatns = "Applicant Details";
exports.addInventors = "Inventor Details";
exports.agreeTermCondtionText = "I agree that the above information submitted by me are true to my best knowledge"; 
exports.applicantNameCheckRegex = /^[a-zA-Z\s]*$/;
exports.pincodeChangeRegex = /^[1-9]\d{5}$/;
exports.numberRegex = /^\d+$/;
exports.emailCheck = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
exports.submitFormPutMethod = "PUT";
exports.formSubmitAPIEndPoint = "bx_block_automaticformcreation/form_creations";
exports.formSubmitAPIEndPointPart = "form_submissions/";
exports.formSubmissionWithId = "bx_block_automaticformcreation/form_submissions/"
exports.mobileNumberValidationMsg = "The mobile number should be 10 digits";
exports.telephoneNumberValidationMsg ="Please enter a valid telephone number";
exports.emailIdValidationMsg = "Enter valid Email Address";
// Customizable Area End