Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpDeleteMethod = "DELETE";

exports.contactUsApiContentType = "application/json";
exports.getContactUsAPiEndPoint = "contact_us/contacts";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorEmailNotValid = "Email not valid.";
exports.phoneNumberError = "Phone number not valid"

exports.nameTitle = "Name";
exports.emailTitle = "Email";
exports.numberTitle = "Phone number";
exports.commentsTitle = "Comments";

exports.namePlaceHolder = "Enter Your Name";
exports.emailPlaceHolder = "Enter Your Email";
exports.numberPlaceHolder = "Enter Phone number";
exports.commentsPlaceHolder = "Enter Comments";

exports.labelTitleText = "contactus";
exports.labelBodyText = "contactus Body";

exports.btnExampleTitle = "CLICK ME";
exports.helplineGeneral = "Helpline For General Queries";
exports.patents = "Patents";
exports.trade = "Trade Marks";
exports.helplineOnline = "Helpline For Online Filing";
exports.officesPatents = "Office of Controller General of Patents, Designs & Trade Marks, Mumbai";
exports.officesHead = "Other Offices under CGPDTM and their Head";
exports.getTouch = "Get In Touch With Us";
exports.firstName = "First Name (As ID Proof)";
exports.lastName = " Last Name (As ID Proof)";
exports.emailAddress = "Email Address";
exports.contactNo = "Contact No.";
exports.applicationType = "Application Type";
exports.feedbackType = "Type of Feedback";
exports.subject = "Subject";
exports.charLimit200 = "Max Character Limit is 200";
exports.description = "Description";
exports.charLimit1000 = "Max Character Limit is 1000";
exports.colonTxt = ":";
exports.lineTxt = "|";
exports.otherOfficesPatents = "Other Offices under CGPDTM and their Head";
exports.apiErrorTxt = "API Error";
exports.errorMsg = "This is an error alert — check it out!";
//--- helpline api
exports.helplineContentType = "application/json";
exports.helplineApimethod = "GET";
exports.helplineEndPoint = "bx_block_contact_us/helplines";
//--- office api
exports.officeContentType = "application/json";
exports.officeApimethod = "GET";
exports.officeEndPoint = "/bx_block_contact_us/offices";
//--- form api
exports.formContentType = "application/json";
exports.formApimethod = "POST";
exports.formEndPoint = "/bx_block_contact_us/contacts";
// Customizable Area End