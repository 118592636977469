import React from "react";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import Banner from "../../../components/src/Banner.web";
import Footer from "../../../components/src/Footer.web";
import { Box, Button, Grid, styled } from "@material-ui/core";
import * as IMG_CONST from "./assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Header
          data-test-id="headerData"
          navigation={undefined}
          id={""}
          navData={this.state.navData}
          fontSizeHandler={this.fontSizeHandler}
          currentFontSize={this.state.currentFontSize}
          isLogin={false}
          onLogoutClick={undefined}
          />
        <Box className="container">
          <MainBox>
            <Banner
              title="welcome"
              activeLink="home"
              navigation={undefined}
              id={""}
              activePage="Terms & Conditions"
            />
            <Box
              style={styles.innerBox}>
              <Box component={"div"} style={styles.boxStyleTwo}>
                <Box
                  data-test-id="descriptionTestId"
                  dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionsData?.data?.attributes?.description }}
                />
                <ButtonGrid container spacing={2}>
                  <Grid item >
                    <ButtonStyle
                      variant="outlined"
                      color="primary"
                      startIcon={<img src={IMG_CONST.mobileImg} />}
                    >
                      {configJSON.mobileTxt}
                    </ButtonStyle>
                  </Grid>
                  <Grid item >
                    <ButtonStyle
                      variant="outlined"
                      color="primary"
                      startIcon={<img src={IMG_CONST.employeesImg} />}
                    >
                      {configJSON.employeeTxt}
                    </ButtonStyle>
                  </Grid>
                  <Grid item >
                    <ButtonStyle
                      variant="outlined"
                      color="primary"
                      startIcon={<img src={IMG_CONST.publicImg} />}
                    >
                      {configJSON.publicTxt}
                    </ButtonStyle>
                  </Grid>
                  <Grid item >
                    <ButtonStyle
                      variant="outlined"
                      color="primary"
                      startIcon={<img src={IMG_CONST.galleryImg} />}
                    >
                      {configJSON.galleryTxt}
                    </ButtonStyle>
                  </Grid>
                  <Grid item >
                    <ButtonStyle
                      variant="outlined"
                      color="primary"
                      startIcon={<img src={IMG_CONST.fileImg} />}
                    >
                      {configJSON.rtiTxt}
                    </ButtonStyle>
                  </Grid>
                </ButtonGrid>
                <ButtonGrid container spacing={5}>
                  <Grid item >
                    <img
                      src={IMG_CONST.indiaPortalImg}
                      alt="indiaPortal" />
                  </Grid>
                  <Grid item >
                    <img
                      src={IMG_CONST.nvspImg}
                      alt="indiaPortal" />
                  </Grid>
                  <Grid item >
                    <img
                      src={IMG_CONST.dippImg}
                      alt="indiaPortal" />
                  </Grid>
                  <Grid item >
                    <img
                      src={IMG_CONST.myGovImg}
                      alt="indiaPortal" />
                  </Grid>
                </ButtonGrid>
              </Box>
            </Box>
            <Footer
              navigation={undefined} 
              id={""}
              footerNavData={this.state.footerNavData}
            />
          </MainBox>
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  signupContainer: {
    paddingTop: "2rem"
  },

  description: {
    '& p,span': {
      fontSize: "18px !important",
      fontWeight: 400,
      fontFamily: "Roboto",
      "& strong": {
        fontSize: "24px !important",
        fontWeight: 600
      }
    }
  },

  innerBox: {
    padding: '10px',
    borderRadius: "10px",
    background: 'linear-gradient(-136deg, #002E6A -7.18%, #708990 104.44%)',
    marginTop: "20px"
  },

  boxStyleTwoBg: {
    marginTop: "30px",
    background: "linear-gradient(180deg, rgba(247, 248, 250, 1) 0%, rgba(39, 80, 128, 1) 100%)",
    padding: "6px",
    borderRadius: 8
  },

  boxStyleTwo: {
    padding: '20px',
    background: "white"
  }
}

const ButtonGrid = styled(Grid)({
  marginTop: '30px',
  display: "flex",
  justifyContent: "center"
});

const ButtonStyle = styled(Button)({
  display: "flex",
  width: "220px",
  padding: "16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "16px",
  color: '#275080',
  textTransform: "capitalize" as "capitalize",
  borderRadius: 8
});

const MainBox = styled(Box)({
  paddingTop: "2rem"
});
// Customizable Area End
