Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.forgotPasswordEndPoint = "bx_block_forgot_password/forgot";
exports.resetPasswordEndPoint = "bx_block_forgot_password/reset"
exports.changePaswordTxt = " Change Password";
exports.currentPaswordTxt = "Current Password";
exports.newPasswordTxt = "New Password";
exports.confirmPasswordTxt = "Confirm Password";
exports.captchaTxt = "Captcha";
exports.digitalSignatureTxt = "Digital Signature";
exports.resetTxt = "Reset";
exports.changeTxt = "Change";
exports.orTxt = "Or";
exports.notChangePasswordTxt = "Password Not Changed";
exports.retryTxt = "Retry";
exports.doneTxt = "Done";
exports.chnagePasswordTxt = 'Password Has Been Changed Successfully';
exports.submitBtnText = "Submit";
exports.backBtn = "Back";
exports.specialCharacterTxt = "1 Special Character";
exports.leastDigitTxt = "Numbers (i.e. 0-9)";
exports.upperLatterTxt = "Upper case letters (A-Z)";
exports.lowerCaseTxt = "Lower case letters (a-z)";
exports.shouldContainTxt = "Should contain:";
exports.characterLengthTxt = "At least 8-15 characters in length";
exports.resetPasswordTxt = "Reset Password";
exports.successChangePassword = "Password changed successfully";
exports.forgotPassTxt = "Forgot Password";
exports.userId = "User ID";
exports.digitalSignature = "Digital Signature";
exports.resetButtonTxt = "Reset";
exports.resetLinkSndTxt = "Reset link sent on registered mail id";
exports.resetPassTxt = "An email to reset your password has been sent on ";
exports.noteReset = "Please note that the link is valid for next 30 minutes";
exports.loginBtnTxt = "Login";
exports.specialCharacterRegex = /[!@#\$%\^&\*(),.?":{}|<>]/;
//Change_password
exports.changePswrdContentType='application/json';
exports.changePswrdMethodType='PUT';
exports.changePswrdEndPoint='bx_block_settings/change_password';
// Customizable Area End