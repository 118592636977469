import React, { Component } from "react";
// Customizable Area Start
import { MdKeyboardArrowRight } from "react-icons/md"
import * as IMG_CONST from './assets'
type MyProps = { navigation: undefined; id: string; footerNavData: any }
// Customizable Area End

// Customizable Area Start
class Footer extends Component<MyProps> {
    renderFooter = () => {
        return (
            <footer className="footer">
                {this.renderNav()}
                <p className="footer-copyright">
                    Content Owned, updated and maintained by Intellectual Property India,
                    All Rights Reserved.
                </p>
                <div className="footer-logo">
                    <img src={IMG_CONST.footerLogoImg} alt="footer logo" />
                </div>
            </footer>
        )
    }

    renderNav = () => (
        <ul className="footer-nav-list">
            {this.props.footerNavData.map((link: any) => (
               <li key={link.name} className="footer-nav-list-item">
               <span className="footer-nav-icon">
                   <MdKeyboardArrowRight />
               </span>{" "}
               <a href={link.link} className="footerTxt">{link.name}</a>
           </li>
            ))}
        </ul>
    );

    render() {
        return (
            <>
                {this.renderFooter()}
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default Footer
// Customizable Area End