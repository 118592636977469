import React from 'react'

// Customizable Area Start
import DigitalSignatureController from "./DigitalSignatureController.web";
// Customizable Area End

export default class DigitalSignature extends DigitalSignatureController {

    renderDigitalSignature = () => {
        // Customizable Area Start
        return (
            <div className="digitalSignatuer">
                <div className="digitalSignatuerMain">
                    <h1 className="digitalSignatuerHeading">Digital Signature</h1>
                    <button className="digitalSignatuerBtn digitalSignatuerBtnActive">
                        Add Digital Signature
                    </button>
                    <div className="digitalSignatureBtnContainer1">
                        <button
                            data-testid={"backButtonId"}
                            onClick={this.backToForgot}
                            className="abccc digitalSignatuerBtn digitalSignatuerBtnActive"
                            >
                            Back
                        </button>
                        <button className="abccc digitalSignatuerBtn">Next</button>
                    </div>
                </div>
            </div>
        );
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        return (
            <>
                {this.renderDigitalSignature()}
            </>
        );
        // Customizable Area End
    }
}