import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import Alert, { Color } from "@material-ui/lab/Alert";
export interface HelplineGeneralInterfaceData {
  message: string;
  attributes: {
    title: string;
    helpline_details: {
      Patents: [];
      ['Trade Marks']: [];
    }
  };
  data: HelplineGeneralInterfaceData[];
  id: number;
  helpNumber: string;
  stateName: string;
}
export interface PatentsInterfaceData {
  id: number;
  title: string;
  subtitle: string;
  mail: string;
  phoneNo: string;
  message: string;
}

interface OfficesDataInteFace {
  attributes: {
    id: string;
    profile_photo_url: string;
    designation: string;
    name: string;
    address: string;
    phone_number: string;
    fax_number: string;
    website_link: string;
    email: string;
  }
}

interface OfficeAttributesInterface {
  title: string;
  office_details: string;
}

export interface OfficesInterfaceData1 {
  id: string;
  type: string;
  attributes: {
    title: string;
    office_details: {
      data: [
        {
          id: string;
          type: string;
          attributes: {
            name: string;
            designation: string;
            address: string;
            website_link: string;
            phone_number: string;
            email: string;
            fax_number: string;
            profile_photo_url: string | null;
          }
        }
      ]
    }
  }
}

export interface OfficesInterfaceData {
  message: string;
  data: [{
    id: string;
    type: string;
    attributes: {
      title: string;
      office_details: {
        data: [
          {
            id: string;
            type: string;
            attributes: {
              name: string;
              designation: string;
              address: string;
              website_link: string;
              phone_number: string;
              email: string;
              fax_number: string;
              profile_photo_url: null;
            }
          }
        ]
      }
    }
  }]
}

interface NavItem {
  link: string;
  path: string;
}

interface FooterNavItem {
  name: string;
  link: string;
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ValidResponseType {
  message: object;
  data: object;
}

interface InvalidResponseType {
  errors: Array<string>;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  helplineGeneralData: Array<HelplineGeneralInterfaceData>;
  officesData: Array<OfficesInterfaceData1>;
  firstName: string;
  lastName: string;
  phoneNo: string;
  subject: string;
  description: string;
  firstNameErr: string;
  lastNameErr: string;
  emailErr: string;
  phoneNoErr: string;
  subjectErr: string;
  descriptionErr: string;
  applicationDropdown: boolean;
  activeApplicationDropdownItem: string;
  applicationTypeErr: string;
  feedbackTypeErr: string;
  feedbackDropdown: boolean;
  activeFeedbackDropdownList: string;
  feedbackDataList: Array<string>;
  applicationDataList: Array<string>;
  navData: NavItem[];
  currentFontSize: string;
  footerNavData: FooterNavItem[];
  isAlert: boolean;
  alertMsg: string;
  alertType: Color;
  isModalOn: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  applicationDropdownRef: React.RefObject<HTMLDivElement>;
  feedbackDropdownRef: React.RefObject<HTMLDivElement>;
  helplineAPICallId: string = "";
  officeApiCallId: string = "";
  formAPICallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      helplineGeneralData: [],
      officesData: [],
      firstName: "",
      lastName: "",
      phoneNo: "",
      subject: "",
      description: "",
      firstNameErr: "",
      lastNameErr: "",
      emailErr: "",
      phoneNoErr: "",
      subjectErr: "",
      descriptionErr: "",
      applicationTypeErr: "",
      feedbackTypeErr: "",
      applicationDropdown: false,
      activeApplicationDropdownItem: "Select Application Type",
      feedbackDropdown: false,
      activeFeedbackDropdownList: "Select Feedback Type",
      feedbackDataList: [
        "Select Feedback Type",
        "General Query",
        "E-Filing",
        "Back Office",
        "General Observation / Feedback",
        "Filling",
        "EDP",
        "Publication",
        "Examination",
        "Post Grand"
      ],
      applicationDataList: [
        "Select Application Type",
        "Patents",
        "Design",
        "Trade Marks",
        "GIR"
      ],
      navData:
        [
          {
            link: "Home",
            path: "/"
          },
          {
            link: "FAQs",
            path: "/faqs"
          },
          {
            link: "Feedback",
            path: "/feedback"
          },
          { link: "Sign Up", path: "/signUp" }
        ],
      footerNavData: [
        {
          name: "Terms and Conditions",
          link: "/terms-conditions"
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy"
        },
        {
          name: "Copyright",
          link: ""
        },
        {
          name: "Hyperlinking Policy",
          link: ""
        },
        {
          name: "Accessibility",
          link: ""
        },
        {
          name: "Archive",
          link: ""
        },
        {
          name: "Contact Us",
          link: ""
        },
        {
          name: "Sitemap",
          link: ""
        }],
      currentFontSize: "16px",
      isAlert: false,
      alertMsg: "",
      alertType: "success",
      isModalOn: false
    };
    this.applicationDropdownRef = React.createRef();
    this.feedbackDropdownRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getHelplineApi();
    this.getOfficeApi();
    const handleClickOutsideApplication = (event: MouseEvent) => {
      if (
        this.applicationDropdownRef.current &&
        !this.applicationDropdownRef.current.contains(event.target as Node)
      ) {
        this.setState({
          applicationDropdown: false,
        });
      }
      if (
        this.feedbackDropdownRef.current &&
        !this.feedbackDropdownRef.current.contains(event.target as Node)
      ) {
        this.setState({
          feedbackDropdown: false,
        });
      }
    };
    document.addEventListener("click", handleClickOutsideApplication);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        return (
          <Alert severity="error">{configJSON.errorMsg}</Alert>
        );
      }
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
      if (responseJson && responseJson.errors) {
        this.apiFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  btnBackProps = {
    onPress: () => this.props.navigation.goBack(),
  };

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let errors = {
      firstNameErr: "",
      lastNameErr: "",
      emailErr: "",
      phoneNoErr: "",
      subjectErr: "",
      descriptionErr: "",
      applicationTypeErr: "",
      feedbackTypeErr: ""
    };
    if (this.state.firstName === "") {
      errors.firstNameErr = "First Name is Required.";
    }
    if (this.state.lastName === "") {
      errors.lastNameErr = "Last Name is Required.";
    }
    if (this.state.email === "") {
      errors.emailErr = "Email Address is Required.";
    }
    if (this.state.phoneNo === "") {
      errors.phoneNoErr = "Contact No. is Required.";
    }
    if (this.state.subject === "") {
      errors.subjectErr = "Subject is Required.";
    }
    if (this.state.description === "") {
      errors.descriptionErr = "Description is Required.";
    }
    if (this.state.activeApplicationDropdownItem === "Select Application Type") {
      errors.applicationTypeErr = "Select Application Type";
    }
    if (this.state.activeFeedbackDropdownList === "Select Feedback Type") {
      errors.feedbackTypeErr = "Select Type of Feedback";
    }
    this.setState(errors, () => {
      if (
        Object.values(errors).every(error => error === "")
      ) {
        this.postFormApi();
      }
    });
  };

  firstNameChangeHandler = () => {
    const firstName = this.state.firstName;
    if (/^[A-Za-z]+$/.test(firstName)) {
      this.setState({
        firstNameErr: ""
      });
    } else {
      this.setState({
        firstNameErr: firstName ? "Enter Valid First Name." : "First Name is Required."
      });
    }
  };

  lastNameChangeHandler = () => {
    const stringRegex = /^[A-Za-z]+$/.test(this.state.lastName)
    if (stringRegex) {
      this.setState({
        lastNameErr: ""
      });
    } else {
      this.setState({
        lastNameErr: this.state.lastName
          ? "Enter Valid Last Name."
          : "Last Name is Required."
      });
    }
  };

  emailChangeHandler = () => {
    const { email } = this.state;
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailRegex.test(email)) {
      this.setState({
        emailErr: ""
      });
    } else {
      this.setState({
        emailErr: email ? "Enter Valid Email Address." : "Email Address is Required."
      });
    }
  };

  phoneNoChangeHandler = () => {
    const stringRegex = /^\+91[6-9]\d{9}$/.test(this.state.phoneNo)
    if (stringRegex) {
      this.setState({
        phoneNoErr: ""
      });
    } else {
      this.setState({
        phoneNoErr: this.state.phoneNo
          ? "Enter Valid Contact No."
          : "Contact No is Required."
      });
    }
  };

  subjectChangeHandler = () => {
    if (
      this.state.subject.trim().length > 0 &&
      this.state.subject.trim().length <= 200
    ) {
      this.setState({
        subjectErr: ""
      });
    } else {
      this.setState({
        subjectErr: "err"
      });
    }
  };

  descriptionChangeHandler = () => {
    if (
      this.state.description.trim().length > 0 &&
      this.state.description.trim().length <= 1000
    ) {
      this.setState({
        descriptionErr: ""
      });
    } else {
      this.setState({
        descriptionErr: "err"
      });
    }
  };

  onFirstNameChange = (event: { target: { value: string } }) => {
    this.setState({ firstName: event.target.value }, () => {
      this.firstNameChangeHandler();
    });
  };

  onLastNameChange = (event: { target: { value: string } }) => {
    this.setState({ lastName: event.target.value }, () => {
      this.lastNameChangeHandler();
    });
  };

  onEmailChange = (event: { target: { value: string } }) => {
    this.setState({ email: event.target.value }, () => {
      this.emailChangeHandler();
    });
  };

  onPhoneNumberChange = (event: { target: { value: string } }) => {
    this.setState({ phoneNo: event.target.value }, () => {
      this.phoneNoChangeHandler();
    });
  };

  onSubjectChange = (event: { target: { value: string } }) => {
    this.setState({ subject: event.target.value }, () => {
      this.subjectChangeHandler();
    });
  };

  onDescriptionChange = (event: { target: { value: string } }) => {
    this.setState({ description: event.target.value }, () => {
      this.descriptionChangeHandler();
    });
  };

  onApplicationDropDownClick = () => {
    this.setState({
      applicationDropdown: !this.state.applicationDropdown
    });
  };

  onActiveApplication = (item: string) => {
    this.setState({
      activeApplicationDropdownItem: item,
      applicationDropdown: false,
      applicationTypeErr: ""
    });
  };

  onFeedbackDropDownClick = () => {
    this.setState({
      feedbackDropdown: !this.state.feedbackDropdown
    });
  };

  onActiveFeedbackClick = (item: string) => {
    this.setState({
      activeFeedbackDropdownList: item,
      feedbackDropdown: false,
      feedbackTypeErr:""
    });
  };

  fontSizeHandler = (size: string): void => {
    const root: HTMLElement | null = document.documentElement;
    if (root) {
      root.style.setProperty("--base-font-size", size);
    }
    this.setState({
      currentFontSize: size
    });
  };

  onResetClick = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      phoneNo: "",
      subject: "",
      description: "",
      activeApplicationDropdownItem: "Select Application Type",
      activeFeedbackDropdownList: "Select Feedback Type",
      firstNameErr: "",
      lastNameErr: "",
      emailErr: "",
      phoneNoErr: "",
      subjectErr: "",
      descriptionErr: "",
      feedbackTypeErr: "",
      applicationTypeErr: ""
    })
  }

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data || responseJson.message);
  };

  inValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSucessCall = async (apiRequestCallId: string, responseJson: HelplineGeneralInterfaceData & OfficesInterfaceData) => {
    if (apiRequestCallId === this.helplineAPICallId) {
      this.helplineSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.officeApiCallId) {
      this.officeSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.formAPICallId) {
      this.formSuccessCallBack();
    }
  };

  apiFailureCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.helplineAPICallId) {
      this.helplineFailureCallBack();
    }
    if (apiRequestCallId === this.officeApiCallId) {
      this.officeFailureCallBack();
    }
    if (apiRequestCallId === this.formAPICallId) {
      this.formFailureCallBack(responseJson);
    }
  };

  getHelplineApi = async () => {
    this.helplineAPICallId = await this.apiCall({
      contentType: configJSON.helplineContentType,
      method: configJSON.helplineApimethod,
      endPoint: configJSON.helplineEndPoint
    });
  };

  helplineSucessCallBack = (responseJson: HelplineGeneralInterfaceData) => {
    responseJson.message != "No helplines found" &&
      this.setState({ helplineGeneralData: responseJson.data })
  };

  helplineFailureCallBack = () => {
    alert(configJSON.apiErrorTxt)
  };

  getOfficeApi = async () => {
    this.officeApiCallId = await this.apiCall({
      contentType: configJSON.officeContentType,
      method: configJSON.officeApimethod,
      endPoint: configJSON.officeEndPoint
    });
  };

  officeSucessCallBack = (responseJson: OfficesInterfaceData) => {
    responseJson.message != "No offices found" && this.setState({ officesData: responseJson.data });
  };

  officeFailureCallBack = () => {
    alert(configJSON.apiErrorTxt)
  };

  postFormApi = async () => {
    const body = {
      contact: {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        phone_number: this.state.phoneNo,
        application_type: this.state.activeApplicationDropdownItem,
        feedback_type: this.state.activeFeedbackDropdownList,
        subject: this.state.subject,
        description: this.state.description
      }
    };
    this.formAPICallId = await this.apiCall({
      contentType: configJSON.formContentType,
      method: configJSON.formApimethod,
      endPoint: configJSON.formEndPoint,
      body: body
    });
  };

  formSuccessCallBack = () => {
    this.setState({ isModalOn: true },
      () => {
        this.onResetClick()
      }
    );
  };

  formFailureCallBack = (responseJson: ValidResponseType) => {
    this.setState({ isAlert: true, alertMsg: "API fail", alertType: "error" });
  };

  oncloseAlert = () => {
    this.setState({ isAlert: false });
  };

  switchOffModal = () => {
    this.setState({ isModalOn: false });
  }
  // Customizable Area End
}
