import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName,} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import countrylist from "./CountryCodeList";
import StorageProvider from "../../../framework/src/StorageProvider";

export interface ModalDataObject {
  id: string;
  type: string;
  attributes: {
    id: string;
    name: string;
    note: string;
    position: number;
    multiple: boolean;
    form_fields: {
      data: {
        id: string;
        type: string;
        attributes: {
          same_as_applicant: boolean;
          id: string;
          field_name: string;
          field_type: string;
          note: string;
          field_value: unknown;
          position: number;
          required: boolean;
          form_values: Array<{
            id: string;
            value: string
          }>;
          file_value: null;
          isValid?: boolean;
        }
      }[]
    }
  }

}
export interface FormFieldDataObject {
  id: string;
  type: string;
  attributes: {
    field_name: string;
    field_type: string;
    field_value: null|unknown;
    required: boolean;
    form_values: Array<FormValueDataObject>;
    isValid?: boolean;
    minimum_number?: number;
    maximum_number?: number;
    note?:string;
    information_icon?:boolean;
    position:number
  }
}

export interface FormValueDataObject {
  value: string;
  id: string;
}
export interface ValidResponseType {
  data: object;
}

export interface ApiFailureData {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  token: string;
}

export interface ApiDataType {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object;
}

export interface FormFillingInterface {
  reference_number: string;
  message: string;
  data: [{
    id: number;
    type: string;
    form_description: string;
    attributes: {
      id: number;
      name: string;
      description: string;
      price: string;
      form_sections: FormSectionDataObject;
    }
  }]
}

interface FormSectionDataObject {
  data: [ModalDataObject]
}

export interface FormFillingDataInterface {
  form_description: string;
  id: number;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    price: string;
    form_sections: FormSectionDataObject;
  }
}

export type FieldTypes = {
  [key: string]: JSX.Element | null;
};

interface FieldAttributes {
  attributes: {
    position: number;
    field_name: string;
    field_type: string;
    field_value: string | null| unknown;
    required: boolean;
  }
}

interface AttributesType {
  id: string;
  note: string;
  field_name: string;
  field_type: string;
  field_value: string | null | unknown;
  position: number;
  required: boolean;
  form_values: {
    id: string;
    value: string;
  }[];
  file_value: null;
  isValid?: boolean;
}

type FieldErrorSet = { [key: string]: boolean }

export interface FormSubmitSuccess {
  data: {
    message: "form submitted successfully",
    reference_number: "DP-F23/35/2023"
  }
}

interface FormSubmitFailure {
  errors: Array<string>;
}
// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;/*  */
  // Customizable Area Start
  handleToggle?:any;
  formId?:any
  // Customizable Area End
}

const { baseURL: apiBaseUrl } = require('./../../../framework/src/config')

interface StateType {
  first_name: string;
  last_name: string;
  phone_number: string;
  selectedCountry: string,
  selectedGender: string,
  selectedPhoneCountry: string,
  email: string;
  message: string;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  activeSteps: number;
  countries: Array<object>,
  gender: Array<Gender>,
  country: Array<Country>,
  industry: Array<Industry>,
  selectedIndustry: string,
  token_local: string | null,
  showErrors: boolean;
  errorsData: string;
  successData: string;
  successDataMsg: string;
  isLoading: boolean;
  isSubmitted: boolean;  
  formikData: FormikData;
  // Customizable Area Start
  isDisabled:boolean;
  isDisabledInventor:boolean;
  responseError: string;
  apiSuccessResponse: Array<FormFillingDataInterface>;
  formIds: Array<number>;
  fieldType: string;
  dictionSelected: string;
  dictionSelectedError: boolean;
  typeSelection: string;
  typeSelectionError: boolean;
  specificationSelection: string;
  specificationSelectionError: boolean;
  provisionalPart: string;
  provisionalPartError: boolean;
  applicanformModalVisible: boolean;
  applicantBeforeIndex: number;
  sortedFormSection: Array<ModalDataObject>;
  formFilteredData: Array<ModalDataObject>;
  floatValue: string;
  floatValueError: boolean;
  selectedChecbox: Array<string>;
  formCheck: boolean;
  formData: Record<string, string | boolean>;
  resertAllForm: boolean;
  saveAllForm: boolean;
  uploadAllForm: boolean;
  applicantData: object;
  applicantMultipleData: Array<object>;
  fieldErrors: Array<FieldErrorSet>;
  mainFieldIndex: number;
  fieldValueIndex: number;
  checkArray: Array<string>;
  selectedCheckboxes: string;
  formSubmitSuccssMessage: string;
  formReferenceId: string;
  formSubmitError: FormSubmitFailure;
  openSavedModal: boolean;
  openFailedModal: boolean;
  saveInitialModalData: Array<object>;
  formModalFilterData: Array<object>;
  editIndex: number;
  formHoverDescription: Array<string>;
  tempSubmitModal:Array<ModalDataObject>;
  formType:string;
  loginToken:string|null
  // Customizable Area End
}

interface FormikData {
  first_name : string,
  last_name : string,
  email : string,
  selectedPhoneCountry: string,
  phone_number: string,
  selectedGender?: string,
  selectedCountry? : string,
  message?: string,
  selectedIndustry? : string,
  countries: Array<object>
}

// Customizable Area Start
interface CustomTypeData {
  contentType: string,
  method: string,
  body?: {
    data: CustomTypeBodyData
  },
  endPoint: string,
}

interface CustomTypeBodyData{
  attributes: {
    first_name: string,
    last_name: string,
    phone_number: string,
    email: string,
    gender: string,
    country: string,
    industry: string,
    message: string
  },
  type: string,
}
// Customizable Area End


interface CustomTypeSubmitvalues {
  first_name: string,
  last_name: string, 
  selectedPhoneCountry: string, 
  phone_number: string, 
  email: string, 
  countries: Array<object>,
  selectedGender?: string, 
  selectedCountry?: string, 
  selectedIndustry?: string, 
  message?: string,
}

interface CustomTypevalues {
  first_name: string,
  last_name: string, 
  selectedPhoneCountry: string, 
  phone_number: string, 
  email: string, 
  selectedGender: string, 
  selectedCountry: string, 
  selectedIndustry: string, 
  message: string,
}

export interface CountriesList {
  Countries : Array<Countries> ;

}

 interface Countries {
    name: string,
    dial_code: string,
    code: string
  }

export interface Gender {
   label: string, 
   value: string 
}

export interface Industry {
  label: string, 
  value: string 
}
export interface Country {
  label: string, 
  value: string 
}
interface SSType {
  SSTypeid: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultipageFormsController extends BlockComponent<
  Props,
  StateType,
  SSType
> {
  // Customizable Area Start
  submitTransactionApiCallId: string = "";
  getOrderApiCallId: string = "";
  getFormFillingApiCallId: string = "";
  submitFormApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      first_name: "",
      last_name: "",
      selectedPhoneCountry: "+91",
      phone_number: "",
      email: "",
      message: "",
      selectedCountry: "",
      selectedGender: "",
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      activeSteps: 0,
      countries: countrylist,
      formikData: {
        selectedCountry: "1",
        selectedGender: "1",
        selectedIndustry: "education",
        first_name: "",
        last_name: "",
        email: "",
        selectedPhoneCountry: "",
        message: "",
        phone_number: "",
        countries: countrylist
      },
      
      // Add more countries as neede
      gender: [
        { label: 'Male', value: '1' },
        { label: 'Female', value: '2' },
      ],
      country: [
        { label: 'India', value: '1' },
        { label: 'America', value: '2' },
        { label: 'Canada', value: '3' },
        { label: 'England', value: '4' },
      ],
      industry: [
        { label: 'Education', value: 'education' },
        { label: 'Food', value: 'food' },
        { label: 'Marketing', value: 'marketing' }
      ],
      selectedIndustry: '',
      token_local: localStorage.getItem("loginTokenBlock"),
      showErrors: false,
      errorsData: "",
      isLoading: false,
      successData: "",
      isSubmitted: false,
      successDataMsg: "",
      // Customizable Area Start
      isDisabled:false,
      isDisabledInventor:false,
      responseError: "",
      apiSuccessResponse: [],
      loginToken:"",
      formIds: [],
      fieldType: "",
      dictionSelected: "",
      dictionSelectedError: false,
      typeSelection: "",
      typeSelectionError: false,
      specificationSelection: "",
      specificationSelectionError: false,
      provisionalPart: "",
      provisionalPartError: false,
      applicanformModalVisible: false,
      applicantBeforeIndex: NaN,
      sortedFormSection: [],
      formFilteredData: [],
      floatValue: "",
      floatValueError: false,
      selectedChecbox: [],
      formCheck: false,
      formData: {},
      resertAllForm: false,
      saveAllForm: false,
      uploadAllForm: false,
      applicantData: {},
      applicantMultipleData: [],
      fieldErrors: [],
      mainFieldIndex: 0,
      fieldValueIndex: 0,
      checkArray: [],
      selectedCheckboxes: "",
      formSubmitSuccssMessage: "",
      formReferenceId: "",
      formSubmitError: {} as FormSubmitFailure,
      openSavedModal: false,
      openFailedModal: false,
      saveInitialModalData: [],
      formModalFilterData: [],
      editIndex: NaN,
      formHoverDescription: [],
      tempSubmitModal: [],
      formType:''
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  
   // Customizable Area Start
  getOrderIdFailureCallBack = async (responseJson: string) => {

  };
  
  // Api response
  async receive(from: string, message: Message) {
    
    if(getName(MessageEnum.RestAPIResponceMessage) === message.id) 
    {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors && apiRequestCallId === this.getOrderApiCallId) {
          this.getOrderIdFailureCallBack(responseJson);
      }
      if (responseJson && !responseJson.errors && apiRequestCallId === this.getOrderApiCallId) { 
        
        const data = responseJson.data.attributes;
        this.setState({
          successDataMsg: configJSON.successMessage,
          successData: "",
          isSubmitted: true,
          activeSteps: 2,
          first_name: data.first_name,
          last_name: data.first_name,
          email: data.first_name,
          phone_number: data.phone_number,
          selectedGender: data.gender,
          selectedCountry: data.country,
          selectedIndustry: data.industry,
          message: data.message,
          formikData: {
            selectedCountry: "1",
            selectedGender: "1",
            selectedIndustry: "education",
            first_name: "",
            last_name: "",
            email: "",
            selectedPhoneCountry: "",
            message: "",
            phone_number: "",
            countries: countrylist
          },
        })
        }

      if (this.isValidResponse(responseJson)) {
        this.responseFormFillingSucessCall(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.responseFormFillingFailureCall(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
}


  multiPageFormSubmit = async (values : CustomTypevalues) => {
    
    const selectedCountryLabel = this.state.country.filter((item: Country) => {
      return item.value === values.selectedCountry ? item : null
    });
    const selectedGenderLabel = this.state.gender.filter((item: Gender) => {
      return item.value === values.selectedGender ? item : null
    });
    const selectedIndustryLabel = this.state.industry.filter((item: Industry) => {
      return item.value === values.selectedIndustry ? item : null
    });

    const rawData = {
      "data": {
        "attributes": {
          "first_name": `${values.first_name}`,
          "last_name": `${values.last_name}`,
          "phone_number": `${values.selectedPhoneCountry} ${values.phone_number}`,
          "email": `${values.email}`,
          "gender": selectedGenderLabel[0].label,
          "country": selectedCountryLabel[0].label,
          "industry": selectedIndustryLabel[0].value,
          "message": `${values.message}`
        },
        "type": "email_account"
      }
    };
      
      this.getOrderApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'POST',
          body: rawData,
          endPoint: '/bx_block_multipageforms/user_profiles'
      })
    
  }
  apiCall = async (data: CustomTypeData) => {
    const { contentType, method, endPoint, body } = data;
      const header = {
          "Content-Type": contentType,
      };
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
      );
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
      );
      body &&
          requestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(body)
          );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
  };
  
  stepOnSubmit = (values: CustomTypeSubmitvalues) => {
    this.setState({ formikData: values, activeSteps: this.state.activeSteps + 1 })
  }

  
  handleBack = () => {
    this.setState({ activeSteps: this.state.activeSteps - 1 })
  };

  getSteps() {
    return ["Step 1", "Step 2", "Complete"];
  }

  LoginPage = () => {
    this.props.navigation.navigate('EmailAccountLogin')
  }
  
  txtInputProps = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }


  // Customizable Area Start

  async componentDidMount() {
    const getToken = await StorageProvider.get("TOKEN")
    this.setState({
      token_local: getToken
  },()=>  this.formFillingGetApi())
  }

  multipageFormApiCall = async (data: ApiDataType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token:this.state.token_local
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  }

  isInValidResponse = (responseJson: ApiFailureData) => {
    return responseJson && responseJson.errors;
  }

  responseFormFillingSucessCall = async (apiRequestCallId: string, responseJson: FormFillingInterface & FormSubmitSuccess) => {
        if (apiRequestCallId === this.getFormFillingApiCallId) {
      this.formFillingApiSucessCallBack(responseJson);
    }
    if (apiRequestCallId === this.submitFormApiCallId) {
      this.submitFormApiSucessCallBack(responseJson);
    }
  }

  responseFormFillingFailureCall = async (apiRequestCallId: string, responseJson: ApiFailureData & FormSubmitFailure) => {
    if (apiRequestCallId === this.getFormFillingApiCallId) {
      this.formFillingApiFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.submitFormApiCallId) {
      this.submitFormApiFailureCallBack(responseJson);
    }
  }

  formFillingGetApi = async () => {
    const formID = await StorageProvider.get('formid')
    this.getFormFillingApiCallId = await this.multipageFormApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint:formID ? configJSON.formSubmissionWithId+formID :configJSON.getFormFillingApiEndPoint
    });
  }

  formFillingApiSucessCallBack = (formFillingResponse: FormFillingInterface) => {
    this.setState({ apiSuccessResponse: formFillingResponse.data }, () => {
      this.apiDataSorting()
    });
  }

  formFillingApiFailureCallBack = (formFillingResponse: ApiFailureData) => {
    this.setState({
      responseError: formFillingResponse.errors[0].token
    });
  }

  apiDataSorting = () => {
    let { apiSuccessResponse } = this.state;
    let formCreationId = apiSuccessResponse?.map((form: FormFillingDataInterface) => form.id);
    let formSectionDescription = apiSuccessResponse?.map((formDescription: FormFillingDataInterface) => formDescription.form_description);
    this.setState({ formIds: formCreationId, formHoverDescription: formSectionDescription });
    let formsection = apiSuccessResponse?.map((item: FormFillingDataInterface) => item.attributes.form_sections.data);
    let sortFormSection = formsection[0].sort((itemPosition1, itemPosition2) => itemPosition1.attributes.position - itemPosition2.attributes.position);
    let isMultipleFormTrue = sortFormSection.filter((item: { attributes: { multiple: boolean } }) => item.attributes.multiple === true);
    let isMultipleFormFalse = sortFormSection.filter((item: { attributes: { multiple: boolean } }) => item.attributes.multiple === false);
    let isMultiFormFalseValidation = isMultipleFormFalse.map((item: ModalDataObject) => {
      item.attributes.form_fields.data.forEach((innerItem: FormFieldDataObject) => {
        innerItem.attributes["isValid"] = false;
      });
      return item
    });
    let isMultiFormTrueValidation = isMultipleFormTrue.map((item: ModalDataObject) => {
      item.attributes.form_fields.data.forEach((innerAppModalItem: FormFieldDataObject) => {
        innerAppModalItem.attributes["isValid"] = false;
      });
      return item
    });
        this.setState({ sortedFormSection: isMultiFormFalseValidation, formFilteredData: isMultiFormTrueValidation, tempSubmitModal: this.deepCopy(isMultiFormTrueValidation) });
  }

  deepCopy =<T, > (obj: T)=> JSON.parse(JSON.stringify(obj)) as T;

  handleModalClose = () => {
    let localData: string | null = localStorage.getItem("modalCurrentData");
  
        if (localData !== null) {
      try {
        let modal = JSON.parse(localData);
        let data = this.state.sortedFormSection;
        let changeIndex = this.state.editIndex;

        if (changeIndex) {
          data[changeIndex] = modal[0];
        }

        this.setState(
          {
            formFilteredData: this.deepCopy(modal),
          },
          () => {
            this.setState(
              {
                applicanformModalVisible: false,
                sortedFormSection: data
              },
              () => {
                localStorage.removeItem("modalCurrentData");
              }
            );
          }
        );
      } catch (error) {
      }
    }
  }

  applicantModalShow = (index: number) => {
    this.setState({
      applicanformModalVisible: true,
      applicantBeforeIndex: index,
      formFilteredData: this.deepCopy(this.state.tempSubmitModal)
    }, () => {
      let modal = JSON.stringify(this.state.formFilteredData);
      localStorage.setItem("modalCurrentData", modal);
    });
  };

  handleEdit = (item: ModalDataObject, index: number) => {
    this.setState({
      applicanformModalVisible: true
    }, () => {
      let data = [item];
      let modal = JSON.stringify(data);
      localStorage.setItem("modalCurrentData", modal);
      this.setState({ formFilteredData: this.deepCopy(data), editIndex: index });
    });
  }

  handleSaveEditModalData = () => {
        let data = this.state.sortedFormSection;
    let changeObject = this.state.formFilteredData;
    let changeIndex = this.state.editIndex;
    data[changeIndex] = changeObject[0];
    this.setState({ sortedFormSection: data }, () => {
      this.setState({ applicanformModalVisible: false });
    })
  }

  handleDelete = (index: number) => {
        let data = this.state.sortedFormSection;
    data.splice(index, 1);
    this.setState({ sortedFormSection: data }, () => {
      this.setState({ applicanformModalVisible: false,isDisabled:false });
    });
  }

  handleChange = (inputField: React.ChangeEvent<{name?: string;value: string}>| {target:{value:string}}, mainIndex: number, fieldIndex: number) => {

    const {value} = inputField.target
    let currentData = this.state.sortedFormSection;

    let mainIndexData = currentData[mainIndex];

    if ( mainIndexData && mainIndexData.attributes.form_fields.data[fieldIndex]) {
      let indexData: AttributesType = mainIndexData.attributes.form_fields.data[fieldIndex].attributes;
      indexData["field_value"] = value;
      indexData["isValid"] = false;
      this.handleMobileNumber(indexData, value);
      this.handleEmailAddress(indexData, value);
      this.validateField(indexData, value);
      this.setState({ sortedFormSection: currentData });
    }
  };

  validateField = (indexData: AttributesType, value: string) => {
    let pincodeRegex = configJSON.pincodeChangeRegex.test(value);
    let numberCheck = configJSON.numberRegex.test(value);
    let emailCheck = configJSON.emailCheck.test(value);
    let emptySpace = configJSON.emptySpace.test(value);

    if (indexData.field_type === 'pincode') {
      indexData["isValid"] = !pincodeRegex;
    }
    if (indexData.field_type === 'number') {
      indexData["isValid"] = !numberCheck;
    }

    if (indexData.field_type === 'email') {
      indexData["isValid"] = !emailCheck;
    }

    if (indexData.field_type === 'text' || indexData.field_type === 'string' ) {
      indexData["isValid"] = emptySpace;
    }
  }

  handleMobileNumber = (indexData: AttributesType, value: string) => {
    let isValidMobileNumber = configJSON.mobileNumberRegex.test(value);
    let isValidTelephoneNumber = configJSON.telephoneNumberRegex.test(value);
    if (indexData.field_type === "mobile_number") {
      indexData["isValid"] = !isValidMobileNumber;
    } else if (indexData.field_type === "telephone_number") {
      indexData["isValid"] = !isValidTelephoneNumber;
    }
  }

  updateSortedFormSec = async(sortedFormSection:ModalDataObject[], inventorsIndex:number, applicantDetails:ModalDataObject,isAdd:boolean) => 
  new Promise<ModalDataObject[]>((resolve) => {
    if(isAdd){
      sortedFormSection.splice(inventorsIndex + 1, 0, applicantDetails)
      resolve(sortedFormSection)
    }
    else{
    sortedFormSection.splice(inventorsIndex - 1, 0, applicantDetails)
    this.handleDelete(inventorsIndex - 1)
    resolve(sortedFormSection)
    }
  })
  
  updateSortedFormSec1 = async(sortedFormSection:ModalDataObject[]) => 
  new Promise<void>(() => {
    return this.setState({
      sortedFormSection: sortedFormSection,
      isDisabledInventor: true,
      isDisabled:false
    })
  })
  
  handleEmailAddress = (indexData: AttributesType, value: string) => {
    let isValidEmailAddress = configJSON.emailRegex.test(value);
    if (indexData.field_type === "email") {
      indexData["isValid"] = !isValidEmailAddress;
    } 
  }

  applicantCopyToInventors = async(isChecked: boolean) => {
    let sortedFormSection: ModalDataObject[] = JSON.parse(JSON.stringify(this.state.sortedFormSection))
    let inventorsIndex = sortedFormSection.findIndex(_ => _?.attributes.form_fields.data.find(_item => _item.attributes.same_as_applicant === true)?.attributes.same_as_applicant === true)
    let applicantDetails = sortedFormSection[inventorsIndex - 1]
    let isApplicatDetails = applicantDetails?.attributes.form_fields.data.filter((fields) => fields.attributes.field_name === "Application No.")

    if (isChecked && isApplicatDetails?.length !== 0) {
      await this.updateSortedFormSec(sortedFormSection, inventorsIndex, applicantDetails,true).then(async()=>{
        await this.updateSortedFormSec1(sortedFormSection)
      })
    }
    else if (isApplicatDetails?.length === 0) {
      return
    } else {
      let inventorsIndex = sortedFormSection.findIndex(_ => _?.attributes.form_fields.data.find(_item => _item.attributes.same_as_applicant === true)?.attributes.same_as_applicant === true)
      let applicantDetails = sortedFormSection[inventorsIndex + 1]
      let isApplicatDetails = applicantDetails?.attributes.form_fields.data.filter((fields) => fields.attributes.field_name === "Application No.")
      if (!isChecked && isApplicatDetails?.length !== 0) {
        
        this.handleDelete(inventorsIndex + 1)
        this.setState({ isDisabledInventor: false })
      }
    }
  }

  handleModalChange = (event:  React.ChangeEvent<{name?: string ;value: string }>, mainIndex: number, fieldIndex: number) => {
    const value = event?.target.value;
    let currentModalData = this.state.formFilteredData;
    let mainIndexModalData = currentModalData[mainIndex];

    if (mainIndexModalData && mainIndexModalData.attributes.form_fields.data[fieldIndex]) {
      let indexData: AttributesType = mainIndexModalData.attributes.form_fields.data[fieldIndex].attributes;
      indexData["field_value"] = value;
      indexData["isValid"] = false;
      this.handleMobileNumber(indexData, value);
      this.validateField(indexData, value);
      this.setState({ formFilteredData: currentModalData });
    }
  };

  handleCheckBoxChange = (inputField: { target: { checked: boolean } }, mainID: string, fieldIndex: number, formID: string) => {    
    const { checked } = inputField.target;
        const updateFormSection = this.state.sortedFormSection.map((item, index) => {
      if (item?.id === formID) {
        item?.attributes.form_fields.data.map((fieldItem) => {
          if (fieldItem.id === mainID) {
            fieldItem.attributes.field_value = checked;
           
            if(item.attributes.form_fields.data.length <= 1  ){
                  this.applicantCopyToInventors(checked)
          }
          }
          else return fieldItem;
        });
      }
      return item
    })

    this.setState({
      sortedFormSection: updateFormSection,
    }
    )
  };

  transformBodyData = () => {
    let { sortedFormSection } = this.state;
    let formSectionsAttributes = sortedFormSection.map((form: ModalDataObject) => {
      return {
        name: form.attributes.name,
        position: form.attributes.position,
        form_fields_attributes: form.attributes.form_fields.data.map((field: FieldAttributes) => ({
          position: field.attributes.position,
          field_name: field.attributes.field_name,
          field_type: field.attributes.field_type,
          field_value: field.attributes.field_value,
          required: field.attributes.required
        })),
      };
    });

    return {
      form_submission: {
        form_sections_attributes: formSectionsAttributes
      }
    };
  }

  handleModalSubmit = () => {
    let isMultipleFormtrue = this.state.formFilteredData;
    let isMultiFormModalValidation = isMultipleFormtrue.map((item: ModalDataObject) => {
      item.attributes.form_fields.data.forEach((innerModalItem: FormFieldDataObject) => {
        if (innerModalItem.attributes.required === true && innerModalItem.attributes.field_value === null || "") {
          innerModalItem.attributes["isValid"] = true;
        }
      });
      return item
    });
    this.setState({ formFilteredData: isMultiFormModalValidation });

    let allFieldsFilled = true;
    isMultipleFormtrue.forEach((item: ModalDataObject) => {
      item.attributes.form_fields.data.forEach((innerModalItem: FormFieldDataObject) => {
        if (innerModalItem.attributes.required === true && (innerModalItem.attributes.field_value === null || innerModalItem.attributes.field_value === "" || configJSON.emptySpace.test(innerModalItem.attributes.field_value) )) {
          innerModalItem.attributes["isValid"] = true;
          allFieldsFilled = false;
        }
      });
    });
    if (allFieldsFilled) {
      let sortedFormModal = this.state.sortedFormSection;
      let addedInventorItem = isMultiFormModalValidation[0]
      let applicantCopyData = this.state.applicantBeforeIndex + 1;
      sortedFormModal.splice(applicantCopyData, 0, addedInventorItem)
      this.setState({ sortedFormSection: sortedFormModal }, () => {
      return this.setState({ isDisabled:true, applicanformModalVisible: false, formFilteredData: this.deepCopy(this.state.tempSubmitModal) });
      });
    }
  }

  handleSubmit = async (form_type: string) => {
    this.setState({ formType: form_type })
    let isMultipleFormFalse = this.state.sortedFormSection;
    if (form_type === "submit") {
      this.setState({ uploadAllForm: true})
      let isMultiFormFalseValidation = isMultipleFormFalse.map((item: ModalDataObject) => {
        item?.attributes.form_fields.data.forEach((innerItem: FormFieldDataObject) => {
          if (innerItem.attributes.required && !innerItem.attributes.field_value  || innerItem.attributes.field_value==="") {
            innerItem.attributes["isValid"] = true;
          } else {
            innerItem.attributes["isValid"] = false;
          }
        });
        return item
      });

      const tempArr = isMultiFormFalseValidation?.map((item: ModalDataObject) => {
        return item?.attributes.form_fields.data.filter((innerItem: FormFieldDataObject) => {
          return innerItem.attributes.isValid === true
        });
      });

      const tempArr2 = tempArr?.filter(item => item.length)
      if (tempArr2?.length === 0) {
        this.setState({ sortedFormSection: isMultiFormFalseValidation, uploadAllForm: true });
        let transformedDataBody = this.transformBodyData();
        this.submitFormApiCallId = await this.multipageFormApiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.submitFormPutMethod,
          endPoint: `${configJSON.formSubmitAPIEndPoint}/${this.state.formIds[0]}/${configJSON.formSubmitAPIEndPointPart + form_type}`,
          body: transformedDataBody
        });
      }
    }else {
      let transformedDataBody = this.transformBodyData();
      this.submitFormApiCallId = await this.multipageFormApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.submitFormPutMethod,
        endPoint: `${configJSON.formSubmitAPIEndPoint}/${this.state.formIds[0]}/${configJSON.formSubmitAPIEndPointPart+form_type}`,
        body: transformedDataBody
      });
    }
  }

  submitFormApiSucessCallBack = (formFillingResponse: FormSubmitSuccess) => {
    this.setState({
      formSubmitSuccssMessage: formFillingResponse.data.message,
      formReferenceId: formFillingResponse.data.reference_number
    }, () => {
       this.setState({ openSavedModal: true, formSubmitError : {} as FormSubmitFailure });
    });
  }

  submitFormApiFailureCallBack = (formFillingResponse: FormSubmitFailure) => {
       this.setState({
    formSubmitError: formFillingResponse,
   });
  }

  successFormModalClose = () => {
    this.setState({ openSavedModal: false });
    this.props.handleToggle(this.state.formType)
  }

  handleFailedModalClose = () => {
    this.setState({
      openFailedModal: false
    });
  };

  handleResetForm = () => {
    let removeFields = (section: ModalDataObject, mIndex:number) => {
      let sec = { ...section }
      sec.attributes.form_fields.data.map((fields, index) => {
        fields.attributes.field_value = null
        this.handleChange({target:{
          value:""
        }}, mIndex, index)
        return fields
      })
      return sec
    }

    this.formFillingGetApi()
    let formArr = this.state.sortedFormSection.map((section, mIndex) => removeFields(section, mIndex))
    this.setState({ uploadAllForm : false,sortedFormSection: formArr, isDisabledInventor: false, isDisabled: false, formSubmitError: {} as FormSubmitFailure }, () => {
      this.forceUpdate();
    })
  }

  handleClassnames =(helperTextForm?: string | false) =>{
   return helperTextForm ? 'number-field-for-applicants-error' : `number-field-for-applicants-default`
  }
  handleConditionalColors = (helperText?:string | false  ) => {
    return helperText ? "#FF0000" :"#979797"
  }
  
  handleInput = (event: React.FormEvent<HTMLTextAreaElement | HTMLInputElement>, regex?: RegExp) => {
    let { value } = event.currentTarget
    if (regex) {
      const validate = regex.test(value)
      if (validate) {
        return value;
      }
      event.currentTarget.value = ""; 
    }
}

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const isLetter = configJSON.checkKeys.test(event.key);
    if (!isLetter) {
      event.preventDefault();
    }
  }
  handleValidationOf = (mainIndex: number) => {
   const newArr =this.state.uploadAllForm && this.state.sortedFormSection[mainIndex]?.attributes.form_fields.data.map((field)=>field.attributes.required && field.attributes.field_value===false || field.attributes.field_value===null)
   const falseValues = newArr && newArr.filter((value) => value === false); 
   return falseValues && falseValues.length > 0 ? false : true
  }
  // Customizable Area End

}
