import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModalOpen: any;
  modalType: string;
  currentFontSize: string;
  navData: any;
  footerNavData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailAccountResistrationFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isModalOpen: false,
      modalType: "verified",
      currentFontSize: "16px",
      navData: [
        {
          link: "Home",
          path: "/"
        },
        {
          link: "FAQs",
          path: "/faqs"
        },
        {
          link: "Feedback",
          path: "/feedback"
        },
        {
          link: "Sign Up",
          path: "/signUp"
        }
      ],
      footerNavData: [
        {
          name: "Terms and Conditions",
          link: "/terms-conditions"
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy"
        },
        {
          name: "Copyright",
          link: ""
        },
        {
          name: "Hyperlinking Policy",
          link: ""
        },
        {
          name: "Accessibility",
          link: ""
        },
        {
          name: "Archive",
          link: ""
        },
        {
          name: "Contact Us",
          link: "/contact-us"
        },
        {
          name: "Sitemap",
          link: ""
        }
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start
  fontSizeHandler = (size: string) => {
    const root: any = document.documentElement;
    root.style.setProperty("--base-font-size", size);
    this.setState({
      currentFontSize: size
    });
    return void 0;
  };
  // Customizable Area End
}
