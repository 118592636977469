import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";


interface LoginValues {
    username: string;
    password: string;
}
interface LoginValues {
    username: string;
    password: string;
}

interface InvalidResponseType {
    errors: Array<{ [key: string]: string }>;
}

interface ValidResponseType {
    meta : {
        token:string
    }
}

interface ApiPayLoadType {
    contentType: string;
    method: string;
    body?: object;
    endPoint: string;
    type?: string;
}

interface NavItem {
    link: string;
    path: string;
}

interface FooterNavItem {
    name: string;
    link: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentFontSize: string;
    navData: Array<NavItem>;
    footerNavData: Array<FooterNavItem>;
    username: string;
    password: string;
    errorAlert: boolean;
    errorAlertMsg: string;
    generatedCaptcha: string;
    isCaptchaValidMessage: string;
    isCaptchaValidCheck:boolean;
    captchaValue: any;
    loginToken: boolean
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailLoginController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    loginApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.NavigationMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            errorAlert: false,
            errorAlertMsg: "",
            currentFontSize: "16px",
            generatedCaptcha: '',
            isCaptchaValidMessage:'',
            isCaptchaValidCheck: false,
            captchaValue: '',
            loginToken: false,
            username: "",
            password: "",
            navData: [{
                link: "Home",
                path: "/",
            },
            {
                link: "FAQs",
                path: "/faqs",
            },
            {
                link: "Feedback",
                path: "/feedback",
            },
            {
                link: "Sign Up",
                path: "/signUp",
            },],
            footerNavData: [{
                name: "Terms and Conditions",
                link: "/terms-conditions"
            },
            {
                name: "Privacy Policy",
                link: "/privacy-policy"
            },
            {
                name: "Copyright",
                link: "",
            },
            {
                name: "Hyperlinking Policy",
                link: "",
            },
            {
                name: "Accessibility",
                link: "",
            },
            {
                name: "Archive",
                link: "",
            },
            {
                name: "Contact Us",
                link: "/contact-us"
            },
            {
                name: "Sitemap",
                link: "",
            },]
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJson && !responseJson.errors) 
            {
                this.responseSucessCall(apiRequestCallId, responseJson);
            } 
            else if (responseJson && responseJson.errors) 
            {
                this.responseFailureCall(apiRequestCallId, responseJson);
            }
        }
    }

    responseSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.loginApiCallId) {
            this.loginSucessCallBack(responseJson);
        }
    };

    responseFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.loginApiCallId) {
            this.loginFailureCallBack(responseJson);
        }
    };

    loginSucessCallBack = async(responseJson: ValidResponseType) => {
        this.dashboardNavigate(responseJson)
        const SigninMessage: Message = new Message(getName(MessageEnum.NavigationMessage))
        SigninMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
      'Dashboard'
    );
    SigninMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    runEngine.sendMessage(SigninMessage.id, SigninMessage)
    };
    dashboardNavigate= async(responseJson:ValidResponseType)=>{
        await StorageProvider.set("TOKEN", responseJson?.meta.token);
    }
  
    loginFailureCallBack = (responseJson: InvalidResponseType) => {
        this.setState({ errorAlert: true, errorAlertMsg: responseJson?.errors[0]?.failed_login })
    };
    // Customizable Area End


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.setState({generatedCaptcha: this.generateCaptcha(5)})
        const token = await StorageProvider.get("TOKEN");
        if (token) {
            const SigninMessage = new Message(getName(MessageEnum.NavigationMessage));
            SigninMessage.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'Dashboard'
            );
            SigninMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            runEngine.sendMessage(SigninMessage.id, SigninMessage);
        }
    }    

    loginApi = async (values: LoginValues) => {
        if(this.state.captchaValue?.length === 0){
            this.setState({
                isCaptchaValidCheck: true,
                isCaptchaValidMessage: 'Captcha is required'
            })
            return true
        }
        if(this.state.captchaValue!== this.state.generatedCaptcha){
            this.setState({
                isCaptchaValidCheck: true,
                isCaptchaValidMessage: 'Captcha does not match'
            })
            return true
        }

        const body = {
            data: {
                type: "email_account",
                email: this.state.username,
                password: this.state.password
            }
        };

        if(this.state.generatedCaptcha === this.state.captchaValue){
            this.setState({
                isCaptchaValidCheck: false,
                isCaptchaValidMessage: ''
            })
            this.loginApiCallId = await this.loginApiCall({
                contentType: configJSON.loginContentType,
                method: configJSON.loginMethodType,
                endPoint: configJSON.loginEndPoint,
                body: body
            });

        }

    };

    onUsernameChange = (event: { target: { value: string } }) => {
        this.setState({ username: event.target.value }, () => {
        });
    };

    onPasswordChange = (event: { target: { value: string } }) => {
        this.setState({ password: event.target.value }, () => {
        });
    };

    oncloseAlert = () => {
        this.setState({ errorAlert: false });
    };

    generateCaptcha(length:number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    handleCaptchaChange = (event:React.ChangeEvent<HTMLInputElement>)=>{
        this.setState({captchaValue: event.target.value})
        if(event.target.value !== this.state.generatedCaptcha){
            this.setState({
                isCaptchaValidCheck: true,
                isCaptchaValidMessage: 'Captcha does not match'
            })
        }else if(event.target.value?.length<=0){
            this.setState({
                isCaptchaValidCheck: true,
                isCaptchaValidMessage: 'Captcha is required'
            })
        }else if(event.target.value === this.state.generatedCaptcha){
            this.setState({
                isCaptchaValidCheck: false,
                isCaptchaValidMessage: ''
            })
        }
    }

    refreshCaptcha = ()=>{
        this.setState({generatedCaptcha: this.generateCaptcha(5)})
    }

    fontSizeHandler = (size: string): void => {
        const root: HTMLElement | null = document.documentElement;
        if (root) {
            root.style.setProperty("--base-font-size", size)
            this.setState({
                currentFontSize: size,
            });
        }
    };

    loginApiCall = async (data: ApiPayLoadType) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = { "Content-Type": contentType };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        body && type !== "formData" ?
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)) :
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;

    };
    // Customizable Area End
}