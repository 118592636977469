// App.tsx - WEB *//
import React, { Component } from 'react';
import firebase from 'firebase'
import HomeScreen from '../../components/src/HomeScreen';
import "./App.css";
import 'antd/dist/antd.css';
import { View } from "react-native";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import SignUp from '../../blocks/email-account-registration/src/EmailAccountRegistration.web'
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from '../../blocks/forgot-password/src/ResetPassword.web';
import HelpCenterFAQ from "../../blocks/helpcentre/src/HelpCenterFAQ.web";
import DigitalSignature from "../../blocks/forgot-password/src/DigitalSignature.web";
import LandingPage from '../../blocks/landingpage/src/LandingPage.web';
import EmailLogin from '../../blocks/email-account-login/src/EmailLogin.web';
import ChangePassword from '../../blocks/forgot-password/src/ChangePassword.web'
import ContactUs from '../../blocks/contactus/src/Contactus.web';
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import { DiscardedFormComponent } from "../../blocks/dashboard/src/DiscardedForm.web"
import MultipageForms from '../../blocks/multipageforms/src/MultipageForms.web';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions.web'
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web'
import EmailAccountRegistrationForm from '../../blocks/email-account-registration/src/EmailAccountResistrationForm.web';
if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}


const routeMap = {

  LandingPage: {
    component: LandingPage,
    path:"/",
    exact:true
  },

  SignUp: {
    component: SignUp,
    path: "/signUp"
  },

  EmailLogin: {
    component: EmailLogin,
    path: "/login"
  },

  Dashboard: {
    component: Dashboard,
    path: "/dashboard"
  },

  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password"
  },

  ResetPassword: {
    component: ResetPassword,
    path: "/reset-password"
  },

  DigitalSignature: {
    component: DigitalSignature,
    path: "/digitalsignature"
  },

  ChangePassword: {
    component: ChangePassword,
    path: "/change-password"
  },

  TermsConditions: {
    component: TermsConditions,
    path: "/terms-conditions"
  },

  HelpCenterFAQ: {
    component: HelpCenterFAQ,
    path: "/faqs"
  },

  ContactUs: {
    component: ContactUs,
    path: "/contact-us"
  },

  DiscardedFormComponent: {
    component: DiscardedFormComponent,
    path: "/discardedforms"
  },

  MultipageForms: {
    component: MultipageForms,
    path: "/multipage-forms"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/user-profile"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/privacy-policy"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <>
        <View>
          {WebRoutesGenerator({ routeMap })}
        </View>
      </>
    );
  }
}

export default App;