import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "framework/src/Message";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    currentFontSize: string;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLogin: boolean;
    currentFontSize: string;
    navData: any;
    footerNavData: any;
    bannerData: any;
    isLoading: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    upCommingEventsAPICallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            showLogin: false,
            currentFontSize: "16px",
            navData: [
                { link: "Home", path: "/", },
                { link: "FAQs", path: "/faqs", },
                { link: "Feedback", path: "/feedback", },
                { link: "Sign Up", path: "/signUp", }
            ],
            footerNavData: [
                { name: "Terms and Conditions", link: "/terms-conditions" },
                { name: "Privacy Policy", link: "/privacy-policy" },
                { name: "Copyright", link: "", },
                { name: "Hyperlinking Policy", link: "", },
                { name: "Accessibility", link: "", },
                { name: "Archive", link: "", },
                { name: "Contact Us", link: "/contact-us", },
                { name: "Sitemap", link: "", }
            ],
            bannerData: [],
            isLoading: true
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getBannerApi();
    }
    classHandler = () => {
        switch (this.state.currentFontSize) {
            case "18px":
                return "home home20"
            case "14px":
                return "home home12"
            default:
                return "home"
        }
    }

    fontSizeHandler = (size: string) => {
        const root: any = document.documentElement;
        root.style.setProperty("--base-font-size", size);
        this.setState({
            currentFontSize: size
        })
        return void 0;
    };

    bannerApiCall = async (data: any) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseBannerSucessCall(apiRequestCallId, responseJson);
            }

        }
    }

    responseBannerSucessCall = async (apiRequestCallId: any, responseJson: any) => {
        if (apiRequestCallId === this.upCommingEventsAPICallId) {
            this.bannerApiSucessCallBack(responseJson);
        }
    };


    getBannerApi = async () => {
        this.upCommingEventsAPICallId = await this.bannerApiCall({
            contentType: configJSON.bannerContentType,
            method: configJSON.bannerApimethod,
            endPoint: configJSON.banenrEndPoint
        });
    };

    bannerApiSucessCallBack = (res: any) => {
        this.setState({ bannerData: res.data, isLoading: false })
    };

    // Customizable Area End
}