import React, { Component } from "react";
// Customizable Area Start
import * as IMG_CONST from './assets'
import { Modal } from 'antd'
type MyProps = {  id: string; modalType: string; modalCloseHandler: () => void; isModalOpen: boolean }
// Customizable Area End
class AppModal extends Component<MyProps> {
    // Customizable Area Start
    renderModalBody = () => {
        let UI;
        switch (this.props.modalType) {
            case "verified":
                UI = (
                    <>
                        <img src={IMG_CONST.successImg} alt="success" className="modal-type-img" />
                        <p className="modal-msg">Your E-Sign Verified by emudra</p>
                        <div className="modal-btn-container">
                            <button
                                data-test-id="modalBackButton"
                                className="modal-back-btn"
                                onClick={this.props.modalCloseHandler}
                            >
                                back
                            </button>
                            <button
                                className="modal-done-btn"
                                onClick={this.props.modalCloseHandler}
                            >
                                done
                            </button>
                        </div>
                    </>
                );
                break;
            case "failed":
                UI = (
                    <>
                        <img src={IMG_CONST.failedImg} alt="failed" className="modal-type-img" />
                        <p className="modal-msg">
                            Your E-Sign Verification failed by emudra
                        </p>
                        <div className="modal-btn-container">
                            <button
                                className="modal-back-btn"
                                onClick={this.props.modalCloseHandler}
                            >
                                back
                            </button>
                        </div>
                    </>
                );
                break;
            case "success":
                UI = (
                    <div className="innerModalBox">
                      <img src={IMG_CONST.successImg} alt="success" className="modal-type-img" />
                      <p className="modal-msg">Account has been created successfully</p>
                      <div className="modal-btn-container">
                        <button
                          className="modal-done-btn"
                          onClick={this.props.modalCloseHandler}
                        >
                          done
                        </button>
                      </div>
                    </div>
                  );
                break;
            default:
                UI = <p>No data found </p>;
                break;
        }
        return UI;
    };

render() {
    return (
        <>
            <Modal
                open={this.props.isModalOpen}
                onCancel={this.props.modalCloseHandler}
                closable={false}
                footer={null}
                centered={true}
                destroyOnClose={true}
                className="app-modal"
                width={620}
            >
                <div>{this.renderModalBody()}</div>
            </Modal>
        </>
    );
}

// Customizable Area End
}
// Customizable Area Start
export default AppModal
// Customizable Area End