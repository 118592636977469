Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";
exports.helpcentreAPIEndPoint = "bx_block_help_centre/question_type";
exports.helpcentreSearchAPIEndPoint = "bx_block_help_centre/question_type?query=";
exports.httpGetType = "GET";
exports.btnExampleTitle = "CLICK ME";
exports.faqTitle = "Frequently Asked Questions (FAQs)";
exports.faqErrortext = "We're sorry. we were not able to find a match";

// Customizable Area End
