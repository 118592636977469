import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import apiCall from "../../../components/src/ApiCall.web";
import { RightPass, WrongPass, BlankField } from "./assets";
import { Color } from "@material-ui/lab/Alert";
import StorageProvider from "../../../framework/src/StorageProvider";

import JWT from "jsonwebtoken";

interface ResetPassword {
    data: {
        attributes: {
            activated: boolean;
            address: string;
            district: string;
            email: string;
            full_phone_number: number
            gender: string;
            mobile_number: number;
            phone_number: number;
            pincode: number;
            state: string;
            user_id: string;
            user_name: string;
        }
    }
}

interface ResetFailure {
    errors: Array<ErrorObject>;
}

interface ErrorObject {
    otp?: string;
    token?: string;
}

interface NavItem {
    link: string;
    path: string;
}

interface FooterNavItem {
    name: string;
    link: string;
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    id: string;
    history: any;
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeBtn: boolean;
    showSuccessMesage: ResetPassword;
    email: string;
    userId: string;
    password: string;
    retypePassword: string;
    validate: boolean;
    isLowerCase: string;
    isUpperCase: string;
    isDigit: string;
    isCharacter: string;
    resetSuccessModal: boolean;
    isLoading: boolean;
    specialCharacter: string;
    navData: NavItem[];
    currentFontSize: string;
    footerNavData: FooterNavItem[];
    isAlert: boolean;
    isResetActive: boolean;
    alertMsg: string;
    alertType: Color;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class ResetPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    resetPasswordApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.CountryCodeMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            activeBtn: true,
            showSuccessMesage: {} as ResetPassword,
            email: "",
            userId: "",
            password: "",
            retypePassword: "",
            validate: false,
            isLowerCase: "inValid",
            isUpperCase: "inValid",
            isDigit: "inValid",
            isCharacter: "inValid",
            resetSuccessModal: false,
            isLoading: false,
            specialCharacter: "inValid",
            footerNavData: 
            [
                {
                    name: "Terms and Conditions",
                    link: "/terms-conditions"
                },
                {
                    name: "Privacy Policy",
                    link: "/privacy-policy"
                },
                {
                    name: "Copyright",
                    link: ""
                },
                {
                    name: "Hyperlinking Policy",
                    link: ""
                },
                {
                    name: "Accessibility",
                    link: ""
                },
                {
                    name: "Archive",
                    link: ""
                },
                {
                    name: "Contact Us",
                    link: ""
                },
                {
                    name: "Sitemap",
                    link: ""
                }
            ],
            navData:
                [
                    { link: "Home", path: "/" },
                    { link: "FAQs", path: "/faqs" },
                    { link: "Feedback", path: "/feedback" },
                    { link: "Sign Up", path: "/signUp" }
                ],
            alertMsg: "",
            currentFontSize: "16px",
            isAlert: false,
            isResetActive: false,
            alertType: "success"
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }


    // Customizable Area Start


    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) == message.id) {
            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)

            )
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            )

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            )

            if (responseJson && !responseJson.errors) {
                await this.passwordRestSucessCall(apiRequestCallId, responseJson);
            }
            if (responseJson && responseJson.errors) {
                await this.passwordRestErrorsCell(apiRequestCallId, responseJson)
            } else if (errorReponse) { this.setState({ isLoading: false }) }
        }
    }

    async componentDidMount() {
        this.checkTokenExpiration()
    }

    checkTokenExpiration = ()=>{
        const TOKEN:any = localStorage.getItem("TOKEN");

        const tokenInfo:any = JWT.decode(TOKEN)
        console.log('tokenInfo',tokenInfo);
        if (tokenInfo?.exp < (new Date().getTime() + 1) / 1000 || TOKEN === null) {
            this.setState({isResetActive: true})
        }
        this.setState({isResetActive: false})
    }

    passwordRestSucessCall = async (apiRequestCallId: string, responseJson: ResetPassword) => {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
            await this.resetPassowrdSuccessCallBack(responseJson);
        }
    }

    passwordRestErrorsCell = async (apiRequestCallId: string, responseJson: ResetFailure) => {
        if (apiRequestCallId === this.resetPasswordApiCallId) {
            await this.resetPassowrdFailureCallBack(responseJson);
        }
    }

    handleResetPassword = async () => {
        let Token = await StorageProvider.get("TOKEN");
        let resetBody = {
            data: {
                attributes: {
                    token: Token,
                    new_password: this.state.password
                }
            }

        }
        this.resetPasswordApiCallId = apiCall({
            contentType: configJSON.forgotPasswordAPiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.resetPasswordEndPoint,
            body: resetBody
        });
    }
   
    resetPassowrdSuccessCallBack = async (responseJson: ResetPassword) => {
        this.setState({ isLoading: false, resetSuccessModal: true, showSuccessMesage: responseJson });
    }

    resetPassowrdFailureCallBack = async (responseJson: ResetFailure) => {
        this.setState({ isLoading: false });
        if(responseJson.errors[0]?.token){
          alert(responseJson.errors[0]?.token);
        } else if (responseJson.errors[0].otp){
            alert(responseJson.errors[0].otp);
        }
    }

    onFinish = (values: any) => {
      console.log('values',values);
      if(this.state.password === this.state.retypePassword){
        this.handleResetPassword()
      }else if(!this.state.retypePassword){
        alert('Please Enter Repeat Password')
      }
    }

    createPassword = (e: { target: { value: string } }) => {
        this.setState({ password: e.target?.value, validate: true }, () => {
            this.validationCondition()
        })
    }

    confirmPassword = (e: { target: { value: string } }) => {
        this.setState({ retypePassword: e.target.value })
    }

    passwordFeild = ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error("Passwords does not match!")
            );
        },
    })

    passwordFeildCheck = ({ getFieldValue }: any)=>({
        validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error("New Password is required!")
            );
        },
    })

    validationCondition = async () => {
        let strings: string | number = this.state.password;
        let specialCharacterRegex = configJSON.specialCharacterRegex;
        this.setState({ isCharacter: "inValid", isLowerCase: "inValid", isUpperCase: "inValid", isDigit: "inValid", specialCharacter: "inValid" });
        if (this.state.password === "") {
            return this.setState({ isCharacter: "valid", isLowerCase: "valid", isUpperCase: "valid", isDigit: "valid", specialCharacter: "valid" });
        }
        for (let char of strings) {
            if (char === char.toUpperCase() && char !== char.toLowerCase()) {
                this.setState({ isUpperCase: "valid" });
            }
            if (char === char.toLowerCase() && char !== char.toUpperCase()) {
                this.setState({ isLowerCase: "valid" });
            }
            if (!isNaN(parseInt(char))) {
                this.setState({ isDigit: "valid" });
            }
            if (strings.length >= 8 && strings.length <= 15) {
                this.setState({ isCharacter: "valid" });
            }
            if (specialCharacterRegex.test(char)) {
                this.setState({ specialCharacter: "valid" });
            }
        }
    }

    resetSuccessMessageShow = () => {
      this.setState({ resetSuccessModal: false }, ()=>{
        localStorage.removeItem("TOKEN")  
        this.props.navigation?.navigate("LandingPage")
      })
    }

    handleBackFunc = ()=>{
      localStorage.removeItem("TOKEN")
      this.props.navigation?.navigate("LandingPage")
    }

    shouldContainCheck = () => {
        return (
            this.allStateStatusCheck()
                ? "blankState" :
                this.shouldContainValid()
        );
    }

    shouldContainValid = () => {
        return (
            this.allFieldImgStatusCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    atLeastAllCheckCss = () => {
        return (
            this.allStateStatusCheck()
                ? "blankState" :
                this.charValidInValid()
        );
    }

    charValidCheck = () => {
        return (
            this.state.isCharacter === "valid" &&
            this.state.password !== ""
        );
    }

    charValidInValid = () => {
        return (
            this.charValidCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    allStateStatusCheck = () => {
        return (
            this.state.isCharacter === "inValid" &&
            this.state.isLowerCase === "inValid" &&
            this.state.isUpperCase === "inValid" &&
            this.state.isDigit === "inValid" &&
            this.state.specialCharacter === "inValid" &&
            this.state.password === ""
        );
    }

    atLeastAllImgCheck = () => {
        return (
            this.allStateStatusCheck()
                ? BlankField :
                this.lengthValidCheck()
        );
    }

    lengthValidCheck = () => {
        return (
            this.charValidCheck()
                ? RightPass : WrongPass
        );
    }

    allFieldImgStatusCheck = () => {
        return (
            this.state.isLowerCase === "valid" &&
            this.state.isUpperCase === "valid" &&
            this.state.specialCharacter === "valid" &&
            this.state.isDigit === "valid" &&
            this.state.password !== ""
        );
    }

    shouldContainImgCheck = () => {
        return (
            this.allFieldImgStatusCheck()
                ?
                RightPass :
                this.shouldContainValidInvalid()
        );
    }

    shouldContainValidInvalid = () => {
        return (
            this.allStateStatusCheck()
                ? BlankField : WrongPass
        );
    }

    lowerCaseNotEqualCheck = () => {
        return (
            this.state.isLowerCase === "inValid" &&
            this.state.password === ""
        );
    }

    lowerCaseEqualCheck = () => {
        return (
            this.state.isLowerCase === "valid" &&
            this.state.password !== ""
        );
    }

    lowerCaseCheck = () => {
        return (
            this.lowerCaseNotEqualCheck()
                ? "blankState" :
                this.lowerCaseValidInValid()
        );
    }

    lowerCaseValidInValid = () => {
        return (
            this.lowerCaseEqualCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    lowerCaseImgCheck = () => {
        return (
            this.lowerCaseEqualCheck()
                ?
                RightPass :
                this.lowerCaseImgValidInValid()
        );
    }

    lowerCaseImgValidInValid = () => {
        return (
            this.lowerCaseNotEqualCheck()
                ?
                BlankField : WrongPass
        );
    }

    upperCaseEqualCheck = () => {
        return (
            this.state.isUpperCase === "valid" &&
            this.state.password !== ""
        );
    }

    upperCaseInvalidCheck = () => {
        return (
            this.state.isUpperCase === "inValid" &&
            this.state.password == ""
        );
    }

    upperCaseImgCheck = () => {
        return (
            this.upperCaseEqualCheck()
                ?
                RightPass :
                this.upperCaseImgValidInValid()
        );
    }

    upperCaseImgValidInValid = () => {
        return (
            this.upperCaseInvalidCheck()
                ?
                BlankField :
                WrongPass
        );
    }

    upperCaseCheck = () => {
        return (
            this.upperCaseInvalidCheck()
                ? "blankState" :
                this.upperCaseCssValidInValid()
        );
    }

    upperCaseCssValidInValid = () => {
        return (
            this.upperCaseEqualCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    digitNotEqualCheck = () => {
        return (
            this.state.isDigit === "inValid" &&
            this.state.password === ""
        );
    }

    digitEqualCheck = () => {
        return (
            this.state.isDigit === "valid" &&
            this.state.password !== ""
        );
    }

    digitValidCheck = () => {
        return (
            this.digitNotEqualCheck()
                ? "blankState" :
                this.digitCssValidInValid()
        );
    }

    digitCssValidInValid = () => {
        return (
            this.digitEqualCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    digitImgCheck = () => {
        return (
            this.digitEqualCheck()
                ?
                RightPass :
                this.digitImgValidInValid()
        );
    }

    digitImgValidInValid = () => {
        return (
            this.digitNotEqualCheck()
                ?
                BlankField : WrongPass
        );
    }

    specialCharImgCheck = () => {
        return (
            this.specialCharNotEqualCheck()
                ?
                RightPass :
                this.specialCharImgValidInValid()
        );
    }

    specialCharImgValidInValid = () => {
        return (
            this.specialCharEqualCheck()
                ?
                BlankField : WrongPass
        );
    }

    specialCharEqualCheck = () => {
        return (
            this.state.specialCharacter === "inValid" &&
            this.state.password === ""
        );
    }

    specialCharNotEqualCheck = () => {
        return (
            this.state.specialCharacter === "valid" &&
            this.state.password !== ""
        );
    }

    renderValidUnValidaCss = () => {
        return (
            this.specialCharEqualCheck()
                ? "blankState" :
                this.lenghtValidInValid()
        );
    }

    lenghtValidInValid = () => {
        return (
            this.specialCharNotEqualCheck()
                ?
                "validated"
                :
                "notValidated"
        );
    }

    fontSizeHandler = (size: string): void => {
        const root: HTMLElement | null = document.documentElement;
        if (root) {
            root.style.setProperty("--base-font-size", size);
        }
        this.setState({
            currentFontSize: size
        });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
      };

    // Customizable Area End
}
