import { Box, InputAdornment, TextField, styled,TextFieldProps } from "@material-ui/core";
import React from "react";
export const NumberField = (props: TextFieldProps) =>{
    const ref = React.useRef<HTMLInputElement>();
    const { InputProps, ...restProps} = props;
    const upHandler = () =>{
      if(!ref.current) return;
      if(ref.current.value) ref.current.value = `${parseFloat(ref.current.value) + 1}`; 
      else ref.current.value = "1"
      props.onChange && props.onChange({target: {value: ref.current.value}} as any)
     
    }
    const downHandler = () =>{
     
      if(ref?.current?.value === "" || ref?.current?.value === "0" ) return
      if(!ref.current) return;
      if(ref.current.value) ref.current.value = `${parseFloat(ref.current.value) - 1}`; 
      else ref.current.value = "-1";
      props.onChange && props.onChange({target: {value:ref.current.value}} as any)
   
    }
    return <StyledNumberField
      value={props.value}
      inputRef={ref}
      style={props.style}
      className={`number-field-common ${props.className}`}
      data-testid="number-field"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div style={{display:'flex',flexDirection:'column', marginTop:-8,zIndex:2}}>
            <Box sx={{mt:0.8}}>
              <svg data-testid="upArrowTestId" onClick={upHandler} width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:"default"}}>
                <g id="group_vector">
                  <path id="view_vector" d="M17 14L12 9L7 14" stroke="#103B70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </svg>
              </Box>
              <Box data-testid="downArrowTestId" sx={{mt:-0.8}}>
              <svg onClick={downHandler} width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{cursor:"default"}}>
                <g id="group_vector">
                  <path id="view_vector" d="M17 10L12 15L7 10" stroke="#103B70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
              </svg>
              </Box>
            </div>
          </InputAdornment>
        ),
       ...(InputProps || {})
      }}
      {...restProps}
    />
  }

  const StyledNumberField = styled(TextField)({ 
    width: "282px", 
    display: "inline-block",
    position: "relative",
    borderColor:"transparent",
    borderWidth:"0 !important",
    "& input": {
      position: "relative"
    },
    "@media (max-width: 390px)": {
      width: "190px"
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginLeft: "0px",
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px"
    },
    "input": {
      '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
      '-moz-appearance': 'textfield',
      }
    },
  });
