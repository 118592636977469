export const downarrow  = require("../assets/image_arrow-down.png");
export const search  = require("../assets/image_search-normal.png");
export const closeimg  = require("../assets/view_vector (1).png");
export const headerLeft  = require("../assets/headerLeftImg.png");
export const headerRight  = require("../assets/headerRightImg.png");
export const rupeeIcon  = require("../assets/rupee.png");

// Customizable Area Start
export const success  = require("../assets/success.png");
export const HeaderFormBanner = require("../assets/headerFormBanner.png");
// Customizable Area End
