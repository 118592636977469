import React from "react";
// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import UserProfileBasicBlockController, {
  Props
} from "./UserProfileBasicBlockController.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import UserProfileBasicBlockForm from "./UserProfileBasicBlockForm.web";
// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Header
          navigation={undefined}
          id={""}
          navData={this.state.navdata}
          fontSizeHandler={this.fontSizeHandler}
          currentFontSize={this.state.currentFontSize}
          isLogin={false}
          onLogoutClick={undefined}
        />
        <div className="container">
          <div className="signup-container">
            <main className="signup-main">
              <Banner
                title={`welcome ${this.state.user_name}`}
                activeLink="home"
                navigation={undefined}
                id={""}
                activePage="User Profile"
              />
              <UserProfileBasicBlockForm
                navigation={undefined}
                id={""}
                currentFontSize={this.state.currentFontSize}
                isEditableMode={this.state.isEditableMode}
                navdata={this.state.navdata}
                footernavData={this.state.footernavData}
              />
            </main>
          </div>
          <Footer
            navigation={undefined}
            id={""}
            footerNavData={this.state.footernavData}
          />
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
