import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ITermsConds {
  id: string;
  description: string;
  is_accepted: boolean;
  created_at: string;
}
interface NavItem {
  link: string;
  path: string;
}

interface FooterNavItem {
  name: string;
  link: string;
}

interface TermsAndConditions {
    data: {
        id: string;
        type: string;
        attributes: {
            title: string;
            sub_title: string;
            description: string;
        }
    }
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?:string;
}

export interface ValidResponseType {
  message: object;
  data: object;
}


export interface InvalidResponseType {
  errors: Array<string>;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isAdminUser: boolean;
  accountId: number;
  termsConds: ITermsConds | null;
  termsCondsList: ITermsConds[];
  isTermsCondsAccepted: boolean;
  isLoading: boolean;
  navData: NavItem[];
  currentFontSize: string;
  footerNavData: FooterNavItem[];
  termsAndConditionsData: TermsAndConditions;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTermsCondsCallId: string = "";
  getTermsCondsListCallId: string = "";
  getAccountGroupsCallId: string = "";
  setAcceptanceOfTermsCondsId: string = "";
  termAndConditionsAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      termsCondsList: [],
      isAdminUser: true,
      termsConds: null,
      isTermsCondsAccepted: false,
      isLoading: false,
      currentFontSize: "16px",
      navData: [
        { link: "Home", path: "/" },
        { link: "FAQs", path: "/faqs" },
        { link: "Feedback", path: "/feedback" },
        { link: "Sign Up", path: "/signUp" }
      ],
      footerNavData: [
        { name: "Terms and Conditions", link: "/terms-conditions" },
        { name: "Privacy Policy", link: "/privacy-policy" },
        { name: "Copyright", link: "" },
        { name: "Hyperlinking Policy", link: "" },
        { name: "Accessibility", link: "" },
        { name: "Archive", link: "" },
        { name: "Contact Us", link: "/contact-us" },
        { name: "Sitemap", link: "" }
      ],
      termsAndConditionsData: {
        data: {
          id: "",
          type: "",
          attributes: {
              title: "",
              sub_title: "",
              description: ""
          }
      }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getTermsAndConditionsApi();
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  navigateToTermsCondsDetail = (termsCondsId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsDetail"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      termsCondsId: termsCondsId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  navigateToTermsCondsEdit = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TermsConditionsEdit"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), null);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getAccountGroups = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountGroupsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountGroupsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({ isLoading: true });
  };

  getTermsCondsList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTermsConds = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCheckBoxChange = (value: boolean) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const body = {
      id: this.state.termsConds?.id,
      is_accepted: value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.setAcceptanceOfTermsCondsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setTermsCondsAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fontSizeHandler = (size: string) => {
    const root = document.documentElement;
    root.style.setProperty("--base-font-size", size);
    this.setState({
      currentFontSize: size
    });
    return void 0;
  };

  responseTermsAndConditionsSucessCall = async (apiRequestCallId: string, responseJson: TermsAndConditions) => {
    if (apiRequestCallId === this.termAndConditionsAPICallId) {
      this.termsAndConditionsApiSucessCallBack(responseJson);
    }
  };
  
  responseTermsAndConditionsFailureCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.termAndConditionsAPICallId) {
      this.responseTermsAndConditionsFailureCallBack();
    }
  };
  
  responseTermsAndConditionsFailureCallBack = () => {
 alert("API Error")
  };
  
  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson && responseJson.data);
};

inValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
};

  getTermsAndConditionsApi = async () => {
    this.termAndConditionsAPICallId = await this.termsAndConditionsApiCall({
      contentType: configJSON.termAndConditionsContentType,
      method: configJSON.termAndConditionsApimethod,
      endPoint: configJSON.termAndConditionsEndPoint
    });
  };

  termsAndConditionsApiSucessCallBack = (response: TermsAndConditions) => {
    this.setState({ termsAndConditionsData: response })
  };

  termsAndConditionsApiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
        )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage), body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
          return;
      }
      if (this.isValidResponse(responseJson)) {
          this.responseTermsAndConditionsSucessCall(apiRequestCallId, responseJson);
      } 
      if (responseJson && responseJson.error_message) {
        this.responseTermsAndConditionsFailureCall(apiRequestCallId, responseJson);
      }
      else if (errorReponse) {
          notification["error"]({
              message: "Internal Server Error.",
          });
      }
    }
    // Customizable Area End
  }
}
