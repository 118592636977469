
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End
 
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  helpCentreQA: any;
  token: String;
  queue_type: any;
  dataQA: [];
  dataSub: [];
  faq: any,
  faqResponse: [],
  faqErrorresponse: string,
  searchValue: string;
  currentFontSize: string,
  searchState: boolean,
  faqnavData: { [key: string]: string }[],
  footerfaqnavData: { [key: string]: string }[],
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
// Customizable Area End
  getHelpCentreApiCallId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getFaqDataApiCall();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)];
    this.state = {
      helpCentreQA: [],
      token: "",
      queue_type: "",
      dataQA: [],
      dataSub: [],
      faq: [],
      faqResponse: [],
      faqErrorresponse: "",
      searchValue: '',
      searchState: false,
      currentFontSize: "16px",
      faqnavData: [
        {
          link: "Home",
          path: "/"
        },
        {
          link: "FAQs",
          path: "/faqs"
        },
        {
          link: "Feedback",
          path: "/feedback"
        },
        {
          link: "Sign Up",
          path: "/signUp"
        }
      ],
      footerfaqnavData: [
        {
          name: "Terms and Conditions",
          link: "/terms-conditions"
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy"
        },
        {
          name: "Copyright",
          link: ""
        },
        {
          name: "Hyperlinking Policy",
          link: ""
        },
        {
          name: "Accessibility",
          link: ""
        },
        {
          name: "Archive",
          link: ""
        },
        {
          name: "Contact Us",
          link: "/contact-us"
        },
        {
          name: "Sitemap",
          link: ""
        }
      ]
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  // Customizable Area Start
    this.getToken();
    this.getHelpCentreQA()
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      /* istanbul ignore next */
      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.getHelpCentreApiCallId) {
          this.gethelpcenterData(responseJson)
        }
        if (apiRequestCallId === this.getHelpCenterSearchApiCallId) {
          this.gethelpSearchcenterdata(responseJson)
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getFaqData: any;
  searchFAQ: any;
  getHelpCenterSearchApiCallId: string = ""

  gethelpcenterData = (responseJson: { data: []; }) => {
    if (responseJson.data) {
      this.setState({ faqResponse: responseJson.data, searchState: false })
    }
  }
  gethelpSearchcenterdata = (responseJson: {
    errors: { message: string }[];
    data: [];
  }) => {
    if (responseJson.data) {
      this.setState({ faqResponse: responseJson.data, searchState: false })
    } else if (responseJson.errors) {
      const data: string[] = responseJson?.errors.map((val: { message: string }) => val.message)
      this.setState({
        searchState: true,
        faqErrorresponse: data.join()
      })
    }
  }

  getHelpCentreQA = () => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpcentreAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHelpCentreSearchQA = (e: React.ChangeEvent<HTMLInputElement>) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCenterSearchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpcentreSearchAPIEndPoint + `${e.target.value}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  gotoSubScreen(item: any) {
    /* istanbul ignore next */
    const que_type = item?.attributes?.que_type
    /* istanbul ignore next */
    const data = item?.attributes?.question_sub_types?.data

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'HelpCentreSub');

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    const helpcentreSubInfo = {
      que_title: que_type,
      que_array: data
    }

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.HelpCentreMessageData), helpcentreSubInfo);

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg)



  }


  gotoHelpCentreQA(sub_type: any, data: []) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'HelpCentreQA');

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    const helpcentreSubInfo = {
      sub_type: sub_type,
      que_array: data
    }

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.HelpCentreMessageData), helpcentreSubInfo);

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }
  
  async getFaqDataApiCall() {
    this.getFaqData = this.apiCall({
      // contentType: 'multipart/form-data',
      method: 'GET',
      endPoint: 'bx_block_contact_us/helplines',
      //   body: data
    })
  }
  async searchFaqApiCall(val: string) {
    this.searchFAQ = this.apiCall({
      // contentType: 'multipart/form-data',
      method: 'GET',
      endPoint: `bx_block_help_centre/search_que_ans?query=${val}`,
      //   body: data
    })
  }


  apiCall(data: any) {
    const { contentType, method, endPoint, body } = data;
    const userData = JSON.parse(localStorage.getItem('loginData') || '{}');
    const token = userData.token || '';

    const header = {
      "Content-Type": contentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    /* istanbul ignore next */
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
      // body
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  fontSizehandler = (size: string) => {
    const root = document.documentElement;
    root.style.setProperty("--base-font-size", size);
    this.setState({
      currentFontSize: size
    });
  };

  // Customizable Area End
}
