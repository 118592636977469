import React from "react";
// Customizable Area Start
import ChangePasswordController, {
  configJSON,
  Props,
} from "./ChangePasswordController.web";
import {
  Box,
  Button,
  Typography,
  styled,
  InputLabel,
  TextField,
  InputAdornment,
  createTheme,
  ThemeProvider,
  Modal,
  Snackbar,
} from "@material-ui/core";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert/Alert";
// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChangePassWord = () => {
    return (
      <MainBox>
        <MainInnerBox>
          <HeaderMain>{configJSON.changePaswordTxt}</HeaderMain>
          <InnerBox>
            <InnerLeftBox>
              <form>
                <Box>
                  <StyledInputLabel required>
                    {configJSON.currentPaswordTxt}
                  </StyledInputLabel>
                  <StyleTextField
                    data-test-id="currentPasswordChangeTestID"
                    error={this.state.showCurrentPasswordValidation}
                    variant="outlined"
                    helperText={this.state.showCurrentPasswordValidation && this.state.currentPasswordErrorTxt}
                    value={this.state.currentPasswordValue}
                    type={this.state.showCurrentPassword ? "text" : "password"}
                    onChange={this.onChangeCurrentPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PasswordImg
                            src={
                              this.state.showCurrentPassword
                                ? IMG_CONST.imgPasswordInVisible
                                : IMG_CONST.imgPasswordVisible
                            }
                            onClick={() => {
                              this.showCurrentPasswordClick();
                            }}
                            style={webStyles.inputAdornment}
                            data-test-id="togglePwdImg"
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                </Box>
                <Box>
                  <StyledInputLabel required>
                    {configJSON.newPasswordTxt}
                  </StyledInputLabel >
                  <StyleTextField
                    data-test-id="newPasswordChangeTestID"
                    error={this.state.showNewPasswordValidation}
                    variant="outlined"
                    helperText={this.state.showNewPasswordValidation && this.state.newPasswordErrorTxt}
                    type={this.state.showNewPassword ? "text" : "password"}
                    onChange={this.onChangeNewPassword}
                    value={this.state.newPasswordValue}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PasswordImg
                            src={
                              this.state.showNewPassword
                                ? IMG_CONST.imgPasswordInVisible
                                : IMG_CONST.imgPasswordVisible
                            }
                            style={webStyles.inputAdornment}
                            onClick={this.showNewPassword}
                            data-test-id="changeNewPwdTestId"
                          />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                </Box>
                <Box>
                  <StyledInputLabel required>
                    {configJSON.confirmPasswordTxt}
                  </StyledInputLabel>
                  <StyleTextField
                    data-test-id="confirmChangeTestID"
                    error={this.state.showNewConfirmPasswordValidation}
                    variant="outlined"
                    helperText={this.state.showNewConfirmPasswordValidation && this.state.confirmPasswordErrorTxt}
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    value={this.state.confirmPasswordValue}
                    onChange={this.onChangeConfirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PasswordImg
                            src={
                              this.state.showConfirmPassword
                                ? IMG_CONST.imgPasswordInVisible
                                : IMG_CONST.imgPasswordVisible
                            }
                            style={webStyles.inputAdornment}
                            onClick={this.showConfirmPassword}
                            data-test-id="confirmPwdTestId"
                          />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                </Box>
                <Box>
                  <StyledInputLabel required>
                    {configJSON.captchaTxt}
                  </StyledInputLabel>
                  <StyleTextField
                    error={this.state.showCaptchaValidation}
                    variant="outlined"
                    helperText={this.state.showCaptchaValidation && this.state.captchaErrorTxt}
                    type={this.state.showCaptcha ? "text" : "password"}
                    onChange={this.onChangeCaptcha}
                    autoComplete="off"
                    value={this.state.captchaValue}
                    data-test-id="captchaTestID"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <PasswordImg
                            src={
                              this.state.showCaptcha
                                ? IMG_CONST.imgPasswordInVisible
                                : IMG_CONST.imgPasswordVisible
                            }
                            style={webStyles.inputAdornment}
                            onClick={this.showCaptcha}
                            data-test-id="captchaPwdTestID"
                          />
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      maxLength: 15
                    }}
                  />
                </Box>
                <Box style={webStyles.mainCaptchaContainer}>
                  <Box style={webStyles.captchaDisplayBox}>
                    <span style={webStyles.captchaDisplayText}>{this.state.generatedPasswordCaptcha}</span>
                  </Box>
                  <Box onClick={this.refreshedCaptcha} data-testid="refreshCaptcha">
                    <img src={IMG_CONST.refreshCaptchaIcon} alt="refresh_captcha" style={webStyles.captchaRefreshImg} />
                  </Box>
                </Box>
              </form>
            </InnerLeftBox>
            <InnerMiddleBox>
              <Typography>{configJSON.orTxt}</Typography>
            </InnerMiddleBox>
            <InnerRightBox>
              <Box>
                <DigitalBtn>{configJSON.digitalSignatureTxt}</DigitalBtn>
              </Box>
            </InnerRightBox>
          </InnerBox>
          {this.renderBtn()}
        </MainInnerBox>
      </MainBox>
    );
  };

  renderBtn = () => {
    return (
      <ButtonBox>
        <ResetBtn
          onClick={this.onResetClick}>{configJSON.resetTxt}</ResetBtn>
        <ChangeBtn
          data-testid="changeDataTestId"
          onClick={this.onSubmitChangePwd}
        >
          {configJSON.changeTxt}
        </ChangeBtn>
      </ButtonBox>
    );
  };

  renderModalBox = () => {
    return (
      <Modal style={webStyles.modalMainBox} open={this.state.isDashModalOpen}>
        {this.renderModalBody(this.state.apiResponseValue)}
      </Modal>
    );
  };

  renderModalBody = (modalType: string) => {
    let UI;
    switch (modalType) {
      case "pass":
        UI = (
          <Box style={webStyles.innerModalBox as React.CSSProperties}>
            <img src={IMG_CONST.successImg} alt="success" style={webStyles.dashModalTypeImg} />
            <p style={webStyles.dashModalMsg as React.CSSProperties}>{configJSON.chnagePasswordTxt}</p>
            <button
              style={webStyles.buttonStyles as React.CSSProperties}
              onClick={this.modalCloseHandler}
              data-test-id="modalCloseTestId"
            >
              {configJSON.doneTxt}
            </button>
          </Box>
        );
        break;
      case "failed":
        UI = (
          <Box style={webStyles.innerModalBox as React.CSSProperties}>
            <img
              src={IMG_CONST.errorImg}
              alt="failed"
              style={webStyles.dashModalTypeImg}
            />
            <p style={webStyles.dashModalMsg as React.CSSProperties} >
              {configJSON.notChangePasswordTxt}
            </p>
            <button
              style={webStyles.buttonStyles as React.CSSProperties}
              onClick={this.modalCloseHandler}
              data-test-id="modalCloseTestId"
            >
              {configJSON.retryTxt}
            </button>
          </Box>
        );
        break;
      default:
        UI = (
          <Box style={webStyles.innerModalBox as React.CSSProperties}>
            <img
              src={IMG_CONST.successImg}
              alt="success"
              style={webStyles.dashModalTypeImg}
            />
            <p style={webStyles.dashModalMsg as React.CSSProperties}>{configJSON.chnagePasswordTxt}</p>
            <button style={webStyles.buttonStyles as React.CSSProperties} onClick={this.modalCloseHandler} data-test-id="modalCloseTestId">
              {configJSON.doneTxt}
            </button>
          </Box>
        );
    }
    return UI;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <ContainerBox>
          {this.renderChangePassWord()}
          {this.renderModalBox()}
          <Snackbar
            data-test-id="errorAlertTestId"
            open={this.state.errorAlertChangePswrd}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: 'right', vertical: "top" }}
            onClose={this.onCloseAlertChangePswrd}
          >
            <Alert severity="error">{this.state.errorAlertMsgChangePswrd}</Alert>
          </Snackbar>
        </ContainerBox>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#d3d3d3"
    }
  },
});

const ContainerBox = styled(Box)({
  width: "100%",
  marginLeft: "10px",
  marginTop: "10px"
});

const MainBox = styled(Box)({
  padding: "10px",
  background: "linear-gradient(180deg, rgba(245, 247, 249, 0.71) 1.98%, #275080 100%)",
  borderRadius: "8px",
  boxSizing: "border-box",
  width: "100%"
});

const MainInnerBox = styled(Box)({
  fontSize: "16px",
  padding: "24px",
  borderRadius: "8px",
  background: "#fff",
  boxSizing: "border-box",
  width: "100%"
});

const HeaderMain = styled(Typography)({
  color: "rgba(0, 0, 0, 0.85)",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  marginBottom: "20px",
  cursor: "pointer"
});

const InnerBox = styled(Box)({
  display: "flex",
  gap: "20px"
});

const StyledInputLabel = styled(InputLabel)({
  margin: "15px 0px",
  color: "#000",
  fontSize: "16px",
  "& .MuiInputLabel-asterisk": {
    color: "red"
  }
});

const PasswordImg = styled("img")({
  height: "20px",
  width: "20px",
  cursor: "pointer"
});

const DigitalBtn = styled(Button)({
  background: "linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%)",
  color: "white",
  padding: "8px 50px"
});

const InnerLeftBox = styled(Box)({
  flex: 1
});

const InnerRightBox = styled(Box)({ marginTop: "50px" });

const InnerMiddleBox = styled(Box)({ marginTop: "60px" });

const ButtonBox = styled(Box)({
  display: "flex",
  gap: "20px",
  marginTop: "20px"
});

const ResetBtn = styled(Button)({
  display: "flex",
  width: "185px",
  padding: "12px 24px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  borderRadius: "8px",
  border: "1px solid var(--primary-normal, #0B336A)",
  background: "var(--White, #FFF)",
  textTransform: "capitalize"
});

const ChangeBtn = styled(Button)({
  background: " linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%)",
  width: "185px",
  color: "#fff",
  textTransform: "capitalize"

});

const StyleTextField = styled(TextField)({
  "&.MuiTextField-root": {
    width: "100%"
  },
  "& .MuiInputBase-input": {
    height: "100%",
    "&::focus": {
      borderColor: "lightgray"
    },
    "&::hover": {
      borderColor: "lightgray"
    }
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "lightgray"
    }
  },
  "& .MuiFormHelperText-root": {
    marginLeft: "0px"
  }
});

const webStyles = {
  dashModalMsg: {
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    margin: "0 20px",
    textAlign: "center"
  },

  mainCaptchaContainer: {
    display: 'flex',
    width: '240px',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '24px',
  },

  inputAdornment: {
    zIndex: 99999,
  },

  captchaRefreshImg: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },

  captchaDisplayBox: {
    width: '171px',
    height: '44px',
    border: '1px solid gray',
    background: '#cee4ed85',
    fontWeight: 'bold' as 'bold',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    userSelect: "none" as 'none',
  },

  captchaDisplayText: {
    color: 'black',
    fonSize: '18px',
    textDecoration: 'line-through',
    letterSpacing: '3px',
    fontSize: '18px'
  },

  orTxt: {
    margin: 0
  },

  buttonStyles: {
    background:
      "linear-gradient(0deg, rgba(54, 90, 125, 1) 0%, rgba(54, 90, 125, 1) 9%, rgba(10, 54, 109, 1) 96%)",
    color: "#fff",
    textAlign: "center",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textTransform: "capitalize",
    height: "3rem",
    width: "9.438rem",
    backgroundColor: "#f5f7fa",
    borderRadius: "0.2rem",
    cursor: "pointer",
    border: "none"
  },

  dashModalTypeImg: {
    width: "12.5rem"
  },

  modalMainBox: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },

  innerModalBox: {
    background: "#fff",
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 30,
    borderRadius: "10px",
    width: "500px"
  },

  signupContainer: {
    paddingTop: "2rem"
  },

  innerBox: {
    padding: '10px',
    borderRadius: "10px",
    marginTop: "20px",
    background: "#ffff"
  }

};
// Customizable Area End
