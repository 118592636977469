import React, { Component } from "react";
// Customizable Area Start
import { Tabs } from 'antd'
import AppAccordian from "./AppAccordian.web";
const tabsItem = [
    { label: "Information/Instructions", key: "item-1", children: <AppAccordian navigation={undefined} id={""} /> },
    { label: "View Post", key: "item-2", children: "Content 2" },
];
type MyProps = { navigation: undefined; id: string; }
// Customizable Area End

class AppTabs extends Component<MyProps> {
// Customizable Area Start
    render() {
        return (
            <>
                <Tabs items={tabsItem} className="app-tabs" />
            </>
        );
    }
}
// Customizable Area End
// Customizable Area Start
export default AppTabs
// Customizable Area End
