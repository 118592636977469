// Customizable Area Start
import StorageProvider from "framework/src/StorageProvider";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  isEditableMode: boolean;
  currentFontSize: string;
  navdata: any;
  footernavData: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  modalType: string;
  currentFontSize: string;
  navdata: any;
  footernavData: any;
  authModalType: string;
  full_phone_number: any;
  user_id: any;
  loader: boolean;
  isEditableMode: boolean;
  email: any;
  user_name: any;
  phoneNumber: any;
  address: any;
  district: any;
  user_type: string;
  successOpen: boolean;
  currentCountryCode: any;
  state: any;
  pincode: any;
  gender: any;
  mobile_number: any;
  isDashModalOpen: boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  navdata: any;
  footernavData: any;
  currentFontSize: string;
  full_phone_number: any;
  // Customizable Area End
}

export default class UserProfileBasicBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileGetApiCallId: any;
  userAttr: any;
  userProfileEditApiCallId: any;
  uniqueSessionRequesterId: any;
  authToken: any;
  user_id: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      authModalType: "success",
      user_type: "",
      modalType: "verified",
      currentFontSize: "16px",
      isDashModalOpen: false,
      currentCountryCode: configJSON.hintCountryCode,
      loader: true,
      isEditableMode: false,
      user_id: "",
      email: "",
      user_name: "",
      phoneNumber: "",
      full_phone_number: "",
      address: "",
      district: "",
      state: "",
      pincode: "",
      gender: "",
      successOpen: false,
      mobile_number: "",
      navdata: [
        {
          link: "Home",
          path: "/"
        },
        {
          link: "FAQs",
          path: "/faqs"
        },
        {
          link: "Feedback",
          path: "/feedback"
        },
        {
          link: "Sign Up",
          path: "/signUp"
        }
      ],
      footernavData: [
        {
          name: "Terms and Conditions",
          link: ""
        },
        {
          name: "Privacy Policy",
          link: ""
        },
        {
          name: "Copyright",
          link: ""
        },
        {
          name: "Hyperlinking Policy",
          link: ""
        },
        {
          name: "Accessibility",
          link: ""
        },
        {
          name: "Archive",
          link: ""
        },
        {
          name: "Contact Us",
          link: ""
        },
        {
          name: "Sitemap",
          link: ""
        }
      ]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // this.authToken = localStorage.getItem("token")

    this.userProfileEditApiCallId = "";
    this.userProfileGetApiCallId = "";
    this.getUserProfile();
    // Customizable Area End
  }

  // Customizable Area Start

  getUserProfile = async () => {
    const RequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileGetApiCallId = RequestMessage.messageId;

    RequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserProfile
    );
    let Token = await StorageProvider.get("TOKEN");

    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: Token
    };

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    RequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiGetUserProfile
    );

    runEngine.sendMessage(RequestMessage.id, RequestMessage);
  };
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson.data) {
        if (apiRequestCallId === this.userProfileGetApiCallId) {
          //On User Profile Success
          let userAttr = responseJson.data.attributes;
          this.setState({
            user_id: userAttr.user_id,
            email: userAttr.email,
            user_type: userAttr.user_type,
            user_name: userAttr.user_name,
            address: userAttr.address,
            district: userAttr.district,
            state: userAttr.state,
            pincode: userAttr.pincode,
            gender: userAttr.gender,
            mobile_number: userAttr.mobile_number,
            full_phone_number: userAttr.full_phone_number
          });
          this.setState({ loader: false });
          this.parseApiCatchErrorResponse(errorReponse);
        }
        if (apiRequestCallId === this.userProfileEditApiCallId) {
          //On User Profile Success
          console.log("response json success data 232", responseJson.data);
          this.setState({ isDashModalOpen: true });
          this.parseApiCatchErrorResponse(errorReponse);
        }
      } else {
        if (responseJson.errors[0].token) {
          this.showAlert("Session Expired", "Please Log in again.");
          window.alert("Session Expired!, Please Log in again.");
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
  }
  userProfileEditApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    let Token = await StorageProvider.get("TOKEN");

    const header = {
      "Content-Type": contentType,
      token: Token
    };

    const requestmessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestmessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestmessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestmessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body && type !== "formData"
      ? requestmessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestmessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestmessage.id, requestmessage);
    return requestmessage.messageId;
  };

  editUserProfile = async (values: any) => {
    const data = {
      attributes: {
        email: values.email,
        full_phone_number: values.phoneNumber,
        address: values.address,
        mobile_number: values.mobile_number
      }
    };
    const httpBody = {
      data: data
    };
    this.userProfileEditApiCallId = await this.userProfileEditApiCall({
      contentType: configJSON.contentTypeApiEditUserProfile,
      method: configJSON.methodTypeApiEditUserProfile,
      endPoint: configJSON.endPointApiEditUserProfile,
      body: httpBody
    });
  };
  onFinish = (values: any) => {
    this.editUserProfile(values);
    this.setState({ isDashModalOpen: true });
  };
  closeDashModal = () => {
    this.setState({ isDashModalOpen: false, isEditableMode: false });
  };

  fontSizeHandler = (size: string) => {
    const root: any = document.documentElement;
    root.style.setProperty("--base-font-size", size);
    this.setState({
      currentFontSize: size
    });
    return void 0;
  };
  // Customizable Area End
}
