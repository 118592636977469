    // Customizable Area Start
    export const imgPasswordVisible = require("../assets/ic_password_visible.svg");
    export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
    export const successImg = require("../assets/success.gif");
    export const errorImg = require("../assets/error.gif");
    export const uploadImg = require("../assets/uploadImg.svg");
    export const RightPass = require("../assets/rightPassword.svg");
    export const WrongPass = require("../assets/wrongPassword.svg");
    export const refreshCaptchaIcon = require("../assets/refresh_captcha.png")
    export const BlankField = require("../assets/blankPassowrd.svg");
    // Customizable Area End