import React, { Component } from "react";
// Customizable Area Start
import * as IMG_CONST from './assets'
import { Dropdown, Menu } from 'antd'
import { FaRegCircleUser } from 'react-icons/fa6'
import { BsSearch } from "react-icons/bs"
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link } from 'react-router-dom'
type MyProps = {
    navigation: undefined; id: string;
    navData: any, currentFontSize: string;
    fontSizeHandler: (size: string) => void;
    isLogin: boolean;
    onLogoutClick?: () => void;
}
// Customizable Area End

class Header extends Component<MyProps> {
    // Customizable Area Start
    renderTranslateDropdown = () => {
        return (
            <Menu
                className="translate-dropdown"
                selectable={true}
                items={[
                    {
                        key: "1",
                        label: (
                            <div className="translate-dropdown-item">
                                <span className="translate-dropdown-icon">अ</span>
                                <span>हिंदी</span>
                            </div>
                        ),
                    },
                    {
                        key: "2",
                        label: (
                            <div className="translate-dropdown-item">
                                <span className="translate-dropdown-icon">A</span>
                                <span>English</span>
                            </div>
                        ),
                    },
                ]}
            />
        );
    };

    renderDropdown = () => {
        return (
            <Menu
                className={
                    this.props.currentFontSize === "18px"
                        ? "dropdown20"
                        : this.props.currentFontSize === "14px"
                            ? "dropdown12"
                            : "dropdown"
                }
                items={[
                    {
                        key: "1",
                        label: (
                            <Link to="/faqs" className={
                                window.location.pathname === "/faqs"
                                    ?
                                    "dropdown-text dropdown-link-active"
                                    :
                                    "dropdown-text"}>
                                FAQs
                            </Link>
                        ),
                    },
                    {
                        key: "2",
                        label: (
                            <Link to="/feedback" className={window.location.pathname === "/feedback" ? "dropdown-text dropdown-link-active" : "dropdown-text"}>
                                Feedback
                            </Link>
                        ),
                    },
                    {
                        key: "3",
                        label: <span className="dropdown-text">Skip to Main Content</span>,
                    },
                    {
                        key: "4",
                        label: <span className="dropdown-text">Screen Reader Access</span>,
                    },
                    {
                        key: "5",
                        label: (
                            <Link to="/signUp" className={window.location.pathname === "/signUp" ? "dropdown-text dropdown-link-active" : "dropdown-text"}>
                                Sign Up
                            </Link>
                        ),
                    },
                    {
                        key: "6",
                        label: (
                            <ul className="dropdown-font-size-container font-size-container20">
                                <li className="dropdown-font">
                                    <button
                                        className={this.props.currentFontSize === "14px" ? "text-minus activeFontSize" : "text-minus"}
                                        onClick={() => this.props.fontSizeHandler("14px")}
                                    >
                                        A-
                                    </button>
                                    <button
                                        className={this.props.currentFontSize === "16px" ? "text-normal activeFontSize" : "text-normal"}
                                        onClick={() => this.props.fontSizeHandler("16px")}
                                    >
                                        A
                                    </button>
                                    <button
                                        className={this.props.currentFontSize === "18px" ? "text-plus activeFontSize" : "text-plus"}
                                        onClick={() => this.props.fontSizeHandler("18px")}
                                    >
                                        A+
                                    </button>
                                </li>
                            </ul>
                        ),
                    },
                    {
                        key: "7",
                        label: (
                            <li className="dropdown-nav-list-item-arrow">
                                <button className="latest-btn dropdown-latest-btn">Latest!!!</button>
                            </li>
                        ),
                    },
                ]}
            />
        );
    };

    renderLink = () => (
        <ul className="nav-left">
            {this.props.navData.map((item: any) =>

            (
                <li
                    key={item.link}
                    className="nav-list-item"
                >
                    <Link
                        to={item.path}
                        key={item.link}
                        className={window.location.pathname === item.path ? "nav-list-link nav-list-link-active" : "nav-list-link"}
                    >
                        <span>{item.link}</span>
                    </Link>
                </li>

            )
            )}
            <button className="latest-btn">Latest!!!</button>
        </ul>
    );

    renderAccountDropdown = () => {
        const menu = (
            <Menu className="account-dropdown">
                <Menu.Item disabled={true}>
                    <p className="account-dropdown-title">Do not have an account?</p>
                </Menu.Item>
                <Menu.Item>
                    <Link className="account-dropdown-btn account-dropdown-btn-signUp"
                        to="/signUp"
                    >
                        sign up
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={true}>
                    <p className="account-dropdown-title">Already have an account?</p>
                </Menu.Item>
                <Menu.Item>
                    <Link
                        className="account-dropdown-btn account-dropdown-btn-login"
                        to="/login"
                    >
                        login
                    </Link>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown
                overlay={menu}
                trigger={["click"]}
                placement="bottom"
                arrow={true}
            >
                <FaRegCircleUser />
            </Dropdown>
        );
    };

    renderMDropdown = () => {
        const menu = (
            <Menu className="mDropdown-custom">
                <Menu.Item disabled={true}>
                    <h2 className="mDropdown-title">Menu</h2>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/" className={window.location.pathname === "/" ? "mDropdown-list-item mDropdown-active-link" : "mDropdown-list-item"}>
                        Home
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/faqs" className={window.location.pathname === "/faqs" ? "mDropdown-list-item mDropdown-active-link" : "mDropdown-list-item"}>
                        FAQs
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/feedback" className={window.location.pathname === "/feedback" ? "mDropdown-list-item mDropdown-active-link" : "mDropdown-list-item"}>
                        Feedback
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to="/signUp" className={window.location.pathname === "/signUp" ? "mDropdown-list-item dropdown-link-active" : "mDropdown-list-item"}>
                        Sign Up
                    </Link>
                </Menu.Item>
                <Menu.Item disabled={true}>
                    <h2 className="mDropdown-title">Accessibility</h2>
                    <div className="access-container">
                        <span className="font-size-text">Font Size</span>
                        <div className="font-change-container">
                            <Menu.Item>
                                <button className={this.props.currentFontSize === "14px" ? "font-change-btn font-change-btn-active" : "font-change-btn"} onClick={() => this.props.fontSizeHandler("14px")}>A-</button>
                            </Menu.Item>
                            <Menu.Item>
                                <button className={this.props.currentFontSize === "16px" ? "font-change-btn font-change-btn-active" : "font-change-btn"} onClick={() => this.props.fontSizeHandler("16px")}>
                                    A
                                </button>
                            </Menu.Item>
                            <Menu.Item>
                                <button className={this.props.currentFontSize === "18px" ? "font-change-btn font-change-btn-active" : "font-change-btn"} onClick={() => this.props.fontSizeHandler("18px")}>A+</button>
                            </Menu.Item>
                        </div>
                    </div>
                </Menu.Item>

                <Menu.Item disabled={true}>
                    <div className="mDropdown-lang">
                        <span className="lang-text">Languages</span>
                        <span className="lang-icon">
                            <MdOutlineKeyboardArrowRight />
                        </span>
                    </div>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} trigger={["click"]} className="menu-dropdown">
                <RxHamburgerMenu />
            </Dropdown>
        );
    };

    renderHeader = () => {
        return (
            <div>
                <header
                    className={
                        this.props.currentFontSize === "18px"
                            ? "header header20"
                            : this.props.currentFontSize === "14px"
                                ? "header header12" :
                                "header"
                    }
                >
                    <div className="header-top">
                        <div className="header-top-right-img-container">
                            <img
                                className="header-top-right-img"
                                src={IMG_CONST.headerLeftImg}
                                alt="header left"
                            />
                        </div>
                        <div className="header-top-left-img-container">
                            <img
                                className="header-top-left-img"
                                src={IMG_CONST.headerRightImg}
                                alt="header right"
                            />
                        </div>
                    </div>
                    <nav className="nav">
                        {this.renderLink()}

                        <ul className="nav-right">

                            <li className="cur-p nav-list-item">
                                Skip to Main Content
                            </li>
                            <li className="cur-p nav-list-item">
                                Screen Reader Access
                            </li>

                            <li className="nav-list-item nav-font-size-container">
                                <button
                                    className={this.props.currentFontSize === "14px" ? "text-minus activeFontSize" : "text-minus"}
                                    onClick={() => this.props.fontSizeHandler("14px")}
                                >
                                    A-
                                </button>
                                <button
                                    className={this.props.currentFontSize === "16px" ? "text-normal activeFontSize" : "text-normal"}
                                    onClick={() => this.props.fontSizeHandler("16px")}

                                >
                                    A
                                </button>
                                <button

                                    className={this.props.currentFontSize === "18px" ? "text-plus activeFontSize" : "text-plus"}

                                    onClick={() => this.props.fontSizeHandler("18px")}

                                >
                                    A+
                                </button>
                            </li>

                            <li>
                                <Dropdown
                                    overlay={() => this.renderTranslateDropdown()}
                                    trigger={["click"]}
                                >
                                    <div className="nav-list-item-arrow">
                                        <li>English</li>
                                        <img
                                            className="down-arrow"
                                            src={IMG_CONST.downArrowImg}
                                            alt="down arrow"
                                        />
                                    </div>
                                </Dropdown>
                            </li>
                            <li className="more">
                                <Dropdown
                                    overlay={() => this.renderDropdown()}
                                    trigger={["click"]}
                                >
                                    <span>More</span>
                                </Dropdown>
                            </li>
                        {
                            this.props.isLogin &&
                            <div className="mainLogOutDiv" onClick={this.props.onLogoutClick}>
                                <img src={IMG_CONST.logOutImg} />
                                <li className="listDiv">Log out</li>
                            </div>
                        }
                        </ul>

                    </nav>
                </header >

                <header className="mHeader">
                    <nav className="mNav">
                        <ul className="mNav-list">
                            <li className="li-mNav-list-item ">
                                <img className="logo" src={IMG_CONST.headerRightImg} alt="logo" />
                            </li>
                            <li className="mNav-list-item" >
                                {this.renderAccountDropdown()}
                            </li>
                            <li className="mNav-list-item">
                                <BsSearch />
                            </li>
                            <li className="mNav-list-item">
                                {this.renderMDropdown()}
                            </li>
                        </ul>
                    </nav>
                </header>

            </div>)
    }

    render() {
        return (
            <>

                {this.renderHeader()}

            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
export default Header
// Customizable Area End



