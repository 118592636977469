import React from "react";
// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  Grid,
  InputLabel,
  TextField,
  Snackbar
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import ContactusController, { HelplineGeneralInterfaceData, OfficesInterfaceData1, Props, configJSON } from "./ContactusController"
import { IoIosArrowDown } from "react-icons/io";
import Header from "../../../components/src/Header.web";
import Banner from "../../../components/src/Banner.web";
import Footer from "../../../components/src/Footer.web";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHelpLineGeneral = () => {
    return (
      <Box>
        {this.state.helplineGeneralData.map((item: HelplineGeneralInterfaceData) => (
          <Grid container spacing={3} style={styles.gridContainer}>
            <Grid item xs={12}>
              <Typography style={styles.mainTitle as React.CSSProperties}>
                {item.attributes.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={styles.listTitle}>
                <Typography style={styles.listTitleText}>{configJSON.patents}</Typography>
              </Box>
              <Box style={styles.list}>
                {item.attributes.helpline_details.Patents.map(
                  (item: { id: number; contact_key: string; contact_value: string; index: number }, index: number, array: { length: number }[]) => (
                    <Box
                      style={
                        index === array.length - 1 ? styles.lastListItem : styles.listItem
                      }
                      key={item.id}>
                      <Typography style={styles.listItemStateName}>
                        {item.contact_key}
                      </Typography>
                      <Typography>{configJSON.colonTxt}</Typography>
                      <Typography style={styles.listContact}>{item.contact_value}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box style={styles.listTitle}>
                <Typography style={styles.listTitleText}>
                  {configJSON.trade}
                </Typography>
              </Box>
              <Box style={styles.list}>
                {item.attributes.helpline_details['Trade Marks'].map((item: {
                  id: number; contact_value: string; contact_key: string;
                }, index: number, array: { length: number }[]) => (
                  <Box
                    style={
                      index === array.length - 1 ? styles.lastListItem : styles.listItem
                    }>
                    <Typography
                      style={styles.listItemStateName}>
                      {item.contact_key}
                    </Typography>
                    <Typography>{configJSON.colonTxt}</Typography>
                    <Box style={styles.listContact}>
                      <Typography>{item.contact_value}</Typography>
                    </Box>
                  </Box>
                )
                )}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    );
  };

  renderOffices = () => {
    return (
      <Box>
        {this.state.officesData.map((item: OfficesInterfaceData1) => (
          <Grid xs={12} container spacing={3} style={styles.gridContainer}>
            <Grid item xs={12}>
              <Typography style={styles.mainTitle as React.CSSProperties}>
                {item.attributes.title}
              </Typography>
            </Grid>
            {item?.attributes?.office_details?.data.map(
              (office) => {
                const textOne = office?.attributes?.address.split("\r\n")[0];
                const textTwo = office?.attributes?.address.split("\r\n")[1];

                return (
                  <Grid item xs={12} md={6} >
                    <Box style={styles.details as React.CSSProperties}>
                      <Box style={styles.profile as React.CSSProperties}>
                        {office?.attributes?.profile_photo_url == null ?
                          <img src={IMG_CONST.userImg} style={styles.profileImg} />
                          :
                          <img src={office?.attributes?.profile_photo_url} style={styles.profileImg} />
                        }
                      </Box>
                      <Box style={styles.description as React.CSSProperties}>
                        <Typography style={styles.titleOne}>{office?.attributes?.name}</Typography>
                        <Typography style={styles.subTitle}>
                          {office?.attributes?.designation}
                        </Typography>
                        <Box style={styles.titleBox}>
                          <Typography style={styles.titleTwo}>{textOne} </Typography>
                          <Typography style={styles.titleTwo}>{textTwo} </Typography>
                        </Box> 
                        <Box style={styles.contacts as React.CSSProperties}>
                          {office.attributes.phone_number === "" ? <Box></Box> : (
                            <Box style={styles.contact}>
                              <img src={IMG_CONST.phoneImg} style={styles.iconImg} />
                              <Typography style={styles.contactText}>
                                {office.attributes.phone_number}
                              </Typography>
                            </Box>
                          )}
                          {office.attributes.fax_number === "" ? <Box></Box> : (
                            <Box style={styles.contact}>
                              <img src={IMG_CONST.faxImg} style={styles.iconImg} />
                              <Typography style={styles.contactText}>
                                {office.attributes.fax_number}
                              </Typography>
                            </Box>
                          )}
                          {office.attributes.website_link === "" ? <Box></Box> : (
                            <Box style={styles.contact}>
                              <img src={IMG_CONST.emailImg} style={styles.iconImg} />
                              <Typography style={styles.contactText}>
                                {office.attributes.website_link}-
                              </Typography>
                            </Box>
                          )}
                          {office.attributes.email === "" ? <Box></Box> : (<Box
                            style={styles.contact}>
                            <img src={IMG_CONST.emailImg} style={styles.iconImg} />
                            <Typography style={styles.contactText}>
                              {office.attributes.email}
                            </Typography>
                          </Box>)
                          }
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                )
              }
            )}
          </Grid>
        ))}
      </Box>
    );
  };

  renderForm = () => {
    const {
      firstNameErr,
      lastNameErr,
      emailErr,
      phoneNoErr,
      firstName,
      lastName,
      email,
      phoneNo
    } = this.state;
    return (
      <Box>
        <form onSubmit={this.onSubmit}
          data-test-id="formTestId"
        >
          <Grid container spacing={3} style={styles.gridContainerForm}>
            <Grid item xs={12}>
              <Typography style={styles.formTitle}>
                {configJSON.getTouch}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required>
                {configJSON.firstName}
              </StyledInputLabel>
              <StyledInput
                placeholder="Enter First Name"
                variant="outlined"
                error={firstNameErr ? true : false}
                helperText={firstNameErr}
                value={firstName}
                onChange={this.onFirstNameChange}
                data-test-id="firstNameChangeTestId"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required>
                {configJSON.lastName}
              </StyledInputLabel>
              <StyledInput
                placeholder="Enter Last Name"
                variant="outlined"
                error={lastNameErr ? true : false}
                helperText={lastNameErr}
                value={lastName}
                onChange={this.onLastNameChange}
                data-test-id="lastNameChangeTestId"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required>
                {configJSON.emailAddress}
              </StyledInputLabel>
              <StyledInput
                placeholder="Enter Your Email Address"
                variant="outlined"
                error={emailErr ? true : false}
                helperText={emailErr}
                value={email}
                onChange={this.onEmailChange}
                data-test-id="emailChangeTestId"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required>
                {configJSON.contactNo}
              </StyledInputLabel>
              <StyledInput
                placeholder="+91"
                variant="outlined"
                error={phoneNoErr ? true : false}
                helperText={phoneNoErr}
                value={phoneNo}
                data-test-id="phoneNoChangeTestId"
                onChange={this.onPhoneNumberChange}
                inputProps={{ maxLength: 13 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required>{configJSON.applicationType}</StyledInputLabel>
              <div ref={this.applicationDropdownRef}>
                <Box
                  style={this.state.applicationTypeErr === "" ? styles.dropdownSelect : styles.dropdownSelectValidation}
                  onClick={this.onApplicationDropDownClick}
                  data-test-id="applicationDropdownTestId"
                >
                  <Typography style={
                    this.state.activeApplicationDropdownItem === "Select Application Type" ?
                      styles.dropdownUnSelectText :
                      styles.dropdownSelectText
                  }
                    data-test-id="applictionSelectTestId">
                    {this.state.activeApplicationDropdownItem}
                  </Typography>
                  <IoIosArrowDown style={styles.dropdownSelectIcon} />
                </Box>
                {this.state.applicationDropdown && (
                  <Paper style={styles.dropdownOuterBox}>
                    <Box component={"ul"} style={styles.dropdownList} data-test-id={"applictionTestId"}>
                      {this.state.applicationDataList.map(
                        (item: string, index: number) => (
                          <Box
                            component={"li"}
                            onClick={() => {
                              this.onActiveApplication(item);
                            }}
                            data-test-id={`activeApplictionTestId_${index}`}
                            key={item}
                            style={
                              this.state.activeApplicationDropdownItem === item
                                ? styles.activeDropdownListItem
                                : styles.dropdownListItem
                            }
                          >
                            <Typography data-test-id="applictionSelectTestId">{item}</Typography>
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                )}
              </div>
              <Typography style={styles.dropdownErrorTxt}>{this.state.applicationTypeErr}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInputLabel required >{configJSON.feedbackType}</StyledInputLabel>
              <div ref={this.feedbackDropdownRef}>
                <Box
                  style={this.state.feedbackTypeErr === "" ? styles.dropdownSelect : styles.dropdownSelectValidation}
                  data-test-id="feedbackDropdownTestId"
                  onClick={this.onFeedbackDropDownClick}
                >
                  <Typography style={
                    this.state.activeFeedbackDropdownList === "Select Feedback Type" ?
                      styles.dropdownUnSelectText :
                      styles.dropdownSelectText
                  }
                    data-test-id="feedbackSelectTestId">
                    {this.state.activeFeedbackDropdownList}
                  </Typography>
                  <IoIosArrowDown style={styles.dropdownSelectIcon} />
                </Box>
                {this.state.feedbackDropdown && (
                  <Paper style={styles.dropdownOuterBox}>
                    <Box component={"ul"} style={styles.dropdownList} data-test-id={"feedbackTestId"}>
                      {this.state.feedbackDataList.map(
                        (item: string, index: number) => (
                          <Box
                            component={"li"}
                            onClick={() => {
                              this.onActiveFeedbackClick(item);
                            }}
                            data-test-id={`feedbackSelectTestId_${index}`}
                            key={item}
                            style={
                              this.state.activeFeedbackDropdownList === item
                                ? styles.activeDropdownListItem
                                : styles.dropdownListItem
                            }
                          >
                            <Typography>{item}</Typography>
                          </Box>
                        ))}
                    </Box>
                  </Paper>
                )}
              </div>
              <Typography style={styles.dropdownErrorTxt}>{this.state.feedbackTypeErr}</Typography>
            </Grid>
            <Grid item xs={12}>
              <StyledInputLabel required>{configJSON.subject}</StyledInputLabel>
              <StyledInput
                placeholder="Enter Your Subject"
                variant="outlined"
                error={this.state.subjectErr ? true : false}
                inputProps={{ maxLength: 200 }}
                helperText={
                  <>
                    <Typography>{configJSON.charLimit200}</Typography>
                    <Typography>{`${this.state.subject.trim().length
                      }/200`}</Typography>
                  </>
                }
                value={this.state.subject}
                onChange={this.onSubjectChange}
                data-test-id="subjectChangeTestId"
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInputLabel required>
                {configJSON.description}
              </StyledInputLabel>
              <StyledDescriptionInput
                placeholder="Enter Your Description"
                variant="outlined"
                multiline
                // rows={4}
                inputProps={{ maxLength: 1000 }}
                error={this.state.descriptionErr ? true : false}
                helperText={
                  <>
                    <Typography>{configJSON.charLimit1000}</Typography>
                    <Typography>{`${this.state.description.trim().length}/1000`}</Typography>
                  </>
                }
                value={this.state.description}
                onChange={this.onDescriptionChange}
                data-test-id="descriptionChangeTestId"
              />
            </Grid>
            <Grid item xs={12}>
              <Box style={styles.formFooter}>
                <Button
                  style={styles.resetBtn as React.CSSProperties}
                  data-test-id="resetBtnTestId"
                  onClick={this.onResetClick}
                >
                  Reset
                </Button>
                <Button style={styles.submitBtn as React.CSSProperties} type="submit">
                  Submit
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    );
  };

  renderModal = () => {
    return <DisplayModal
      open={this.state.isModalOn}
      onClose={this.switchOffModal}
    >
      <ModalContent>
        <SubmitIcon src={IMG_CONST.successImg} />
        <MessageText>Submit Successfully</MessageText>
        <DoneButton onClick={this.switchOffModal}>Done</DoneButton>
      </ModalContent>
    </DisplayModal>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: "top" }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Header
          navigation={undefined}
          id={""}
          navData={this.state.navData}
          fontSizeHandler={this.fontSizeHandler}
          currentFontSize={this.state.currentFontSize}
          isLogin={false}
          onLogoutClick={undefined}
        />
        <div className="container">
          <div style={styles.signupContainer}>
            <Banner
              title="welcome"
              activeLink="home"
              navigation={undefined}
              id={""}
              activePage="Contact Us"
            />
            <Box style={styles.innerBox}
            >
              {this.renderModal()}
              {this.renderHelpLineGeneral()}
              {this.renderOffices()}
              {this.renderForm()}
            </Box>
            <Footer
              navigation={undefined}
              id={""}
              footerNavData={this.state.footerNavData}
            />
          </div>
        </div>
      </Box>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = {
  container: {
    padding: "16px 24px"
  },

  gridContainer: {
    width: "100%",
    margin: 0,
    padding: "0px 20px",
  },

  gridContainerForm: {
    width: "100%",
    margin: 0,
    background: '#fff'
  },

  mainTitle: {
    backgroundColor: "#0B3462",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    color: "#fff",
    fontSize: "24px",
    fontWeight: "500",
    textAlign: "center"
  },

  details: {
    display: "flex",
    gap: "20px",
    borderRadius: "8px",
    border: "1px solid  #9BAEC3",
    height: "100%",
    padding: "16px",
    boxSizing: "border-box"
  },

  profile: {
    fontSize: "90px",
    width: "90px",
    height: "90px",
    flexShrink: "0",
    color: "#D5DFEB"
  },

  dropdownErrorTxt: {
    fontSize: "14px",
    color: "#f44336"
  },

  description: {
    display: "flex",
    flexDirection: "column"
  },

  titleOne: {
    fontWeight: 700
  },

  titleTwo: {
    fontWeight: 500,
    color: "#808080",
    fontSize: "12px"
  },

  subTitle: {
    color: "#808080",
    fontSize: "12px"
  },

  titleBox: {
    marginTop:"4px"
  },

  contacts: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px"
  },

  contact: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    color: "#808080",
    marginRight: "8px"
  },

  contactText: {
    fontSize: "12px"
  },

  formTitle: {
    fontSize: "32px",
    fontWeight: 600
  },
  inputField: {
    width: "100%",
    marginTop: "16px",
    "& .MuiFormHelperText-contained": {
      marginLeft: "0px"
    }
  },

  inputFieldFooter: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "#808080"
  },

  formFooter: {
    marginTop: "16px"
  },

  stateNameTxt: { width: "80px" },

  resetBtn: {
    color: "#0B336A",
    border: "1px solid",
    padding: "7px 41.5px",
    marginRight: "16px",
    boxSizing: "border-box",
    textTransform: "capitalize"
  },

  submitBtn: {
    background: "linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%)",
    color: "#fff",
    padding: "8px 41.5px",
    textTransform: "capitalize"
  },

  formControl: {
    position: "relative",
    paddingBottom: "20px"
  },

  inputErr: {
    position: "absolute",
    bottom: "0",
    color: "#FC0019",
    fontSize: "14px"
  },

  listTitle: {
    backgroundColor: "#D5E0EB",
    borderRadius: "8px"
  },

  listTitleText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0px",
    fontWeight: 400,
    fontSize: "18px"
  },

  list: {
    margin: "10px 0px"
  },

  listItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    borderBottom: "2px #D5DFEB solid",
    padding: "10px 10px"
  },

  lastListItem: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    padding: "10px 10px"
  },

  listItemStateName: {
    width: "80px"
  },

  listContact: {
    display: "flex",
    flex: 1,
    gap: "5px"
  },

  dropdownSelect: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid #979797",
    height: "44px",
    marginTop: "16px",
    padding: "0 10px",
    cursor: "pointer"
  },

  dropdownSelectValidation: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    border: "1px solid red",
    height: "44px",
    marginTop: "16px",
    padding: "0 10px",
    cursor: "pointer"
  },

  dropdownUnSelectText: {
    color: "rgb(209 213 219)",
    fontSize: "16px",
  },
  dropdownSelectText: {
    color: "#000",
    fontSize: "16px",
  },
  listText: {
    marginBottom: "0px !important"
  },
  dropdownSelectIcon: {
    color: "#0B3462",
    fontSize: "24px"
  },

  dropdownListContainer: {
    marginTop: "-16px"
  },

  dropdownList: {
    listStyle: "none",
    padding: "16px 8px"
  },

  dropdownOuterBox: {
    border: "1px solid #979797",
    borderRadius: "8px"
  },

  dropdownListItem: {
    borderRadius: "8px",
    padding: "8px 16px",
    cursor: "pointer"
  },

  activeDropdownListItem: {
    backgroundColor: "#D5DFEB",
    borderRadius: "8px",
    padding: "8px 16px",
    cursor: "pointer"
  },

  signupContainer: {
    paddingTop: "2rem",
  },

  innerBox: {
    padding: '10px',
    borderRadius: "10px",
    marginTop: "20px",
    background: "#ffff"
  },

  iconImg: {
    height: "15px",
    width: "15px"
  },

  profileImg: {
    width: "90px",
    height: "90px",
    borderRadius: "50%"
  }
};

const DisplayModal = styled(Modal)({
  height: "100%",
  width: "100%",
  background: "linear-gradient(360deg, #275080 1.98%, rgba(245, 247, 249, 0.71) 100%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

const ModalContent = styled(Box)({
  height: "300px",
  width: "300px",
  borderRadius: "10px",
  outline: "none",
  backgroundColor: "#ffffff",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-evenly",
})

const MessageText = styled(Typography)({

})

const DoneButton = styled("button")({
  width: "100px",
  border: "none",
  borderRadius: "2px",
  padding: "5px 10px",
  // background: var(--Gradient, linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%));
  background: "linear-gradient(180deg, #002E6A -6.59%, #708990 105.03% )",
  color: "#ffffff",
  fontSize: "16px",
  fontWeight: 400,
  cursor: "pointer"
})

const SubmitIcon = styled("img")({
  height: "80px",
  width: "80px",
  borderRadius: "100%"
})

const StyledInputLabel = styled(InputLabel)({
  color: "rgba(0,0,0,1)",
  fontWeight: 500,
  "& .MuiInputLabel-asterisk": {
    color: "red",
    marginLeft: "-1px"
  }
});

const StyledInput = styled(TextField)({
  width: "100%",
  marginTop: "16px",
  "& .MuiOutlinedInput-root": {
    height: "44px",
    "& fieldset": {
      borderColor: "#979797"
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#909DAD"
    }
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between"
  }
});

const StyledDescriptionInput = styled(TextField)({
  width: "100%",
  marginTop: "16px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#979797"
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#909DAD"
    }
  },
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px",
    fontSize: "14px",
    display: "flex",
    justifyContent: "space-between"
  }
});
// Customizable Area End
