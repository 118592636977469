import React from "react";
// Customizable Area Start
import UserProfileBasicBlockController, {
  Props
} from "./UserProfileBasicBlockController.web";
import { Form, Input, Radio, Select, Typography } from "antd";
const { Option } = Select;
import { Button, Dialog, Paper } from "@material-ui/core";
import { successImg } from "../../forgot-password/src/assets";
const userIcon = require("../assets/group_user1.png");
const editIcon = require("../assets/edit.png");

// Customizable Area End

export default class UserProfileBasicBlockForm extends UserProfileBasicBlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderForm = (userData: any) => (
    <div style={styles.perentDiv}>
      <div style={styles.formContainer}>
        <div style={styles.formContentContainer}>
          <div style={styles.userDetailsDiv}>
            <p style={styles.formtitle}>User Details</p>
            {!this.state.isEditableMode && (
              <img
                src={editIcon}
                style={styles.editicon}
                data-test-id="editProfileSubmitBtn"
                onClick={() => {
                  this.setState({ isEditableMode: true });
                }}
              />
            )}
          </div>
          <Form
          requiredMark={false}
            onFinish={this.onFinish}
            data-test-id="signUpSubmitBtn"
            name="sign-up"
            layout="vertical"
            initialValues={userData}
            disabled={!this.state.isEditableMode}
          >
            <div style={styles.profilephotodiv}>
              <div style={styles.usernamediv}>
                <div style={styles.usericondiv}>
                  <img src={userIcon} style={styles.usericon} />
                </div>
                <div style={styles.boxStyleUserName}>
                  <span style={styles.usernametxt} >{this.state.user_name}</span>
                  <p style={styles.useridtxt} >{this.state.user_id}</p>
                </div>
              </div>{this.state.isEditableMode && (
              <button type="button" style={styles.uploadphotobtn}>
                Upload your Photo
              </button>)}
            </div>
            <div style={styles.fieldWrapper}>
              <span style={styles.fieldTitle}>Type of Application <span style={styles.required}>*</span></span>
              <Form.Item>
              <Select disabled defaultValue={this.state.user_type}>
                 <option value={this.state.user_type}>{this.state.user_type}</option> 
                </Select>
              </Form.Item>
              <span style={styles.fieldTitle}>Registered Signature <span style={styles.required}>*</span></span>
              <Radio.Group>
                <Radio value="Electronics Signature" disabled>
                  Electronics Signature
                </Radio>
                <Radio value="Digital Signature" disabled>
                  Digital Signature
                </Radio>
              </Radio.Group>
            </div>
            <div style={styles.fieldWrapper}>
              <span style={styles.fieldTitle}>User ID <span style={styles.required}>*</span></span>
              <Form.Item
                name="domicile"
                data-test-id="id-input"
                
                rules={[
                  {
                    required: true,
                    message: <span style={styles.errorStyle}>This field cannot be left empty</span>
                  },
                  {
                    pattern: /^.{1,10}$/,
                    message:  <span style={styles.errorStyle}>Not More Then 10 Characters</span>
                  }
                ]}
              >
                <Input  disabled style={{margin:"2px"}}/> 
              </Form.Item>
              <Form.Item
                
                data-test-id="name-input"
                name="user_name"
                label={
                  <span style={styles.labelsStyle}>
                    Name of Application <span style={styles.required}>*</span>
                  </span>
                }
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>
                  },
                  {
                    message:<span style={styles.errorStyle}>name as available in Digital Signatures shall be used as Name of Applicant/One of the applicant in all filings.</span>
                  }
                ]}
              >
                <Input disabled style={{margin:"2px"}}/>  
              </Form.Item>
              <Form.Item
                data-test-id="address-input"
                name="address"
                label={
                  <span style={styles.labelsStyle}>
                    Address for Services <span style={styles.required}>*</span>
                  </span>
                }
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>
                  },

                ]}
              >
                <Input disabled={!this.state.isEditableMode} style={{margin:"2px"}}/> 
              </Form.Item>
              <Form.Item
                name="district"
                label={
                  <span style={styles.labelsStyle}>
                    Town/City/District <span style={styles.required}>*</span>
                  </span>
                }
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>

                    
                  },
                  {
                    pattern: /^[A-Za-z\s'-]{3,}$/,
                    message: <span style={styles.errorStyle}>Enter valid town/city/district with landmark</span>
                  }
                ]}
              >
                <Input  disabled style={{margin:"2px"}}/>  
              </Form.Item>
              <Form.Item
                name="state"
                label={
                  <span style={styles.labelsStyle}>
                    State/Union Territory <span style={styles.required}>*</span>
                  </span>
                }
                style={styles.inputstyle}
                rules={[{ required: true, 
                  message: <span style={styles.errorStyle}>Selection Required</span>
                }]}
              >
                <Input disabled style={{margin:"2px"}}/>  
              </Form.Item>
              <Form.Item
                name="pincode"
                label={<span style={styles.labelsStyle}>Pincode <span style={styles.required}>*</span></span>}

                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message: <span style={styles.errorStyle}>This field cannot be left empty</span>
                  },
                  {
                    pattern: /^\d{6}$/,
                    message: <span style={styles.errorStyle}>enter valid pincode</span>
                  }
                ]}
              >
                <Input disabled style={{margin:"2px"}}/>  
              </Form.Item>
              <Form.Item
                name="telephone"
                label={<span style={styles.labelsStyle}>Telephone Number (with STD/ISD Code) <span style={styles.required}>*</span></span>}
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message: <span style={styles.errorStyle}>This field cannot be left empty</span>
                  },
                  {
                    pattern: /^\+?\d{10,14}$/,
                    message:  <span style={styles.errorStyle}>Enter Valid STD/ISD code</span>
                  }
                ]}
              >
                <Input style={{margin:"2px"}}/>
              </Form.Item>
              <Form.Item
                name="phone_number"
                label={<span style={styles.labelsStyle}>Mobile Number (without prefix 0 or +91) <span style={styles.required}>*</span></span>}
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>
                  },
                  {
                    pattern: /^[6-9]\d{9}$/,
                    message: <span style={styles.errorStyle}>Enter valid mobile number</span>,
                  }
                ]}
              >
                <Input style={{margin:"2px"}}/> 
              </Form.Item>
              <Form.Item
                name="email"
                label={<span style={styles.labelsStyle}>E-mail Address <span style={styles.required}>*</span></span>}
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>,
                  },
                  {
                    type: "email",
                    message: <span style={styles.errorStyle}>Enter valid Email Address</span>,


                  }
                ]}
              >
                <Input style={{margin:"2px"}}/> 
              </Form.Item>
              <Form.Item
                name="Gender"
                label={<span style={styles.labelsStyle}>Gender  <span style={styles.required}>*</span></span>}
                style={styles.inputstyle}
                rules={[
                  {
                    required: true,
                    message:<span style={styles.errorStyle}>This field cannot be left empty</span>,
                  }
                ]}
              >
                <Radio.Group>
                  <div>
                    <Radio value="Male" disabled>
                      Male
                    </Radio>
                    <Radio value="Female" disabled>
                      Female
                    </Radio>
                    <Radio value="Other" disabled>
                      Other
                    </Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
              <div>
                {this.state.isEditableMode && (
                  <Button
                    variant="contained"
                    data-test-id="submit"
                    type="submit"
                    style={styles.submitbtn}
                  >
                    Save
                  </Button>
                )}
              </div>
            </div>
          </Form>
          <>

            {this.state.isDashModalOpen && (
              <Dialog open={this.state.isDashModalOpen} onClose={this.closeDashModal}>
                <Paper style={styles.success} elevation={0}>
                  <img src={successImg} style={styles.successImgStyle} alt="Success" />
                
                    <Typography style={styles.saved}>Saved Successful</Typography>
                    <Button onClick={this.closeDashModal} style={styles.submitbtn} color="primary">
                      Done
                    </Button>
                  
                </Paper>
              </Dialog>
            )}
          </>
        </div>
      </div>
    </div>
  );

  // Customizable Area End

  render() {
    // Customizable Area Start
    const userData = {
      domicile: this.state.user_id,
      email: this.state.email,
      address: this.state.address,
      user_name: this.state.user_name,
      phoneNumber: this.state.phoneNumber,
      state: this.state.state,
      pincode: this.state.pincode,
      Gender: this.state.gender,
      district: this.state.district,
      phone_number: this.state.mobile_number,
      telephone: this.state.full_phone_number,
      mobile_number: this.state.mobile_number
    };
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div style={styles.fromHolder}>
          {!this.state.loader && this.renderForm(userData)}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  formContainer: {
    width: "100%",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    borderRadius: "0.5rem",
    padding: "10px",
    margin: "10px",
    background:"linear-gradient(180deg, rgba(245, 247, 249, 0.71) 1.98%, #275080 100%)"
  },
  formContentContainer: {
    borderRadius: "0.5rem",
    background: "#fff",
    padding: "1rem"
  },
  formtitle: {
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "1.25rem"
  },
  errorStyle:{
   fontSize:"14px",
   padding:"1px",
   fontWeight:400
  },
  required:{
    color:"red"
  },
  inputstyle: {
    fontWeight: 600
  },
  fromHolder: {
    width: "100%"
  },
  submitbtn: {
    background:
      "var(--Gradient, linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%))",
    color: "white",
    borderRadius: "4px",
    padding: "7px, 16px, 7px, 16px",
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "30px",
    letterSpacing: "0em",
    width: "8.375rem",
    textTransform: "capitalize" as "capitalize"
  },
  labelsStyle:{
   fontSize:"16px",
   fontWeight:500
  },
  fieldTitle: {
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "1rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "1.125rem",
    display: "block"
  },
  userDetailsDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid black"
  },
  boxStyleUserName: {
    padding: "20px",
    background: "white"
  },
  fieldWrapper: {
    border: "none",
    padding: "16px",
    marginBottom: "20px"
  },
  successImgStyle: {
    width: '100%',
    height: 'auto',
    maxWidth: '235px',
    maxHeight: '175px',
  },
  uploadphotobtn: {
    background:
      "var(--Gradient, linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%))",
    color: "white",
    width: "158px",
    height: "49px",
    borderRadius: "10px"
  },
  perentDiv: { display: "flex" },
  editicon: {
    marginTop: "-12px",
    cursor:"pointer" as "pointer"
  },
  usernamediv: {
    display: "flex",
    alignItems: "center"
  },
  usericon: {
    marginTop: "12px"
  },
  usernametxt: {
    color: "rgba(0, 0, 0, 0.85)",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginBottom: "1.125rem",
    display: "block",
    marginTop: "15px"
  },
  useridtxt: {
    marginTop: "-13px",
    fontSize:"16px",
    color:"rgba(151, 141, 141, 0.85)"
  },
  profilephotodiv: {
    display: "flex",
    width: "70%",
    marginBottom: "30px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  usericondiv: {
    borderRadius: "80px",
    background: "#D5DFEB",
    width: "68px",
    height: "68px",
    textAlign: "center" as React.CSSProperties["textAlign"]
  },
  saved:{
    
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      padding:"14px",
     
    
    '@media (min-width: 600px)': {
      styleName: {
        fontSize: '18px',
        lineHeight: '20px',
        padding:"6px",

      },
    },
  },
  
  success: {
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
    flexDirection:"column" as "column",
    width: "25.25rem",
    height: "23.75rem",
    borderRadius:"16px"
  }
};
// Customizable Area End
