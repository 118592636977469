import React from "react";
// Customizable Area Start
import LandingPageController from "./LandingPageController.web";
import CarouselComp from "../../../components/src/CarouselComp.web";
import EmailLogin from "../../email-account-login/src/EmailLogin.web";
import * as IMG_CONST from './assets'
import AppTabs from "../../../components/src/AppTabs.web";
import { MdKeyboardArrowRight } from "react-icons/md"
import { Collapse } from "antd";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
const { Panel } = Collapse;
// Customizable Area End

class LandingPage extends LandingPageController {
    // Customizable Area Start
    renderWrapper = () => {
        return (
            <>
                <Header data-test-id="headerData"
                 isLogin={false}
                 onLogoutClick={undefined}
                 navigation={undefined} id={""} navData={this.state.navData} fontSizeHandler={this.fontSizeHandler} currentFontSize={this.state.currentFontSize} />
                <div className="container">
                    <div className={this.classHandler()} >
                        <main className="home-main">
                            <ul className="running-content-list">
                                <li className="running-content-list-item">
                                    3/2020 and Public Notice dated 18th January
                                </li>
                            </ul>
                            <div className="active-page">
                                <span className="active-page-name">home</span>
                                <span className="active-page-icon">
                                    <MdKeyboardArrowRight />
                                </span>
                            </div>
                            <div className="home-main-left">
                                <div className="carousel-container">
                                    <CarouselComp navigation={undefined} id={""} bannerData={this.state.bannerData} isLoading={false} />
                                </div>
                                <h3 className="read-title">Important! Please Read</h3>
                                <div className="information-container">
                                    <div className="read">
                                        <img src={IMG_CONST.importantImg} alt="important" />
                                        <p className="read-para">
                                            In view of the Hon’ble Supreme Court Order in Suo
                                            Motu Writ Petition (Civil) No. (3) 3/2020 and Public
                                            Notice dated 18th January, 2022 of the Office of the
                                            CGPDTM, the period from 15-03-2020 till 28-02-2022
                                            stands excluded for the purpose of limitation.
                                            However, in case the user faces any issues with
                                            respect to the above while using the e-filing system
                                            for the filing of the documents, they are requested
                                            to contact the IPO-HELPDESK at: Email:
                                            mailto:ipo-helpdesk@nic.in Telephone No(s): 011-25301243,
                                            011-25300201 and 011-28089556
                                        </p>
                                    </div>
                                    <div className="read">
                                        <img src={IMG_CONST.importantImg} alt="important" />
                                        <p className="read-para read-para-b">
                                            Please note that the payment gateway is not
                                            available between 2300 HOURS and 0030 HOURS in the
                                            e-filing module due to bank reconciliation process.
                                        </p>
                                    </div>
                                    <div className="tabs-container">
                                        <AppTabs navigation={undefined} id={""} />
                                    </div>
                                </div>
                                <div className="mImpRead">
                                    <Collapse accordion className="mHome-accordian">
                                        <Panel header="Important! Please Read" key="1">
                                            <div className="read">
                                                <img src={IMG_CONST.importantImg} alt="important" />
                                                <p className="read-para">
                                                    In view of the Hon’ble Supreme Court Order in
                                                    Suo Motu Writ Petition (Civil) No. (3) 3/2020
                                                    and Public Notice dated 18th January, 2022 of
                                                    the Office of the CGPDTM, the period from
                                                    15-03-2020 till 28-02-2022 stands excluded for
                                                    the purpose of limitation. However, in case the
                                                    user faces any issues with respect to the above
                                                    while using the e-filing system for the filing
                                                    of the documents, they are requested to contact
                                                    the IPO-HELPDESK at: Email: mailto:ipo-helpdesk@nic.in
                                                    Telephone No(s): 011-25301243, 011-25300201 and
                                                    011-28089556
                                                </p>
                                            </div>
                                            <div className="read">
                                                <img src={IMG_CONST.importantImg} alt="important" />
                                                <p className="read-para">
                                                    Please note that the payment gateway is not
                                                    available between 2300 HOURS and 0030 HOURS in
                                                    the e-filing module due to bank reconciliation
                                                    process.
                                                </p>
                                            </div>
                                        </Panel>
                                    </Collapse>
                                    <div className="mTabs-container">
                                        <AppTabs navigation={undefined} id={""} />
                                    </div>
                                </div>
                            </div>
                            <div className="home-main-right">
                                <EmailLogin   {...this.props}  />
                            </div>
                        </main>
                    </div>
                    <Footer navigation={undefined} id={""} footerNavData={this.state.footerNavData} />
                </div>
            </>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    render() {
        return (
            <>
                {this.renderWrapper()}
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default LandingPage
export { LandingPage }
// Customizable Area End

