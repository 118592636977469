import React from "react";
// Customizable Area Start
import EmailAccountRegistrationFormController from "./EmailAccountRegistrationFormController.web";
import { Form, Input, Radio, Select } from "antd";
import AppModal from "../../../components/src/AppModal.web";
const { Option } = Select;
import Alert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

// Customizable Area End

class EmailAccountRegistrationForm extends EmailAccountRegistrationFormController {
  // Customizable Area Start
  renderModal = () => (
    <AppModal
      data-test-id="signupFormModal"
      isModalOpen={this.state.isAuthModalOpen}
      modalCloseHandler={this.navigateLandingScreen}
      modalType={this.state.authModalType}
      id={""}
    />
  );

  renderForm = () => (
    <div className={"form-container"}>
      <div className="form-content-container">
        <p className="form-title">
          Fields marked with an asterisk * are Required.
        </p>
        <Form
          data-test-id="signUpSubmitBtn"
          className={this.classHandler()}
          name="sign-up"
          layout="vertical"
          onFinish={this.onFinish}
        >
          <div className="field-wrapper">
            <span className="field-title">Type of Application</span>
            <Form.Item
              name="user_type"
              rules={[{ required: true, message: "Selection Required" }]}
            >
              <Select
                data-test-id="selectTestId"
              >
                {this.state.applicationData.map((item: { attributes: { name: string } }, index: number) => (
                  <option key={index} value={item?.attributes?.name}>
                    {item?.attributes?.name}
                  </option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="field-wrapper ">
            <span className="field-title">Signature Details</span>
            <Form.Item
              name="eSign"
              label="Select you want to Register with:"
              rules={[{ required: true, message: "Selection Required" }]}
              className="sign-details-radio-container"
            >
              <Radio.Group>
                <Radio value="Electronics Signature" className="radio-input1">
                  Electronics Signature
                </Radio>
                <Radio value="Digital Signature">Digital Signature</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="sign-details-ques-ans-container">
              <div className="sign-details-ques-ans">
                <p className="sign-details-ques">
                  Q. What are Electronics Signatures?
                </p>
                <p className="sign-details-ans">
                  A. Please read here
                  <a
                    href="https://online.gov.in/epatentfiling/Extras/FAQESign.aspx"
                    target="_blank"
                    className="signatureLink"
                  >
                    {" "}
                    https://online.gov.in/epatentfiling/Extras/FAQESign.aspx
                  </a>
                </p>
              </div>
              <div className="sign-details-ques-ans">
                <p className="sign-details-ques">
                  Q. How to Procure Electronic Signature?
                </p>
                <p className="sign-details-ans">
                  A. At present M/s e-Mudhra, esign Service Provider has been
                  authorized and integrated to offer the service. Users may
                  visit the follow URL to procure esign for individual or
                  Organization:
                  <a
                    href="https://esign.e-mudhar.com/UI/subscripitionPlanNewUser.jsp?src=c44ead5fdecd48ecac24f"
                    target="_blank"
                    className="signatureLink"
                  >
                    {" "}
                    https://esign.e-mudhar.com/UI/subscripitionPlanNewUser.jsp?src=c44ead5fdecd48ecac24f
                  </a>
                </p>
              </div>
            </div>
            <div className="field-with-btn">
              <Form.Item
                name="eSignServiceProvider"
                label="Enter Esign ID (as registered with e-sign service provider)"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty"
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="field-with-btn">
              <Form.Item
                name="eSignID"
                label="Select name of Esign ID Service Provider"
                rules={[{ required: true, message: "Selection Required" }]}
              >
                <Select>
                  <Option value="one">one</Option>
                  <Option value="two">two</Option>
                  <Option value="three">three</Option>
                </Select>
              </Form.Item>

              <button
                type="button"
                className="form-field-btn mtMinus verify-btn"
              >
                verify now
              </button>
            </div>
          </div>

          <div className="field-wrapper">
            <span className="field-title">User Details</span>

            <div className="field-with-btn">
              <Form.Item
                name="user_id"
                label="User ID (not more than 10 Char)"
                rules={[
                  {
                    required: true,
                    message: "This field cannot be left empty"
                  }
                ]}
              >
                <Input
                  value={this.state.checkNowValue}
                  onChange={this.checkNowValueChange}
                  data-test-id="userIDChangeTestId"
                  maxLength={10}
                />
              </Form.Item>
              <button
                type="button"
                className="form-field-btn mtMinus check-now"
                onClick={() => this.checkNowApi()}              >
                check now
              </button>
            </div>
            <Form.Item
              data-test-id="password-input"
              name="password"
              label="Create Password"
              className="passowrd-field"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~]).{8,}$/,
                  message:
                    "To make your password more secure: Must contain at least one lower case letter, one upper case letter, one digit, one special character, and be at least 8 characters long."
                }
              ]}
            >
              <Input.Password
                maxLength={15}
              />
            </Form.Item>
            <Form.Item
              data-test-id="confirm-password-input"
              name="password_confirmation"
              label="Re-Type Password"
              dependencies={["password"]}
              className="create-passowrd-field"
              rules={[
                this.passwordErrorSignUpMatch,
                {
                  required: true,
                  message: "This field cannot be left empty"
                }
              ]}
            >
              <Input.Password
                maxLength={15}
              />
            </Form.Item>
          </div>

          <div className="field-wrapper">
            <span className="field-title">Field Details</span>

            <Form.Item
              name="domicile"
              label="Application Domicile/Place of Business"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^[a-zA-Z\s]{3,}$/,

                  message:
                    "Special characters/numbers are not allowed & minimum 3 characters are allowed"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="user_name"
              label="Name of Applicant"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^[a-zA-Z\s]{3,}$/,
                  message:
                    "Special characters/numbers are not allowed & minimum 3 characters are allowed"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="address"
              label="Address for Services (Form-Filing Purposes)"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="district"
              label="Town/City/District"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^[A-Za-z\s'-]{3,}$/,
                  message:
                    "Special characters/numbers are not allowed & minimum 3 characters are allowed"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="state"
              label="State/Union Territory"
              rules={[{ required: true, message: "Selection Required" }]}
            >
              <Select>
                {this.state.indianStates.map((state, index) => (
                  <option key={index} value={state.stateName}>
                    {state.stateName}
                  </option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="pincode"
              label="Pin Code(Enter Only valid Pin Code)"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^\d{6}$/,
                  message: "Enter a valid 6-digit PIN code"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="telephone"
              label="Telephone Number (with STD/ISD Code)"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern : /^\+?\d{10,14}$/,
                  message: "Enter valid telephone number"
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Mobile Number(without prefix 0 or +91)"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  pattern: /^[6-9]\d{9}$/,
                  message: "Enter valid mobile number"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              label="E-mail ID"
              rules={[
                {
                  required: true,
                  message: "This field cannot be left empty"
                },
                {
                  type: "email",
                  message: "Please enter a valid Email address"
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Selection Required" }]}
            >
              <Select>
                <Option value={0}>Male</Option>
                <Option value={1}>Female</Option>
                <Option value={2}>Other</Option>
              </Select>
            </Form.Item>
            <div className="sign-up-footer">
              <button
                className="form-field-btn sign-up-footer-preview-btn"
                type="button"
              >
                Preview
              </button>
              <button
                className="form-field-btn sign-up-footer-submit-btn"
                data-test-id="resetBtnTestId"
                onClick={this.handleReset}
              >
                Reset
              </button>
              <button
                className="form-field-btn sign-up-footer-submit-btn"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );

  // Customizable Area End

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: "top" }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        {this.renderModal()}
        {this.renderForm()}
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default EmailAccountRegistrationForm;
// Customizable Area End
