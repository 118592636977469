import React, { Component } from "react";
// Customizable Area Start
import { MdKeyboardArrowUp } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io"
import { Collapse } from 'antd'
type MyProps = { navigation: undefined; id: string; }
const { Panel } = Collapse;
// Customizable Area End
class AppAccordian extends Component<MyProps> {
    // Customizable Area Start
    renderAppAccordian = () => {
        return (
            <Collapse
                accordion
                className="app-accordian"
                expandIconPosition="end"
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <span className="collapse-arrow">
                            <MdKeyboardArrowUp />
                        </span>
                    ) : (
                        <span className="collapse-arrow">
                            <IoIosArrowDown />
                        </span>
                    )
                }
            >
                <Panel header="New to E-sign" key="1">
                    <p className="bottomHeader"> E Signature</p>
                    <p className="bottomHeader">Registration and installation details</p>
                    <p className="bottomHeader">Web URL to procure esign for individual or Organization</p>
                </Panel>
                <Panel header="New to DSC" key="2">
                    <p>namaste bharat</p>
                </Panel>
            </Collapse>
        )
    }
    
    render() {
        return (
            <>
                {
                    this.renderAppAccordian()
                }
            </>
        );
    }
    // Customizable Area End
}


// Customizable Area Start
export default AppAccordian
// Customizable Area End