import React from "react";

// Customizable Area Start
import { Form, Input } from "antd";
import ForgotPasswordController from "./ForgotPasswordController.web";
import { configJSON } from "./ForgotPasswordController";
import { Box, Snackbar } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import Alert from "@material-ui/lab/Alert";
// Customizable Area End

export class ForgotPassword extends ForgotPasswordController {

  renderForgotPasswordForm = () => {
    // Customizable Area Start
    return (
      <div>
        <div className="ForgotPassword-main">
          <h2 className="ForgotPassword-heading">{configJSON.forgotPassTxt}</h2>
          <div className="ForgotPasswordBtn-container">
            <button
              data-testid={"handleToggleBtn"}
              className={
                this.state.activeBtn
                ? "ForgotPasswordBtn-active forgotResponsive"
                : "ForgotPasswordBtn forgotResponsive"
              }
              onClick={this.handleMenuTabUserId}
            >
              {configJSON.userId}
            </button>
            <button
              data-testid={"handleDigitalSignatureToggleBtn"}
              className={
                this.state.activeBtn
                  ? "ForgotPasswordBtn forgotResponsive"
                  : "ForgotPasswordBtn-active forgotResponsive"
              }
              
              onClick={this.handleMenuTabDigitalSignature}
            >
              {configJSON.digitalSignature}
            </button>
          </div>

          <Form
            data-testid="emailID"
            name="basic"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 16 }}
            style={{
              maxWidth: 700
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            className="ForgotPassword-form"
            onFinish={this.onFinish}
          >
            <Form.Item
              label="Registered Email_Id"
              name="email_id"
              rules={[
                { required: true, message: "Registered Email_Id is required" },
                {
                  type: "email",
                  message: "Please enter a valid Email_Id",
                },
              ]}
            >
              <Input name="email_id" 
              className="emailTxt"
              />
            </Form.Item>

            <Form.Item
              label="User ID"
              name="user_id"

              rules={[
                {
                  required: true,
                  message: "User ID is required"
                },

              ]}
            >
              <Input name="user_id"
              className="emailTxt"
              />
            </Form.Item>
            <div className="forgotPassword-submitbtn-container">
              <button
                type="submit"
                className={"ForgotPasswordBtn-active"}
              >
                {configJSON.resetButtonTxt}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
    // Customizable Area End
  };

  renderSuccessMessage = () => {
    // Customizable Area Start
    return (
      <div className="forgotPasswordSuccessMessage">
        <h2 className="forgotPasswordSuccessMessage-heading">
          {configJSON.resetLinkSndTxt}
        </h2>
        <div className="forgotPasswordSuccessMessage-container">
          <p className="forgotPasswordSuccessMessage-message">
            {configJSON.resetPassTxt}
            <span className="forgotPasswordSuccessMessage-email">
              {"  "}
              {this.state.email}
            </span>
          </p>
          <p className="forgotPasswordSuccessMessage-message">
            {configJSON.noteReset}
          </p>
        </div>

        <div className="forgotPasswordSuccessMessage-btnContainer">
          <button
            data-testid={"loginRedirection"}
            onClick={this.handleLoginRedirect}
            className={"ForgotPasswordBtn-active"}
          >{configJSON.loginBtnTxt}</button>
        </div>
      </div>
    );
    // Customizable Area End
  };

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Snackbar
          open={this.state.isAlert}
          anchorOrigin={{ horizontal: 'right', vertical: "top" }}
          onClose={this.oncloseAlert}
          autoHideDuration={3000}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Header
          id={""}
          navData={this.state.navData}
          fontSizeHandler={this.fontSizeHandler}
          currentFontSize={this.state.currentFontSize}
          navigation={undefined}
          data-test-id="fontSzieHandler"
          isLogin={false}
          onLogoutClick={undefined}
        />
        <div className="container">
          <div  className="signupContainer">
            <Box className="innerBox">
      <div className="digitalSignatuer">
        {this.state.showSuccessMesage ?
          this.renderSuccessMessage()
          :
          this.renderForgotPasswordForm()
        }
      </div>
            </Box>
              <Footer
                navigation={undefined}
                id={""}
                footerNavData={this.state.footerNavData}
              />
          </div>
        </div>
      </Box>
    );
    // Customizable Area End
  }
}

export default ForgotPassword;
