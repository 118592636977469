import React, {Component} from "react";
// Customizable Area Start
import * as IMG_CONST from './assets'
type MyProps = { title: string;activePage:string; activeLink: string; navigation: undefined; id: string;};
// Customizable Area End

class Banner extends Component<MyProps> {
    // Customizable Area Start
    renderBanner = () => {
        const { title, activeLink,activePage } = this.props;
        return (
            <div className="banner">
                <div className="banner-content">
                    <h2 className="banner-title">{title}</h2>
                    <div className="banner-link">
                        <a href="/" className="banner-active-link">{activeLink}</a>
                        <img className="down-arrow" src={IMG_CONST.downArrowImg} alt="down arrow" />

                        <span>{activePage}</span>
                    </div>
                </div>
                <div className="banner-img">
                    <img src={IMG_CONST.banner} alt="banner" />
                    <div className="overlay"></div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <>
                {this.renderBanner()}
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
export default Banner
// Customizable Area End


