// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { message as MESSAGE, notification } from "antd";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface NavItem {
    link: string;
    path: string;
}

interface FooterNavItem {
    name: string;
    link: string;
}

interface ValidResponseType {
    message: object;
    data: object;
}

interface InvalidResponseType {
    errors: Array<string>;
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface PrivacyPolicy {
    data: {
        id: string;
        type: string;
        attributes: {
            title: string;
            sub_title: string;
            description: string;
        }
    }
}
// Customizable Area End

interface S {
    // Customizable Area Start
    navData: NavItem[];
    currentFontSize: string;
    footerNavData: FooterNavItem[];
    privacyPolicyData: PrivacyPolicy;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PrivacyPolicyController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    privacyPolicyAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        this.state = {
            navData: [
                { 
                    link: "Home", path: "/" 
                },
                { 
                    link: "FAQs", path: "/faqs" 
                },
                { 
                    link: "Feedback", path: "/feedback" 
                },
                { 
                    link: "Sign Up", path: "/signUp"
                 }
            ],
            currentFontSize: "16px",
            footerNavData: [
                { name: "Terms and Conditions", link: "/terms-conditions" },
                { name: "Privacy Policy", link: "/privacy-policy" },
                { name: "Copyright", link: "" },
                { name: "Hyperlinking Policy", link: "" },
                { name: "Accessibility", link: "" },
                { name: "Archive", link: "" },
                { name: "Contact Us", link: "/contact-us" },
                { name: "Sitemap", link: "" }
            ],
            privacyPolicyData: {
                data: {
                    id: "",
                    type: "",
                    attributes: {
                        title: "",
                        sub_title: "",
                        description: ""
                    }
                }
            }
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.getPrivacyPolicyApi();
    }
    
    fontSizeHandler = (size: string) => {
        const root = document.documentElement;
        root.style.setProperty("--base-font-size", size);
        this.setState({
            currentFontSize: size,
        });
        return void 0;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (responseJson.status === 500) 
            {
                MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
                return;
            }
            if (this.isValidResponse(responseJson)) 
            {
                this.responsePrivacyPolicySucessCall(apiRequestCallId, responseJson);
            }
            if (responseJson && responseJson.error_message) 
            {
                this.responsePrivacyPolicyFailureCall(apiRequestCallId, responseJson);
            }
            else if (errorReponse) {
                notification["error"]({
                    message: "Internal Server Error.",
                });
            }
        }
    }

    privacyPolicyApiCall = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = 
        {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
            );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && type !== "formData"
            ? requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage), body
            );
            
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    
    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    responsePrivacyPolicySucessCall = async (apiRequestCallId: string, responseJson: PrivacyPolicy) => {
        if (apiRequestCallId === this.privacyPolicyAPICallId) {
            this.privacyPolicyApiSucessCallBack(responseJson);
        }
    };

    privacyPolicyApiSucessCallBack = (response: PrivacyPolicy) => {
        this.setState({ privacyPolicyData: response })
    };

    responsePrivacyPolicyFailureCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.privacyPolicyAPICallId) {
            this.responsePrivacyPolicyFailureCallBack();
        }
    };

    responsePrivacyPolicyFailureCallBack = () => {
        alert("API Error")
    };

    getPrivacyPolicyApi = async () => {
        this.privacyPolicyAPICallId = await this.privacyPolicyApiCall({
            contentType: configJSON.privacyPolicyContentType,
            method: configJSON.privacyPolicyApimethod,
            endPoint: configJSON.privacyPolicyEndPoint
        });
    }
    // Customizable Area End
}
