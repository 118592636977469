import React from "react";

// Customizable Area Start
import { Form, Input, Modal } from "antd";
import ResetPasswordController from "./ResetPasswordController.web";
import { Box, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Footer from "../../../components/src/Footer.web";
import Header from "../../../components/src/Header.web";
export const configJSON = require("./config");
import * as IMG_CONST from "./assets";
// Customizable Area End

export default class ResetPassword extends ResetPasswordController {

  renderResetSuccessModal = () => {
    // Customizable Area Start
    return (
      <div >
        <Modal
          open={this.state.resetSuccessModal}
          footer={null}
          centered={true}

          closable={false}
          className="antd_modal"
        >
          <div className="modal_main_div">
            <img src={IMG_CONST.successImg} className="successImg" />
            <span>{configJSON.successChangePassword}</span>
            <button
              data-testid={"doneButtonId"}
              onClick={this.resetSuccessMessageShow}
            >{configJSON.doneTxt}</button>
          </div>
        </Modal>
      </div>
    )
    // Customizable Area End
  }

  renderResetPassword = () => {
    // Customizable Area Start
    return (
      <>
        {this.state.isResetActive ? <>
          <div className="ResetPassword-mainOne">
            <h2 className="ResetPassword-headingOne">Token is expired, please go back</ h2>
            <button onClick={this.handleBackFunc} className="ResetPasswordBtnBackButton">
              {configJSON.backBtn}
            </button>
          </div>
        </> : <div className="ResetPassword-mainOne">
          <h2 className="ResetPassword-headingOne">{configJSON.resetPasswordTxt}</h2>
          <Form
            data-testid={"digitalSignatureForm"}
            name="basic"
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 16 }}
            style={{
              maxWidth: 700
            }}
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
            className="ResetPassword-form"
            onFinish={this.onFinish}
          >
            <Form.Item label="Create New Password" name="password"
              className="emailInputTxt"
              rules={[
                {
                  message: "Please enter your password!",
                  required: true
                },
                this.passwordFeildCheck
              ]}
              validateFirst
            >
              <Input.Password
                className="inputFieldResetPasword"
                data-testid="passwordId"
                onChange={this.createPassword}
                value={this.state.password}
                maxLength={15}
              />
            </Form.Item>
            <Form.Item
              dependencies={["createPassword"]}
              label="Repeat Password"
              name="retypePassword"
              rules={[
                {
                  message: "Please re-enter your password!",
                  required: true
                },
                this.passwordFeild
              ]}
              validateFirst
            >
              <Input.Password
                className="inputFieldResetPasword"
                data-testid="confirmPasswordID"
                value={this.state.retypePassword}
                onChange={this.confirmPassword}
                maxLength={15}
              />
            </Form.Item>
            <>
              <div className="validationMessagesContainer">
                <div className="validationImageShow">
                  <img
                    src={
                      this.atLeastAllImgCheck()}
                  />
                  <p
                    className={
                      this.atLeastAllCheckCss()
                    }
                  >
                    {configJSON.characterLengthTxt}
                  </p>
                </div>
                <div>
                  <div className="validationImageShow">
                    <img
                      src={
                        this.shouldContainImgCheck()}
                    />
                    <span
                      className={
                        this.shouldContainCheck()
                      }
                    >{configJSON.shouldContainTxt}
                    </span>
                  </div>
                  <div className="shouldContainBox">
                    <div className="validationImageShow">
                      <img src={
                        this.lowerCaseImgCheck()
                      }
                      />
                      <p
                        className={
                          this.lowerCaseCheck()
                        }
                      >
                        {configJSON.lowerCaseTxt}
                      </p>
                    </div>
                    <div className="validationImageShow">
                      <img src={
                        this.upperCaseImgCheck()}
                      />
                      <p
                        className={
                          this.upperCaseCheck()
                        }
                      >
                        {configJSON.upperLatterTxt}
                      </p>
                    </div>
                    <div className="validationImageShow">
                      <img src={
                        this.digitImgCheck()}
                      />
                      <p
                        className={this.digitValidCheck()}
                      >
                        {configJSON.leastDigitTxt}
                      </p>
                    </div>
                    <div className="validationImageShow">
                      <img src={
                        this.specialCharImgCheck()}
                      />
                      <p
                        className={this.renderValidUnValidaCss()}
                      >
                        {configJSON.specialCharacterTxt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="ResetPasswordBtnContainer">
              <button type="submit" className={"ResetPasswordBtn"}>
                {configJSON.submitBtnText}
              </button>
              <button onClick={this.handleBackFunc} className="ResetPasswordBtnBackButton">
                {configJSON.backBtn}
              </button>
            </div>
          </Form>
        </div>}
      </>
    );
    // Customizable Area End
  };

  render() {
    // Customizable Area Start
    return (
      <Box>
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          data-test-id="alertTestId"
          anchorOrigin={{ horizontal: 'right', vertical: "top" }}
          onClose={this.oncloseAlert}
        >
          <Alert severity={this.state.alertType}>{this.state.alertMsg}</Alert>
        </Snackbar>
        <Header
          navigation={undefined}
          id={""}
          navData={this.state.navData}
          currentFontSize={this.state.currentFontSize}
          fontSizeHandler={this.fontSizeHandler}
          data-test-id="fontSzieHandler"
          isLogin={false}
          onLogoutClick={undefined}
        />
        <div className="container">
          <div className="signupContainer">
            <Box className="innerBox">
              {this.renderResetPassword()}
              {this.renderResetSuccessModal()}
            </Box>
            <Footer
              id={""}
              navigation={undefined}
              footerNavData={this.state.footerNavData}
            />
          </div>
        </div>
      </Box>
    );
    // Customizable Area End
  }
}
