import React from "react";
// Customizable Area Start
import {
  Box, Typography, withStyles,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  FormControl,
  Link,
  MenuItem,
  Paper,
  Select,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Input,
  Modal
} from "@material-ui/core";
import MultipageForms from "../../multipageforms/src/MultipageForms.web";
import { ExpandMore, ChevronRight, Search } from "@material-ui/icons";
import Pagination from '@material-ui/lab/Pagination';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HeaderFormBanner, closeimg, headerLeft } from "./assets";
import { DiscardedFormComponent } from "./DiscardedForm.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import moment from "moment";
import ChangePassword from "../../forgot-password/src/ChangePassword.web"
import UserProfileBasicBlockForm from "../../user-profile-basic/src/UserProfileBasicBlockForm.web";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderHeaderBanner = () => {
    return (
      <Box className="banner">
        <Box sx={webStyles.nameBreadcrumbDiv}>
          <UserRegisteredName>{webConfigJSON.userName}</UserRegisteredName>
          <Box sx={webStyles.mainFormSelectBannerShow}>
            <Box sx={webStyles.formSelectBannerShow}>
              <FormSelectedShowText>
                {webConfigJSON.pageLocation}
              </FormSelectedShowText>
              {this.state.expanded && <ChevronRight />}
              <FormSelectedShowText
                data-testid="nameBreadTestID">
                {this.state.expanded}
              </FormSelectedShowText>
              {this.state.expanded && <ChevronRight />}
            </Box>
            <FormSelectedShowText
              data-testid="selectedFormNameTestID">
              {this.state.newApplicationSelect}
            </FormSelectedShowText>
          </Box>
        </Box>
        <Box sx={webStyles.bannerImgForm}>
          <Box sx={webStyles.overlay}></Box>
          <img src={HeaderFormBanner} style={webStyles.bannerImgHide} alt="HeaderIcon" />
        </Box>
      </Box>
    )
  }

  renderFormsLayout = () => {
    return (
      <Box sx={webStyles.dashboardFormLayoutMainDiv}>
        <Box sx={webStyles.dashbordFormLowerDiv}>
          <Box sx={webStyles.dashbordFormLowerLeftDiv}>
            <Box sx={webStyles.dashboardApplicationWrapperDiv}>
              {this.state.newApplications.map((item: any, index: number) => {
                return (
                  <Accordion key={index}
                    expanded={this.state.expanded === `${item.value}`}
                    data-testid={`formSelectId-${index}`}
                    onChange={() => this.handleChange(item.value)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content1"
                      id="panel1d-header1"
                      expandIcon={<ExpandMore style={webStyles.expandMoreIcon} />}
                      style={webStyles.accordionSummaryStyle}
                    >
                      <MainFormText>
                        {item.value}
                      </MainFormText>
                    </AccordionSummary>
                    <AccordionDetails style={webStyles.accordionStyles}>
                      <Box sx={webStyles.formUnderFormSelect}>
                        {item.deferentForms?.map((item: { formSelect: string }, index: number) =>
                        (
                          <Box
                            key={index}
                            sx={webStyles.formBorder}
                            data-testid={`subFormSelect-${index + 1}`}
                            onClick={() => this.formTypeSelect(item.formSelect)}
                          >
                            <SubFormSelectText key={item.formSelect}>
                              {item.formSelect}
                            </SubFormSelectText>
                          </Box>
                        ))
                        }
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  expandiconset = () => {
    return <ExpandMoreIcon />
  }

  renderMenuItemSearchPatentTable = () => {
    const menuItemSearchItem: any[] = this.state.searchResultsArr?.length > 0 ? [10, 15, 20, 25, 30] : [0];
    return menuItemSearchItem.map((item: any, i: number) => (
      <MenuItemDisplay value={item} style={webStyles.searchResultMenuItem}>{item}</MenuItemDisplay>
    ))
  }

  renderDateSearchPatent = (originalDate: any) => {
    let parsedDate = moment(originalDate);
    let formattedDate = parsedDate.format('MMM DD, YYYY');
    return formattedDate;
  }

  renderSearchComponentRender = () => {
    if (this.state.searchState === true) {
      return <Grid container style={webStyles.searchFormContainer}>
        <Grid item lg={12} md={12} style={{ width: '100%' }}>
          <Box style={webStyles.searchFormSubContainer}>
            <Typography style={webStyles.searchFormHeading}>Search Patent Result For Healthcare</Typography>
            <Box display={'flex'} style={{ paddingTop: '5px' }}>
              <Typography style={webStyles.searchWithTitle}>Search with :</Typography>
              <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                <RadioGroup data-testid="radioSearchBtn" style={webStyles.searchRadioGroup} value={this.state.searchFormRadio} onChange={this.handleSearchFormRadioChange}>
                  <FormControlLabel style={webStyles.searchRadiolabel} value="Patent Name" control={<Radio style={webStyles.radioBtnColor} />} label="Patent Name" />
                  <FormControlLabel style={webStyles.searchRadiolabel} value="Patent Number" control={<Radio style={webStyles.radioBtnColor} />} label="Patent Number" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box style={webStyles.searchInputBoxContainer}>
              <TextField variant="outlined" data-testid="handleSearchValueChange" style={webStyles.searchBoxInput} value={this.state.searchValue} onChange={this.handleSearchValueChange} onKeyDown={this.handleKeyDownSearchPatent} />
              <Search data-testid="handleAllSearchByPatent" style={webStyles.searchIcon} onClick={this.handleAllSearchByPatent} />
            </Box>
            <Box width={'100%'} display="flex" marginTop={"32px"} justifyContent={'center'} marginBottom={"40px"}>
              <Button style={webStyles.buttonBack}>Back</Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    } else if (this.state.searchResultTable === true) {

      return <><Grid container style={webStyles.searchResultContainer}>
        <Grid item lg={12} md={12} style={{ width: '100%' }}>
          <Box style={webStyles.searchResultSubContainer}>
            <Box display={"flex"} >
              <img src={require('../assets/image_left_arrow.png')} data-testid="handleBackToSearchPatent" onClick={this.handleBackToSearchPatent} style={webStyles.leftArrow} />
              <Typography style={webStyles.searchFormHeadingResult2}>Search Patent Result For Healthcare</Typography>
            </Box>
            <Box display={'flex'} alignItems={"baseline"} style={{ margin: '15px 15px 20px 15px' }}>
              <Typography style={{ fontSize: '18px', fontWeight: 400 as 400, marginRight: '12px', }}>
                Result Showing
              </Typography>
              <Select disableUnderline={true}
                displayEmpty={true}
                data-testid="searchResultPerPage"
                defaultValue={this.state.searchResultPerPage}
                variant="outlined" value={this.state.searchResultPerPage}
                style={webStyles.searchItemSelect}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginLeft: '-11px',
                      width: '75px', top: '45px',
                    }
                  },
                }}
                onChange={this.handleSearchResultPerPage} input={<Input style={{ padding: '4px 4px 4px 11px' }} />}
              >
                {this.renderMenuItemSearchPatentTable()}
              </Select>
              <Box>
                <Typography style={{ marginLeft: '12px' }}>Out of {this.state.searchResultsArr?.length}</Typography>
              </Box>
            </Box>
            <Box>
              <TableContainer>
                <Table>
                  <TableHead style={{ backgroundColor: '#E2E8F6' }}>
                    <TableRow>
                      <TableCell>PATENT NO.</TableCell>
                      <TableCell>APPLICATION DATE</TableCell>
                      <TableCell>PATENT NAME</TableCell>
                      <TableCell>INVENTOR NAME</TableCell>
                      <TableCell>PUBLICATION DATE</TableCell>
                      <TableCell>ABSTRACT</TableCell>
                      <TableCell>CLAIMS</TableCell>
                    </TableRow>
                  </TableHead>
                  {this.state.searchResultsArr?.length !== 0 &&
                    this.state.searchResultsArr?.slice(
                      this.state.searchResultPerPage *
                      (this.state.currentPageSearchPatent - 1),
                      this.state.searchResultPerPage *
                      this.state.currentPageSearchPatent
                    ).map((data: any) => {
                      return <TableBody key={data?.id}>
                        <TableRow style={{ height: '84px' }}>
                          <TableCell>{data?.attributes?.reference_number}</TableCell>
                          <TableCell>{this.renderDateSearchPatent(data?.attributes?.created_at)}</TableCell>
                          <TableCell style={{ width: '300px' }}>{this.renderPatentName(data?.attributes?.form_sections)}</TableCell>
                          <TableCell>{this.getInventerName(data?.attributes?.form_sections)}</TableCell>
                          <TableCell>{this.renderDateSearchPatent(data?.attributes?.created_at)}</TableCell>
                          <TableCell><Button data-testid="abstractBtn" style={webStyles.btnViewPatent} onClick={(e) => {
                            e.preventDefault()
                            this.handleOpenSearchViewModel(data, e)
                          }}>View</Button></TableCell>
                          <TableCell><Button data-testid="claimBtn" style={webStyles.btnViewPatent} onClick={this.handleOpenSearchViewModel.bind(this, data)
                          }>View</Button></TableCell>
                        </TableRow>
                      </TableBody>
                    }
                    )
                  }
                </Table>
                {this.state.searchResultsArr?.length === 0 && <><div style={{ display: 'flex', alignItems: "center", justifyContent: "center", padding: '32px 0px 32px 0px' }}>
                  <Typography color="error" style={{ fontSize: '18px', fontWeight: 500 as 500, textAlign: 'center' as 'center' }}>No Record Found</Typography>
                </div>
                  <Box width={'100%'} display="flex" justifyContent={'center'} marginBottom={"36px"}>
                    <Button data-testid="noRecordBackToSearchPatent" style={webStyles.searchButtonBack} onClick={this.handleBackToSearchPatent}>Back</Button>
                  </Box></>}
              </TableContainer>
            </Box>
            {this.state.searchResultsArr?.length !== 0 ? <PaginationDisplay data-testid="searchPatentPagination" count={this.state.totaltPageSearchPatent} page={this.state.currentPageSearchPatent} onChange={this.handleChangePageSearchPatent} variant="outlined" style={webStyles.paginationStyle} /> : <></>}
          </Box>
        </Grid>
      </Grid>
        <Modal
          BackdropProps={{
            invisible: true, style: webStyles.modalBackDropStyle,
          }}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          open={this.state.searchPatentModel} onClose={this.handleCloseModalPatent}>
          <Box style={webStyles.searchModalViewContainer}>
            <Box style={{...webStyles.searchModalViewSubContainer, overflowY: 'scroll'}}>
              <Box>
                <Box style={webStyles.crossImgStyle}>
                  <img src={closeimg} style={webStyles.closeImgStyle} onClick={this.handleCloseModalPatent} />
                </Box>
                <Box marginTop={'24px'} marginLeft={'16px'}>
                  <img src={headerLeft} style={webStyles.headerLeftStyle} />
                </Box>
              </Box>
              <Grid container style={{ paddingTop: '30px', paddingBottom: '35px' }}>
                <Box display={'flex'} alignItems="centeer" height={"fit-content"} justifyContent="space-evenly" paddingLeft='20px' >
                  <Typography style={webStyles.paddingLeft16}>(12)</Typography>
                  <Typography style={webStyles.governTypography}>Government of India Patent</Typography>
                </Box>
                <Box display={'flex'} alignItems="baseline" style={{ paddingLeft: '113px',paddingTop: '6px', }}>
                  <Typography style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>(10)</Typography>
                  <Typography style={webStyles.typography18px}>Patent No:</Typography>
                  <Typography style={webStyles.patentNoStyle}>IN-1023948</Typography>
                </Box>
                <Box width={'100%'} display={"flex"} justifyContent={"space-between"} mt={'12px'}>
                  <Box display={'flex'} alignItems="center" marginLeft={"51px"}>
                    <Typography style={webStyles.typography18px}>Mrs. Ritu Desai</Typography>
                  </Box>
                  <Box display={'flex'} alignItems="center" mr={"26px"}>
                    <Typography>(45)</Typography>
                    <Typography style={webStyles.typography18px}>Date of Patent:</Typography>
                    <Typography style={webStyles.datePatentStyle}>{this.state.searchCreatedDate}</Typography>
                  </Box>
                </Box>
                <hr style={webStyles.horizontalRule} />
                <Grid
                  container
                  spacing={2}
                  style={{ width: "95%", margin: "auto", marginTop: "24px" }}
                >
                  <Grid xs={12} sm={10} md={6} lg={6} style={{ display: "grid" }}>
                    <Box style={{ width: "90%", display: "grid" }}>
                      <div style={{ display: "flex", height: "fit-content" }}>
                        <Typography>(51)</Typography>
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                        >
                          Remote vital signs monitoring via smartphone
                          integrationodi.
                        </Typography>
                      </div>
                      <div style={{ display: "flex", height: "fit-content" }}>
                        <Typography>(52)</Typography>
                        <div>
                          <Typography
                            style={{ fontWeight: 400, marginLeft: "10px" }}
                          >
                            Applicant: <b style={webStyles.boldPadding5px}> {this.state.applicantName}, </b>New Delhi
                            (IND)
                          </Typography>
                        </div>
                      </div>

                      <div style={{ display: "flex", height: "fit-content" }}>
                        <Typography>(54)</Typography>
                        <div>
                          <Typography
                            style={{ fontWeight: 400, marginLeft: "10px" }}
                          >
                            Inventor: <b style={webStyles.boldPadding5px}> {this.state.applicantName}, </b>New Delhi
                            (IND)
                          </Typography>
                        </div>
                      </div>

                      <div style={{ display: "flex", height: "fit-content" }}>
                        <Typography>(55)</Typography>
                        <div>
                          <Typography
                            style={{ fontWeight: 500, marginLeft: "10px" }}
                          >
                            Appl.No: <b style={webStyles.boldPadding5px}> {this.state.applicantNumber} </b>
                          </Typography>
                        </div>
                      </div>

                      <div style={{ display: "flex", height: "fit-content" }}>
                        <Typography>(56)</Typography>
                        <div>
                          <Typography
                            style={{ fontWeight: 500, marginLeft: "10px" }}
                          >
                            Filed: <b style={webStyles.boldPadding5px}> {this.state.searchCreatedDate} </b>
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  </Grid>

                  <Grid xs={12} sm={10} md={6} lg={6}>
                    <div style={{ display: "flex", height: "fit-content" }}>
                      <Typography>(58)</Typography>
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: "18px",
                          marginLeft: "138px",
                        }}
                      >
                        ABSTRACT
                      </Typography>
                    </div>
                    <Typography style={{ padding: "10px" , paddingLeft:'15px', position:'relative', top:'20px'}}>
                      This patent describes a method and system for remote monitoring of vital signs using smartphones. Wearable sensors collect and transmit health data, like heart rate and blood pressure, to the user's smartphone. A mobile app displays real-time and historical vital sign trends, providing convenient health monitoring and alerts. This integration offers a user-centric solution to enhance healthcare management.
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{ margin: "25px auto 15px auto", width: "95%", }}
                >
                  <Grid xs={6} sm={6} md={6} lg={6}>
                    <Typography style={{height:'19px'}}>
                      Date:
                      <span style={{ fontWeight: 600, paddingLeft:'12px' }}>{this.state.searchCreatedDate}</span>
                    </Typography>
                    <Typography style={{height:'19px', paddingTop:'24px'}}>
                      Place: <span style={{ fontWeight: 600 }}>{this.state.applicantCity}</span>
                    </Typography>
                  </Grid>
                  <Grid xs={6} sm={6} md={6} lg={6} style={{ textAlign: "end" }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Modal >
      </>
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box style={webStyles.handleBanner}>
        <Box sx={webStyles.handleHeader}>
          <Header
            navigation={undefined}
            id={""}
            navData={this.state.dashboardnavData}
            fontSizeHandler={this.sizehandler}
            currentFontSize={this.state.currentFontSize}
            isLogin={true}
            onLogoutClick={this.onLogoutClick}
            data-testid="logoutBtn"
          />
        </Box>
        <Box sx={webStyles.bannerStyle}>
          {this.renderHeaderBanner()}
        </Box>
        <Box sx={webStyles.handleFormLauout}>

          {this.renderFormsLayout()}

          {this.state.patentState ?

            <Box style={webStyles.mainContainer}>

              <Paper style={webStyles.application} elevation={0}>
                <Box>

                  <Link underline="always" style={webStyles.form_title} >Add Applications already filed under SIPP scheme</Link>
                  <FormControl fullWidth style={{ marginTop: "6px" }}>
                    <Typography style={webStyles.select_label}>Select Form</Typography>

                    <Select style={webStyles.select}
                      data-testid="selectingOption"
                      disableUnderline
                      defaultValue={this.state.selectedOptionValue}
                      value={this.state.selectedOptionValue}
                      onChange={this.handleOptionValue}
                      inputProps={{
                        name: 'select',
                        id: 'my-select',
                      }}
                      IconComponent={this.expandiconset}
                    >
                      <MenuItem value="Form-1">Form 1</MenuItem>
                    </Select>
                    <Typography style={webStyles.details}>Need help in selecting a form? <Link underline="always" style={webStyles.sub_details}>Click here</Link> </Typography>
                    <div style={webStyles.btndiv}>

                      <Button data-testid="submit" onClick={() => this.onSubmit()} style={webStyles.submit}>Submit</Button>
                    </div>
                  </FormControl >
                </Box>
              </Paper>
            </Box>

            : null}
          {this.state.discardState &&
            <DiscardedFormComponent
              data-testid="discardedComp"
              {...this.props}
              selectedForm={this.state.newApplicationSelect}
              handleCheckboxChange={this.handleCheckboxChange}
              data={this.state.discardedData}
              previewData={this.state.previewData}
              searchForm={this.searchForm}
              onPagination={this.handlePagination}
              handleDiscard={this.handleDiscardbuttonClick}
              handleEdit={this.handleEditbuttonClick}
              handleCheck={this.state.previewCheck}
              discardCheck={this.state.discardCheck}
              checkAll={this.state.checkAll}
              handleCheckboxAll={this.handleCheckboxAll}
              totalPages={this.handlePages()}
              activePage={this.state.currentPage}
              showModel={this.state.openSavedModal}
              closeModel={this.handleCloseModel}
              successMsg={this.state.successDataMsg}
              selectedButton={this.state.selectedButton}
            />
          }

          {this.state.newApplication && <MultipageForms 
            data-testid='multipage-form'
            formId={this.state.formId}
            handleToggle={(formType:string)=>this.setState({
              newApplication:false,
              discardState:true,
              newApplicationSelect:formType === 'submit' ?'Submitted Forms':'Drafted Forms',
              expanded:'Form History'
            })
            }
           {...this.props} />}
          {this.renderSearchComponentRender()}
          {this.state.changePassword && <ChangePassword />}
          {this.state.userState && <UserProfileBasicBlockForm navigation={undefined} id={""} isEditableMode={false} currentFontSize={""} navdata={undefined} footernavData={undefined} />}
        </Box>

        <Box sx={webStyles.footer}>
          <Footer
            navigation={undefined}
            id={""}
            footerNavData={this.state.footerdashboardnavData}
          />
        </Box>

      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  application: {
    padding: "40px",
    height: '353px',
    width: "100%"
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    padding: "10px",
    borderRadius: "8px",
    background: "linear-gradient(136.2deg, rgb(205, 211, 219) -6.59%, rgb(202, 214, 217) 105.03%)",
    marginTop: '10px',
    marginLeft: '10px',
  },
  bannerImgForm: {
    position: "relative",
    height: "100%",
    flex: "0.5"
  },
  overlay: {
    backgroundColor: "linear-gradient(13deg,rgb(255 255 255) 18%,rgb(255 255 255 / 78%) 29%,#ffffff00 50%)",
    position: "absolute",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    zIndex: "4"
  },

  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  handleHeader: {

  },
  bannerStyle: {
    margin: "2%",
  },
  select: {
    border: "1px solid #979797",
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: "4px",
    padding: "4px",

    ' &.MuiSvgIcon-root-393': {
      visibility: 'hidden'
    },
  },
  select_label: {
    fontSize: "1.1rem",
    fontWeight: 590,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left" as "left",
    marginTop: "22px",
    marginBottom: "16px"
  },
  details: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "#0b336a",
    marginTop: "10px",
    textAlign: "left" as "left"
  },
  sub_details: {
    fontSize: "1.1rem",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "#0b336a",
    marginTop: "10px",
    textAlign: "left" as "left"
  },
  submit: {
    marginTop: "20px",
    borderRadius: "4px",
    width: "201px",
    height: "44px",
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "18.75px",
    background: `linear-gradient(to bottom, #002E6A, #708990)`,
    color: "#fff",
    textTransform: "capitalize" as "capitalize",

  },
  btndiv: {
    textAlign: "center" as "center",
    marginTop: "20px"
  },
  form_title: {
    fontSize: "2rem",
    color: "#000",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing: "0px",
    textAlign: "left" as "left",
    textDecortaion: "underline"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  handleBanner: {
    backgroundImage: "linear-gradient(to bottom, #F5F7F9, #275080)",
    minHeight: '100vh',
    height: 'auto !important',
  },
  handleFormLauout: {
    display: "flex",
    flexDirection: "row",
    margin: "2%",
    marginTop: "20px",
    "@media (max-width: 965px)": {
      flexDirection: "column"
    }
  },
  dashboardFormLayoutMainDiv: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
    paddingLeft: "0px",
    "@media (max-width: 965px)": {
      padding: "0.5rem 0px 0px 0px"
    }
  },
  nameBreadcrumbDiv: {
    display: "flex",
    flexDirection: "column",
    color: "#275080",
    gap: "8px",
    minHeight: "40px"
  },
  mainFormSelectBannerShow: {
    display: "flex",
    alignItems: "flex-start",
    "@media (max-width: 520px)": {
      flexDirection: "column"
    }
  },
  formSelectBannerShow: {
    display: "flex",
    alignItems: "center"
  },
  bannerImgHide: {
    width: "100%",
    height: "100%"
  },

  activeBreadcrumbSpan: {
    fontWeight: 600,
    color: "#073366",
    fontSize: "1.125rem"
  },
  dashbordFormLowerDiv: {
    display: "flex",
    gap: "25px"
  },
  dashbordFormLowerLeftDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    width: "100%",
    borderRadius: "4px"
  },
  dashboardApplicationWrapperDiv: {
    borderRadius: "4px",
    overflow: "hidden",
    width: "350px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& .MuiAccordion-rounded": {
      borderRadius: "4px"
    },
    "@media (max-width:965px)": {
      width: "100%",
      maxWidth: "893px",
    },
  },
  expandMoreIcon: {
    color: "#FFF",
    height: "25px",
    width: "25px"
  },
  accordionSummaryStyle: {
    backgroundColor: "#07336c",
    borderRadius: "4px",
    color: "#FFF",
    margin: "0px 0px",
    "@media (max-width:965px)": {
      width: "100%",
      maxWidth: "750px"
    }
  },
  accordionStyles: {
    padding: "0px"
  },
  boldPadding5px:{
    paddingLeft:'5px'
  },
  formUnderFormSelect: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  formBorder: {
    borderBottom: "1px solid #979797",
    width: "auto",
    cursor: "pointer"
  },
  footer: {
    padding: "2%",
  },
  searchFormContainer: {
    width: "78%",
    height: "318px",
    borderRadius: "8px",
    background: 'linear-gradient(to bottom, #70899054, #70899054)',
    marginTop: '7px',
    marginLeft: '24px',
  },
  searchFormSubContainer: {
    width: 'auto',
    height: "298px",
    borderRadius: '8px',
    border: '1px solid #D7D7D7',
    background: '#FFF',
    margin: '10px'
  },
  searchFormHeading: {
    color: "#073366",
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    marginLeft: '24px',
    marginTop: '32px',
  },
  searchFormHeadingResult2: {
    color: "#000",
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600 as 600,
    lineHeight: '24px',
    marginLeft: '16px',
    marginTop: '32px',
  },
  searchRadioGroup: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    marginTop: '6px',
    marginLeft: '24px',

  },
  searchWithTitle: {
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400 as 400,
    lineHeight: 'normal',
    textTransform: 'capitalize' as 'capitalize',
    marginLeft: '24px',
    marginTop: '20px'
  },
  searchItemSelect: {
    borderRadius: '4px',
    border: '1px solid #979797',
    width: '77px',
    height: '33px',
    padding: '15px 12px',
  },
  searchRadiolabel: {
    fontSize: '18px',
    fontWeight: 400,
  },
  searchBoxInput: {
    width: '100%',
    height: '44px',
    fill: '#FFF',
    strokeWidth: '1px',
    stroke: '#979797',
  },
  searchIcon: {
    display: 'flex',
    width: '52px',
    height: '52px',
    padding: '10px',
    justifyContent: 'center',
    color: 'white',
    alignItem: 'center',
    flexShrink: 0,
    borderRadius: '4px',
    background: 'var(--Gradient, linear-gradient(136deg, #002E6A -6.59%, #708990 105.03%))',
    marginTop: '-3px',
    marginLeft: '16px',
    cursor: 'pointer'
  },
  searchInputBoxContainer: {
    display: 'flex',
    marginTop: '25px',
    marginLeft: '24px',
    marginRight: '16px'
  },
  buttonBack: {
    width: '172px',
    padding: '13px 0px 12px 0px',
    borderRadius: '4px',
    border: '1px solid var(--Primary-Normal, #0B336A)',
    background: '#F5F7FA',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0B336A'
  },
  radioBtnColor: {
    color: '#0B336A',
  },
  leftArrow: {
    width: '55px',
    marginRight: '0px',
    marginTop: '20px',
    marginLeft: '10px',
    height: '51px',
    zIndex: 9999999,
    cursor: 'pointer',
  },
  btnViewPatent: {
    borderRadius: '4px',
    border: '1px solid #979797',
    width: '56px',
    height: '32px'
  },
  paginationStyle: {
    display: "flex",
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '24px',
    marginBottom: '32px',
    paddingRight: '24px',
  },
  searchResultContainer: {
    width: "78%",
    height: "100%",
    borderRadius: "8px",
    background: 'linear-gradient(to bottom, #70899054, #70899054)',
    marginTop: '7px',
    marginLeft: '24px',
  },
  searchResultSubContainer: {
    width: 'auto',
    height: "auto",
    borderRadius: '8px',
    border: '1px solid #D7D7D7',
    background: '#FFF',
    margin: '10px'
  },
  searchResultMenuItem: {
    paddingTop: '4px',
    paddingRight: '11px',
    paddingLeft: '4px',
    paddingBottom: '4px'
  },
  searchButtonBack: {
    width: '172px',
    padding: '13px 0px 12px 0px',
    borderRadius: '4px',
    border: '1px solid var(--Primary-Normal, #0B336A)',
    background: '#F5F7FA',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0B336A'
  },
  crossImgStyle: {
    display: 'flex',
    width: '44px',
    height: '44px',
    padding: '8px',
    justifyContent: 'end',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '2px',
    border: '2px solid #808080',
    marginLeft: 'auto',
    marginRight: '24px',
    position: 'relative' as 'relative',
    top: '16px',
  },
  searchModalViewContainer: {
    width: '1008px',
    height: 'auto',
    borderRadius: '8px',
    background: 'linear-gradient(to bottom, #70899054, #70899054)',
    padding: '10px',
  },
  searchModalViewSubContainer: {
    width: '988px',
    height: '95vh',
    backgroundColor: '#FFF',
    boxSizing: 'border-box' as 'border-box',
    margin: '1px 0px',
    borderRadius: '8px',
    
  },
  searchModalTransparent: {
    width: '1441px',
    height: '1714px',
    flexShrink: 0,
    background: 'linear-gradient(180deg, #275080 1.98%, rgba(245, 247, 249, 0.71) 100%)',
    position: 'absolute' as 'absolute',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
  },
  modalBackDropStyle: {
    background: 'linear-gradient(0deg, #275080 1.98%, rgba(245, 247, 249, 0.71) 100%)',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  closeImgStyle: {
    width: '30px',
    height: '24px',
    cursor: 'pointer'
  },
  headerLeftStyle: {
    width: '310px',
    height: '56.137px',
  },
  governTypography: {
    fontSize: '24px',
    fontWeight: 600,
    paddingRight: '24px'
  },
  typography18px: {
    fontSize: '18px',
    fontWeight: 600,
    paddingRight: '16px',
    paddingLeft: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  paddingLeft16: {
    paddingRight: '12px',
    alignItems: 'center',
    display: 'flex',
  },
  datePatentStyle: {
    fontSize: '18px',
    fontWeight: 600,
    paddingRight: '26px'
  },
  patentNoStyle: {
    fontSize: '18px',
    fontWeight: 600,
    paddingRight: '66px',
    display: 'flex',
    alignItems: 'center',
  },
  horizontalRule: {
    width: '940px',
    height: '1px',
    background: '#979797',
    paddinnTop: '30px',
    marginLeft: '22px',
    marginTop: '30px'
  },
  remoteVitalSign: {
    fontSize: '18px',
    fontWeight: 600 as 600,
    lineHeight: '24px',
    textTransform: 'uppercase' as 'uppercase',
    width: '418px',
  },
  abtractTypography: {
    fontSize: '18px',
    fontWeight: 600 as 600,
    lineHeight: '24px',
    textTransform: 'uppercase' as 'uppercase',
    marginLeft: '100px',
  },
  applicantStyle: {
    fontSize: '16px',
    fontWeight: 400 as 400,
  },
  applicantStyle2: {
    fontSize: '16px',
  }
};

export const UserRegisteredName = styled(Typography)({
  fontSize: "1.8rem",
  fontWeight: 700,
  width: "max-content",
  "@media(max-width: 750px)": {
    fontSize: "1.5rem",
    fontWeight: 600
  },
  "@media(max-width: 520px)": {
    fontSize: "1rem",
    fontWeight: 600
  },
  "@media(max-width: 340px)": {
    fontSize: "0.8rem",
    fontWeight: 600
  }
});

const FormSelectedShowText = styled(Typography)({
  fontWeight: 500,
  color: "#073366",
  fontSize: "1.125rem",
  "@media (max-width: 750px)": {
    fontSize: "0.9rem"
  },
  "@media(max-width: 520px)": {
    fontSize: "1rem",
    fontWeight: 400
  },
  "@media(max-width: 340px)": {
    fontSize: "0.8rem",
    fontWeight: 400
  }
})

const Accordion = withStyles({
  root: {
    "&$expanded": {
      margin: "auto",
      width: "100%",
      maxWidth: "899px",
      borderRadius: "4px"
    },
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between"
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      maxWidth: "893px",
      borderRadius: "4px"
    }
  },
  expanded: {}
})(MuiAccordion);

const SubFormSelectText = styled(Typography)({
  color: "#000",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  padding: "1rem 5%",
  "@media (max-width: 965px)": {
    width: "100%"
  },
  "@media(max-width: 520px)": {
    fontSize: "13px",
    fontWeight: 600
  },
  "@media(max-width: 340px)": {
    fontSize: "12px",
    fontWeight: 500
  }
});

const MenuItemDisplay = styled(MenuItem)({
  '&.MuiList-padding': {
    width: '65.65px'
  },
  '&.MuiSelect-outlined': {
    border: '.1px solid gray',
    paddingLeft: '8px',
  }
});

const PaginationDisplay = styled(Pagination)({
  '&.MuiPaginationItem-page.Mui-disabled': {
    opacity: 0.38,
    border: '2px solid gray',
    borderRadius: '6px',
  },
  '&.MuiPagination-ul:last-child, @.MuiPagination-ul:first-child > button': {
    border: '2px solid gray',
    opacity: 0.38,
    borderRadius: '6px',
  },
  '&.MuiPaginationItem-ellipsis': {
    height: 'auto',
    color: '#808080',
  },
  '&.MuiPaginationItem-page.Mui-selected': {
    color: '#808080',
  }
})

const MainFormText = styled(Typography)({
  color: "#FFF",
  fontSize: "18px",
  fontWeight: 700,
  "@media(max-width: 520px)": {
    fontSize: "14px",
    fontWeight: 600
  },
  "@media(max-width: 320px)": {
    fontSize: "12px",
    fontWeight: 500
  }
});
// Customizable Area End
