import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import Loader from "./Loader.web";
type MyProps = { navigation: undefined; id: string; bannerData: any; isLoading:boolean }

class CarouselComp extends Component<MyProps> {
        renderCarousel = () => {
            if (!this.props.bannerData || this.props.bannerData.length === 0) {
                return null; 
            }
        return (
            <Carousel
                showArrows={false}
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showThumbs={false}
                interval={3000}
                className="app-carousel"
            >

                {
                    this.props.bannerData.map((item: any) => <div key={item.id}>
                        <img className="carousel-img" src={item.attributes.banner_image_url} />
                    </div>)
                }
            </Carousel>)
    }
    render() {
        return (
            <>
            <Loader loading={this.props.isLoading} />
                {this.renderCarousel()}
            </>
        );
    }
}
export default CarouselComp