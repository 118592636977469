import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { notification } from "antd"

// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
export const configJSON = require("./config");
interface IndianState {
    stateName: string;
}

interface ApplicationData {
    data: ApplicationData[];
}

interface ErrorObject {
    [key: string]: string;
}

interface ApplicationDataType {
    data: []
    id: string;
    type: string;
    attributes: {
        name: string;
    };
}

export interface ValidResponseType {
    message: object;
    data: object;
}

export interface InvalidResponseType {
    errors: Array<string>;
}

interface ChecknowDataType {
        data: string;
}

interface ChecknowErrorDataType {
    errors: string;
}

interface SignUpData {
    user_type: string;
    user_id: string;
    domicile: string;
    user_name: string;
    address: string;
    district: string;
    state: string;
    pincode: string;
    telephone: string;
    phone_number: string;
    gender: string;
    password: string;
    password_confirmation: string;
    email: string;
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    currentFontSize: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isAuthModalOpen: boolean;
    authModalType: string;
    indianStates: IndianState[];
    applicationData: Array<ApplicationDataType>;
    checkNowValue:string;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailAccountResistrationFormController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    signUpAPICallId: string = "";
    applicationTypeAPICallId: string = "";
    checkNowAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isAuthModalOpen: false,
            indianStates: [
                { stateName: "Andaman and Nicobar Islands" },
                { stateName: "Andhra Pradesh" },
                { stateName: "Arunachal Pradesh" },
                { stateName: "Assam" },
                { stateName: "Bihar" },
                { stateName: "Chandigarh" },
                { stateName: "Chhattisgarh" },
                { stateName: "Dadra and Nagar Haveli and Daman and Diu" },
                { stateName: "Delhi" },
                { stateName: "Goa" },
                { stateName: "Gujarat" },
                { stateName: "Haryana" },
                { stateName: "Himachal Pradesh" },
                { stateName: "Jammu and Kashmir" },
                { stateName: "Jharkhand" },
                { stateName: "Karnataka" },
                { stateName: "Kerala" },
                { stateName: "Ladakh" },
                { stateName: "Lakshadweep" },
                { stateName: "Madhya Pradesh" },
                { stateName: "Maharashtra" },
                { stateName: "Manipur" },
                { stateName: "Meghalaya" },
                { stateName: "Mizoram" },
                { stateName: "Nagaland" },
                { stateName: "Odisha" },
                { stateName: "Puducherry" },
                { stateName: "Punjab" },
                { stateName: "Rajasthan" },
                { stateName: "Sikkim" },
                { stateName: "Tamil Nadu" },
                { stateName: "Telangana" },
                { stateName: "Tripura" },
                { stateName: "Uttar Pradesh" },
                { stateName: "Uttarakhand" },
                { stateName: "West Bengal" }
            ],
            authModalType: "success",
            applicationData: [],
            checkNowValue: "",
            isAlert: false,
            alertMsg: "",
            alertType: "success"
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area End


    // Customizable Area Start
    async componentDidMount() {
        this.applicationTypeApi();        
    }

    apiCall = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        body && type !== "formData"
            ? 
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
            
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                this.responseSucessCall(apiRequestCallId, responseJson);
            } else if (responseJson && responseJson.errors) {
                this.responseFailureCall(apiRequestCallId, responseJson);
            }
        }
    }

    responseSucessCall = async (apiRequestCallId: string, responseJson: ApplicationDataType & ChecknowDataType) => {
        if (apiRequestCallId === this.signUpAPICallId) {
            this.signUpSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.applicationTypeAPICallId) {
            this.applicationTypeSucessCallBack(responseJson);
        }
        if (apiRequestCallId === this.checkNowAPICallId) {
            this.checkNowApiSucessCallBack(responseJson );
        }
    };

    responseFailureCall = async (apiRequestCallId: string, responseJson: ApplicationDataType & ChecknowErrorDataType ) => {
        if (apiRequestCallId === this.signUpAPICallId) {
            this.signUpFailureCallBack(responseJson);
        }
        if (apiRequestCallId === this.checkNowAPICallId) {
            this.checkNowApiFailureCallBack(responseJson);
        }
    };

    signUp = async (values: SignUpData) => {
        const body = {
            data: {
                type: "email_account",
                attributes: {
                    user_type: values.user_type,
                    user_id: values.user_id,
                    //E-SignId part is remaining.
                    password: values.password,
                    password_confirmation: values.password_confirmation,
                    domicile: values.domicile,
                    user_name: values.user_name,
                    address: values.address,
                    district: values.district,
                    state: values.state,
                    pincode: values.pincode,
                    full_phone_number: values.telephone,
                    mobile_number: values.phone_number,
                    email: values.email,
                    gender: values.gender
                }
            }
        }
        this.signUpAPICallId = await this.apiCall({
            contentType: configJSON.singUpContentType,
            method: configJSON.signUpMethodType,
            endPoint: configJSON.singUpEndPoint,
            body: body
        });
    };

    openNotification = (errors: ErrorObject[]) => {
        errors.forEach((error: ErrorObject, i: number) => {
            const errKey = Object.keys(error)[0];
            const args = {
                message: "Validation Error Occurred!",
                description: `${errKey.replace(/_/g, " ")} ${error[errKey]}`,
                duration: 3
            };
            notification.error(args);
        });
    };

    signUpSucessCallBack = (res: any) => {
        this.setState({
            isAuthModalOpen: true
        })
        localStorage.setItem("token", res.meta.token)
    };

    signUpFailureCallBack = (res: any) => {
        this.openNotification(res.errors);
    };

    navigateLandingScreen = async () => {
        this.setState({ isAuthModalOpen: false }, ()=>{this.props.navigation.navigate('Dashboard')})
      };


    onFinish = (values: SignUpData) => {
        this.signUp(values)
    }

    passwordErrorSignUpMatch = ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (
                !value ||
                getFieldValue("password") === value
            ) {
                return Promise.resolve();
            }
            return Promise.reject(
                new Error(
                    "Password and Re-type password doesn't match"
                )
            );
        },
    })

    classHandler = () => {
        switch (this.props.currentFontSize) {
            case "18px":
                return "sign-up sign-up20"
            case "14px":
                return "sign-up sign-up12"
            default:
                return "sign-up"
        }
    }
    handleReset = () => {
        location.reload()
    }

    applicationTypeApi = async () => {
        this.applicationTypeAPICallId = await this.apiCall({
            contentType: configJSON.applicationTypeContentType,
            method: configJSON.applicationTypeMethodType,
            endPoint: configJSON.applicationTypeEndPoint
        });
    };

    applicationTypeSucessCallBack = (response: ApplicationDataType) => {
        this.setState({ applicationData: response.data })
    };

    checkNowApi = async () => {
        const body = {
            data: {
                user_id: this.state.checkNowValue
            }
        }
        this.checkNowAPICallId = await this.apiCall({
            contentType: configJSON.checkNowApiContentType,
            method: configJSON.checkNowApiMethodType,
            endPoint: configJSON.checkNowApiEndPoint,
            body: body
        });
    };

    checkNowApiSucessCallBack = (response: ChecknowDataType) => {
        this.setState({ isAlert: true, alertMsg: response.data + " is available", alertType: "success" });
    };

    checkNowApiFailureCallBack = (response: ChecknowErrorDataType) => {
        this.setState({ isAlert: true, alertMsg: response.errors, alertType: "error" });
    };

    checkNowValueChange = (event: { target: { value: string } }) => {
        this.setState({ checkNowValue: event.target.value });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };
    // Customizable Area End
}


