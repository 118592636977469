Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarPutApiMethodType = "PUT";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.title = "Discarded Forms";
exports.filterText = "Filter By";
exports.searchText = "Search";
exports.application = "Application No.";
exports.patentName = "Patent Name";
exports.entrytype = "Entry Type";
exports.filingdate = "Filing Date";
exports.formAmount = "Form Amount";
exports.statusHeading = "Status";
exports.showAll = "Show All";
exports.Preview = "Preview";
exports.signDocuments = "Sign Documents";
exports.makePayment = "Make Payment";
exports.TransferPayment = "Transfer Payment";
exports.orderCertifiedCopy = "Order Certified Copy";
exports.transferPatent = "Transfer Patent"
exports.renewal = "Renewal";
exports.data="Status : Discarded"
exports.designInformation="Design Information"
exports.designNumber="Design Number"
exports.designClass="Design Class"
exports.proprietorFullname="Proprietor Full Name"
exports.paymentFaileddetails="Payment Failed Details"
exports.extensionFee="Extension Fee"
exports.date="Date"
exports.reasonforPaymentfail="Reason For Payment Fail"
exports.Applicant1="Applicant  1" 
exports.Applicant2="Applicant  2"
exports.Applicant3="Applicant  3"
exports.applicantDetails="Applicant Details"
exports.applicationName ="Application Name "
exports.Address="Address"
exports.controller="Controller of Design the patent office"
exports.applicantSignature="Applicant Signature"
exports.Pincode="Pincode"
exports.townDistrict="Town/City/District"
exports.stateTerritory ="State/Union Territory "
exports.Country ="Country"
exports.addressService="Address for Service in India"
exports.condition="I agree that the above information submitted by me are true to my best knowledge"
exports.titleForm ="“FORM 4"
exports.subTitle ="[THE DESIGNS ACT, 2000]"
exports.subText ="Application for the Restoration of design [Section 12(2)] [Rule 24]"
exports.discardformGetUrl =(status,currentPage,pageLimit)=>  `bx_block_automaticformcreation/form_submissions?status=${status}&page=${currentPage}&limit=${pageLimit}`;
exports.userName = "Welcome John D";
exports.pageLocation = "Home";
exports.signDoc = "Sign Documents";
exports.makePay = "Make Payment"
exports.Discard = "Discard"
exports.Edit = "Edit"
exports.status = "Status :"
exports.discardFormupdateUrl = "bx_block_automaticformcreation/form_creations/"
exports.EditFormupdateUrl = "Edit"
exports.discardEndFormupdateUrl = "/discard"
exports.successFormText = "success";
exports.doneButtonText = "Done";
exports.searchPatentMethod = "GET"
exports.searchExistingPatentUrl = "/bx_block_advanced_search/search"
// Customizable Area End