export const headerLeftImg = require('./assets/headerLeftImg.png');
export const headerRightImg = require('./assets/headerRightImg.png');
export const translateImg = require('./assets/translateImg.png');
export const downArrowImg = require('./assets/downArrowImg.svg');
export const banner = require('./assets/banner.png');
export const footerLogoImg = require('./assets/footerLogoImg.svg');
export const successImg = require('./assets/success.gif');
export const failedImg = require('./assets/failedImg.png');
export const modalCloseImg = require('./assets/modalCloseImg.svg');
export const modalTittleImg = require('./assets/hindiWordImg.png');
export const logOutImg = require('./assets/logOutImg.png');