import React from "react";
// Customizable Area Start
import Banner from "../../../components/src/Banner.web";
import EmailAccountRegistrationForm from "./EmailAccountResistrationForm.web";
import EmailAccountRegistrationController from "./EmailAccountRegistrationController.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  render() {
    // Customizable Area Start
    return (
      <>
        <Header
          navigation={undefined}
          id={""}
          navData={this.state.navData}
          fontSizeHandler={this.fontSizeHandler}
          currentFontSize={this.state.currentFontSize}
          isLogin={false}
          onLogoutClick={undefined}
        />

        <div className="container">
          <div className="signup-container">
            <main className="signup-main">
              <Banner
                title="welcome"
                activeLink="home"
                navigation={undefined}
                id={""}
                activePage="Sign up"
              />
              <EmailAccountRegistrationForm
                navigation={this.props.navigation}
                id={""}
                currentFontSize={this.state.currentFontSize}
              />
            </main>
          </div>
          <Footer
            navigation={undefined}
            id={""}
            footerNavData={this.state.footerNavData}
          />
        </div>
      </>
    );
    // Customizable Area End
  }
}
